import React from "react";
import MainLayout from "../../Common/MainLayout";
import postToFunctions from "../../Common/postToFunctions";
import _ from "lodash";
import moment from "moment";
import * as routes from "../../Common/Routes";
import LoadingScreen from "react-loading-screen";

class Notifications extends React.PureComponent {
	state = {
		notifications: [],
		loading: false,
		vetCheck: false,
		examCheck: false,
	};

	async componentDidMount() {
    var body = document.getElementsByTagName("body")[0];
		body.style.cursor = "wait";
		if (this.props.userData) {
			this.setState({ loading: true }, async () => {
				let { data } = await postToFunctions("getNotificationsNoLimit", {
					uid: this.props.userData.uid,
					isLab: this.props.userData.isLaboratory,
				});
				this.setState({
					notifications: data.notify,
					notificationsBackUp: data.notify,
					loading: false,
        },()=>{
          body.style.cursor = "";
        });
        
			});
		}
	}

	async filter(event) {
		let { vetCheck, examCheck, notifications, notificationsBackUp } = this.state;
		if (event.target.name == "guia") examCheck = event.target.checked;
		if (event.target.name == "veterinario") vetCheck = event.target.checked;
		if (vetCheck) notifications = await _.filter(notificationsBackUp, { locale: "VETERINÁRIOS" });
		if (examCheck) notifications = await _.filter(notificationsBackUp, { locale: "GUIAS" });
		if (vetCheck && examCheck) notifications = notificationsBackUp;
    if (!vetCheck && !examCheck) notifications = notificationsBackUp;
    
		this.setState({ vetCheck, examCheck, notifications });
	}

	render() {
		return this.state.loading ? (
			<div>
				<LoadingScreen
					loading={this.state.loading}
					bgColor="#fff"
					spinnerColor="#1F6CBC"
					textColor="#1F6CBC"
					logoSrc={""}
					text={<span style={{ fontFamily: "Proxima Nova Semibold" }}>Carregando...</span>}
				/>
			</div>
		) : (
			<MainLayout {...this.props} data={this.props.userData}>
				<div className="main-layout__top-content">
					<div className="search">
						<form>
							<div className="search__filter search__filter-checkbox">
								<fieldset class="search__filter-fieldset">
									<i class="icon-filter" />
									Filtro
								</fieldset>
								<div className="checkbox-wrapper">
									<input
										type="checkbox"
										name="guia"
										onChange={e => {
											this.filter(e);
										}}
									/>
									<label>Guias</label>
								</div>
								<div className="checkbox-wrapper">
									<input
										type="checkbox"
										name="veterinario"
										onChange={e => {
											this.filter(e);
										}}
									/>
									<label>Veterinários</label>
								</div>
							</div>
							{/* <div className="search__field search__field--space">
                                <input type="text" placeholder="Pesquise pelo nome do proprietário" />
                            </div> */}
						</form>
					</div>
					<div className="notification-wrapper">
						{_.map(this.state.notifications, item => {
							return (
								<div
									className="notification-box clickable"
									onClick={() => {
										this.props.history.push(
											item.locale == "GUIAS" ? routes.GUIDES : routes.PROFESSIONALS,
										);
									}}>
									<div className="notification-box__title">{item.title}</div>
									<div className="notification-box__datetime">
										{moment(item.createdAt).format("DD/MM/YYYY") +
											" às " +
											moment(item.createdAt).format("HH:mm")}
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</MainLayout>
		);
	}
}

export default Notifications;
