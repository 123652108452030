function validateLogin(values) {
    let errors = {};

    if (!values.email) {
        errors.email = 'Preencha o email';
    } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
        errors.email = 'Formato de email inválido';
    }

    if (!values.password) {
        errors.password = 'Preencha a senha';
    }

    return errors;
}


export default validateLogin;