import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import ReactTooltip from "react-tooltip";

const Menu = ({ match: { path }, data }) => {
	const isLaboratory = !data ? false : data.isLaboratory ? true : false;
	return (
		<div className="menu">
			<ul className="menu__list">
				{isLaboratory ? (
					<></>
				) : (
					<>
						<li
							data-tip
							data-for="home"
							className={`menu__list-item ${
								path === "/counters" ? "menu__list-item--active" : ""
							}`}>
							<Link to="/counters">
								<i className="icon icon-counters" />
							</Link>
						</li>
						<ReactTooltip id="home" place="right" type="info" effect="solid">
							<span className="font-tooltip">Home</span>
						</ReactTooltip>

						<li
							data-tip
							data-for="labs"
							className={`menu__list-item ${path === "/labs" ? "menu__list-item--active" : ""}`}>
							<Link to="/labs">
								<i className="icon icon-labs" />
							</Link>
						</li>
						<ReactTooltip id="labs" place="right" type="info" effect="solid">
							<span className="font-tooltip">Laboratórios</span>
						</ReactTooltip>

            <li
							data-tip
							data-for="professionals"
							className={`menu__list-item ${
								path === "/professionals" ? "menu__list-item--active" : ""
							}`}>
							<Link to="/professionals">
								<i className="icon icon-professionals" />
							</Link>
						</li>
						<ReactTooltip id="professionals" place="right" type="info" effect="solid">
							<span className="font-tooltip">Veterinários</span>
						</ReactTooltip>
					</>
				)}
				<li
					data-tip
					data-for="guides"
					className={`menu__list-item ${path === "/guides" ? "menu__list-item--active" : ""}`}>
					<Link to="/guides">
						<i className="icon icon-guides" />
					</Link>
				</li>
				<ReactTooltip id="guides" place="right" type="info" effect="solid">
					<span className="font-tooltip">Guias</span>
				</ReactTooltip>
				{isLaboratory ? (
					<></>
				) : (
					<>
          	<li
							data-tip
							data-for="animals"
							className={`menu__list-item ${path === "/animals" ? "menu__list-item--active" : ""}`}>
							<Link to="/animals">
								<i className="icon icon-horse" />
							</Link>
						</li>
						<ReactTooltip id="animals" place="right" type="info" effect="solid">
							<span className="font-tooltip">Animais</span>
						</ReactTooltip>
						<li
							data-tip
							data-for="owners"
							className={`menu__list-item ${path === "/owners" ? "menu__list-item--active" : ""}`}>
							<Link to="/owners">
								<i className="icon icon-owners" />
							</Link>
						</li>
						<ReactTooltip id="owners" place="right" type="info" effect="solid">
							<span className="font-tooltip">Proprietários</span>
						</ReactTooltip>

					

						<li
							data-tip
							data-for="properties"
							className={`menu__list-item ${
								path === "/properties" ? "menu__list-item--active" : ""
							}`}>
							<Link to="/properties">
								<i className="icon icon-fence" />
							</Link>
						</li>
						<ReactTooltip id="properties" place="right" type="info" effect="solid">
							<span className="font-tooltip">Propriedades</span>
						</ReactTooltip>
					</>
				)}
			</ul>
			<ul className="menu__list">
				<li
					data-tip
					data-for="notifications"
					className={`menu__list-item ${
						path === "/notifications" ? "menu__list-item--active" : ""
					}`}>
					<Link to="/notifications">
						<i className="icon icon-bell-white" />
					</Link>
				</li>
				<ReactTooltip id="notifications" place="right" type="info" effect="solid">
					<span className="font-tooltip">Notificações</span>
				</ReactTooltip>

				<li
					data-tip
					data-for="profile"
					className={`menu__list-item ${path === "/profile" ? "menu__list-item--active" : ""}`}>
					<Link to="/profile">
						<i className="icon icon-user" />
					</Link>
				</li>
				<ReactTooltip id="profile" place="right" type="info" effect="solid">
					<span className="font-tooltip">Perfil</span>
				</ReactTooltip>
			</ul>
		</div>
	);
};

export default withRouter(Menu);
