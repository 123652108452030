function validateLab(values) {
    const errors = {} 
    if (!values.name) {
        errors.name = 'O nome não está preenchido.';
    } 
    if (!values.email) {

        errors.email = 'O email não está preenchido.';
    }
    if(!values.unique_guide){
        errors.unique_guide = 'Exame obrigatório';
    }
    return errors;
}

export default validateLab;