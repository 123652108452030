import React, { createContext } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import _ from "lodash";
import LoadingScreen from "react-loading-screen";
import postToFunctions from "./postToFunctions";
import { auth } from "../Config/firebase";

const defaultValues = {};
const AppContext = createContext(defaultValues);

const withAuthentication = Component => {
	class WithAuthentication extends React.Component {
		constructor(props) {
			super(props);

			this.state = {
				authUser: {},
				userData: {},
				loading: true,
			};
		}

		getChildContext() {
			return {
				authUser: this.state.authUser,
				userData: this.state.userData,
			};
		}

		async componentDidMount() {
			await auth.onAuthStateChanged(async authUser => {
				const result = await postToFunctions("getLabById", { uid: authUser ? authUser.uid : null });
				var userData = result.data.laboratory;
				if (!userData) {
					let { data } = await postToFunctions("getAdminData", {
						uid: authUser ? authUser.uid : null,
					});
					userData = data.userData;
				}
				this.setState({
					authUser: authUser,
					userData: userData,
					loading: false,
				});
			});
		}
		render() {
			const { loading } = this.state;

			if (loading) {
				return (
					<LoadingScreen
						loading={loading}
						bgColor="#fff"
						spinnerColor="#1F6CBC"
						textColor="#1F6CBC"
						logoSrc={""}
						text={<span style={{ fontFamily: "Proxima Nova Semibold" }}>Carregando...</span>}
					/>
				);
			}

			return (
				<AppContext.Provider value={this.state}>
					<Component {...this.props} />
				</AppContext.Provider>
			);
		}
	}

	WithAuthentication.childContextTypes = {
		authUser: PropTypes.object,
		userData: PropTypes.object,
	};

	return WithAuthentication;
};

export { withAuthentication as default, AppContext };
