import React from "react";
import MainLayout from "../../Common/MainLayout";
import { Link } from "react-router-dom";
import _ from "lodash";
import LoadingScreen from "react-loading-screen";
import ToggleSwitch from "../../Components/ToggleSwitch";
import postToFunctions from "../../Common/postToFunctions";
import * as utils from "../../Common/util";
import iziToast from "izitoast/dist/js/iziToast.min.js";
import moment from "moment";

class Professionals extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			activities: [],
			activitiesBackUp: [],
			loading: false,
			states: [],
			cities: [],
			searchText: "",
			filterActiviesData: [],
			filterBackUp: [],
		};
	}

	async componentDidMount() {
    var body = document.getElementsByTagName('body')[0]
    
    body.style.cursor = 'wait'
		if (this.props.userData) {
			if (this.props.userData.isLaboratory) this.props.history.push("/guides");
		}
		this.setState({ loading: true }, async () => {
			this.getStates();
      await this.getVaterinaries();
      body.style.cursor = ''
		});
	}

	async getStates() {
		const result = await utils.getStates();
		this.setState({ states: result });
	}

	async getCities(uf) {
		if (uf) {
			const activities = await utils.filterByState(
				uf,
				this.state.states,
				this.state.activitiesBackUp,
			);
			const cities = await utils.getCities(uf);
			const veterinary = await utils.filterByName(activities, this.state.searchText);
			this.setState({ activities, cities, filterActiviesData: veterinary });
		} else {
			this.setState({
				activities: this.state.activitiesBackUp,
				cities: [],
				filterActiviesData: this.state.filterBackUp,
			});
		}
	}

	async filterForVeterinaryName() {
		const { activities, searchText } = this.state;
		const veterinary = await utils.filterByName(activities, searchText);
		this.setState({ filterActiviesData: veterinary, filterBackUp: veterinary });
	}

	deleteVeterinary(uid) {
		iziToast.show({
			theme: "light",
			icon: "fa fa-ban",
			iconColor: "red",
			maxWidth: 700,
			title: "Deseja mesmo excluir esse veterinário?",
			timeout: false,
			position: "center", // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter
			buttons: [
				[
					'<button style="background-color: #1F6CBC; color:#fff;">Sim</button>',
					async () => {
						const { data } = await postToFunctions("deleteVeterinary", { uid });
						iziToast.destroy();
						const activities = await _.reject(this.state.activities, { uid: uid });
						const activitiesBackUp = await _.reject(this.state.activitiesBackUp, { uid: uid });
						this.setState({ activities, activitiesBackUp });
						if (data.success) {
							iziToast.success({
								title: "OK",
								message: "Veterinário excluído com sucesso!",
								position: "topRight",
							});
						}
					},
					true,
				], // true to focus
				[
					'<button  style="background-color: #fff; border:solid 1px #1F6CBC; color: #1F6CBC">Não</button>',
					function(instance, toast) {
						instance.hide(
							{
								transitionOut: "fadeOutUp",
								onClosing: function(instance, toast, closedBy) {
									console.info("closedBy: " + closedBy); // The return will be: 'closedBy: buttonName'
								},
							},
							toast,
							"buttonName",
						);
					},
				],
			],
		});
	}

	handleChangeCallback = async i => {
		const { data } = await postToFunctions("activeVeterinary", { veterinary: i });
	};

	async filterForCities(idCity) {
		if (idCity) {
			const { cities, activitiesBackUp } = this.state;
			const activities = await utils.filterByCity(idCity, cities, activitiesBackUp);
			const veterinary = await utils.filterByName(activities, this.state.searchText);

			this.setState({ activities, filterActiviesData: veterinary });
		} else {
			this.setState({
				activities: this.state.activitiesBackUp,
				filterActiviesData: this.state.filterBackUp,
			});
		}
	}

	async getVaterinaries() {
		let { data } = await postToFunctions("getVeterinaries");
		if (data.success) {
			data = await _.orderBy(data.veterinaries, ["createdAt"], ["desc"]);
			this.setState({ activities: data, activitiesBackUp: data, loading: false });
		} else {
			this.setState({ loading: false });
		}
	}

	render() {
		const { activities, searchText, filterActiviesData, activitiesBackUp } = this.state;
		const activitiesData = searchText == "" ? activities : filterActiviesData;
		return this.state.loading ? (
			<div>
				<LoadingScreen
					loading={this.state.loading}
					bgColor="#fff"
					spinnerColor="#1F6CBC"
					textColor="#1F6CBC"
					logoSrc={""}
					text={<span style={{ fontFamily: "Proxima Nova Semibold" }}>Carregando...</span>}
				/>
			</div>
		) : (
			<MainLayout {...this.props}>
				<div className="main-layout__actions-layout">
					<div className="main-layout__top-content">
						<h2 className="main-layout__title">Veterinários</h2>
						<div className="search">
							<form>
								<h3 className="main-layout__title">Veterinários cadastrados</h3>
								<div className="search__field">
									<input
										onChange={e =>
											this.setState({ searchText: e.target.value }, () => {
												this.filterForVeterinaryName();
											})
										}
										type="text"
										placeholder="Pesquise pelo nome do veterinário"
									/>
								</div>

								<div className="search__filter">
									<fieldset class="search__filter-fieldset">
										<i class="icon-filter"></i>
										Filtro
									</fieldset>
									<div className="search-filter__select">
										<label>Estado</label>
										<select
											onChange={e => {
												this.getCities(e.target.value);
											}}
											className="search-filter__select">
											<option value="">Selecione o estado</option>
											{_.map(this.state.states, state => {
												return <option value={state.id}>{state.nome}</option>;
											})}
										</select>
									</div>

									<div className="search-filter__select">
										<label>Cidade</label>
										<select
											onChange={e => {
												this.filterForCities(e.target.value);
											}}
											className="search-filter__select">
											<option value="">Selecione a cidade</option>
											{_.map(this.state.cities, city => {
												return <option value={city.id}>{city.nome}</option>;
											})}
										</select>
									</div>
								</div>
							</form>
						</div>
					</div>
					<div class="right-space">
						<Link to="/professionals/new-veterinary">
							<button class="default-button default-button--mariner default-button--fluid default-button--thin clickable">
								+ Cadastrar novo veterinário
							</button>
						</Link>
					</div>
				</div>

				<table className="main-table">
					<tr>
						<th>Nome do Veterinário</th>
						<th>Email</th>
						<th>Estado</th>
						<th>Contato</th>
						<th>Data de Cadastro</th>
						<th>Ativação</th>
						<th>Editar</th>
						<th>Excluir</th>
					</tr>
					{_.map(activitiesData, item => {
						var day = moment(item.createdAt).format("DD/MM/YYYY");
						return (
							<>
								<tr>
									<td>{item.name}</td>
									<td>{item.email}</td>
									<td>{item.state}</td>
									<td>{item.contact}</td>
									<td>{day}</td>
									<td>
										<ToggleSwitch
											active={item.active}
											changeCallack={() => this.handleChangeCallback(item)}
										/>
									</td>
									<td>
										<Link to={`/professionals/${item.uid}`}>
											<button class="custom-button02 clickable">
												<i className="icon-edit"></i>
											</button>
										</Link>
									</td>
									<td>
										<button
											onClick={() => {
												this.deleteVeterinary(item.uid);
											}}
											class="custom-button02 clickable">
											<i className="icon-trash"></i>
										</button>
									</td>
								</tr>
							</>
						);
					})}
				</table>
			</MainLayout>
		);
	}
}

export default Professionals;
