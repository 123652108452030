import React from "react";
import MainLayout from "../../Common/MainLayout";
import { Link } from "react-router-dom";
import postToFunctions from "../../Common/postToFunctions";
import _ from "lodash";
import iziToast from "izitoast/dist/js/iziToast.min.js";
import LoadingScreen from "react-loading-screen";
import DatePicker from "react-datepicker";
import moment from "moment";
import "../../Styles/Css/react-datepicker.css";

class Guides extends React.PureComponent {
	state = { exams: [], loading: true, filterBy: {} };

	async componentDidMount() {
    var body = document.getElementsByTagName('body')[0]
    
    body.style.cursor = 'wait'
		try {
     if(this.props.userData){
       const { data } = await postToFunctions("getExams", { getData: true, isLab:this.props.userData.isLaboratory ? this.props.userData.isLaboratory : false, labUid:this.props.userData.uid });
	   let exams = await _.orderBy(data.exams,['createdAt'],['desc'])
	   console.log(data.exams)
       this.setState({
         exams: exams,
         examsBackUp: exams,
         owners: data.owners,
         veterinaries: data.veterinaries,
       },()=>{
        body.style.cursor = ''
       });
     }
		} catch (e) {
      body.style.cursor = ''
			console.log("getExams error: ", e);
			iziToast.error({
				title: "Erro",
				message: "Tivemos um problema em recuperar os dados. Tente novamente",
				position: "center",
			});
		}
		this.setState({ loading: false });
	}

	renderLoading() {
		return (
			<div>
				<LoadingScreen
					loading={this.state.loading}
					bgColor="#fff"
					spinnerColor="#1F6CBC"
					textColor="#1F6CBC"
					logoSrc={""}
					text={<span style={{ fontFamily: "Proxima Nova Semibold" }}>Carregando...</span>}
				/>
			</div>
		);
	}

	filterExams = () => {
		let exams = this.state.examsBackUp;

		const { filterBy } = this.state;
		if (filterBy.owner) {
			exams = _.filter(exams, exam => {
				return exam.owner && exam.owner === filterBy.owner;
			});
		}

		if (filterBy.veterinary) {
			exams = _.filter(exams, exam => {
				return exam.veterinary && exam.veterinary === filterBy.veterinary;
			});
		}

		if (filterBy.date) {
			exams = _.filter(exams, exam => {
				return exam.date && exam.date === filterBy.date;
			});
		}

		if (filterBy.exam_number) {
			exams = _.filter(exams, exam => {
				return exam.exam_number && exam.exam_number.includes(filterBy.exam_number);
			});
		}

		this.setState({ exams });
	};

	handleFilterParams(parameter, value) {
		this.setState(
			prevState => ({
				filterBy: { ...prevState.filterBy, [parameter]: value },
			}),
			() => this.filterExams(),
		);
	}

	handleDateChange = event => {
		if (event) {
			this.setState({ date: event });

			const dateEpoch = moment(event).valueOf();
			this.handleFilterParams("date", moment(dateEpoch).format("DD/MM/YYYY"));
		} else {
			this.handleFilterParams("date", null);
		}
  };
  

  deleteGuide(uid) {
		iziToast.show({
			theme: "light",
			icon: "fa fa-ban",
			iconColor: "red",
			maxWidth: 700,
			title: "Deseja mesmo excluir esse guia?",
			timeout: false,
			position: "center", // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter
			buttons: [
				[
					'<button style="background-color: #1F6CBC; color:#fff;">Sim</button>',
					async () => {
						const { data } = await postToFunctions("deleteExam", { uid });
						iziToast.destroy();
						const exams = await _.reject(this.state.exams, { uid: uid });
						const examsBackUp = await _.reject(this.state.examsBackUp, { uid: uid });
            this.setState({ exams, examsBackUp });
            iziToast.success({
              title: "OK",
              message: "Guia excluído com sucesso!",
              position: "topRight",
            });
					},
					true,
				], // true to focus
				[
					'<button  style="background-color: #fff; border:solid 1px #1F6CBC; color: #1F6CBC">Não</button>',
					function(instance, toast) {
						instance.hide(
							{
								transitionOut: "fadeOutUp",
								onClosing: function(instance, toast, closedBy) {
									console.info("closedBy: " + closedBy); // The return will be: 'closedBy: buttonName'
								},
							},
							toast,
							"buttonName",
						);
					},
				],
			],
		});
	}

	renderMainLayout() {
		const { exams, owners, veterinaries } = this.state;

		return (
			<MainLayout {...this.props} data={this.props.userData}>
				<div className="main-layout__actions-layout">
					<div className="main-layout__top-content">
						<h2 className="main-layout__title">Guias</h2>
						<div className="search">
							<form>
								<h3 className="main-layout__title">Guias cadastradas</h3>
								<div className="search__field">
									<input
										type="text"
										placeholder="Pesquise por guias"
										onChange={e => this.handleFilterParams("exam_number", e.target.value)}
									/>
								</div>

								<div className="search__filter">
									<fieldset class="search__filter-fieldset">
										<i class="icon-filter" />
										Filtro
									</fieldset>
									<div className="search-filter__select">
										<label>Veterinário</label>
										<select
											onChange={option =>
												this.handleFilterParams("veterinary", option.target.value)
											}
											className="search-filter__select">
											<option value="">Selecione o veterinário</option>
											{veterinaries &&
												_.map(veterinaries, veterinary => {
													return <option value={veterinary.uid}>{veterinary.name+" "+veterinary.last_name}</option>;
												})}
										</select>
									</div>

									<div className="search-filter__select">
										<label>Proprietário</label>
										<select
											onChange={option => this.handleFilterParams("owner", option.target.value)}
											className="search-filter__select">
											<option value="">Selecione o proprietário</option>
											{owners &&
												_.map(owners, owner => {
													return <option value={owner.uid}>{owner.name}</option>;
												})}
										</select>
									</div>

									<div className="search-filter__select">
										<label>Data</label>
										<DatePicker
											selected={this.state.date}
											dateFormat="dd/MM/yyyy"
											onChange={this.handleDateChange}
										/>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
				{_.size(exams) > 0 && (
					<table className="main-table">
						<tr>
							<th>Nº de Exame</th>
							<th>Doença</th>
							<th>Laboratório</th>
							<th>Veterinário</th>
							<th>Proprietário</th>
							<th>Animal</th>
							<th>Data</th>
							<th>Editar</th>
							{!this.props.userData.isLaboratory && <th>Excluir</th>}
						</tr>
						{_.map(exams, exam => (
							<tr>
								<td>{exam.exam_number}</td>
								<td>{exam.disease}</td>
								<td>{exam.labName}</td>
								<td>{exam.veterinaryName}</td>
								<td>{exam.ownerName}</td>
								<td>{exam.animalName}</td>
								<td>{exam.date}</td>
								<td>
                  <Link
                  to={{
                    pathname:`/guides/${exam.uid}`,
                    state:{
                      lab:this.props.userData.isLaboratory,
                      user:this.props.userData
                    }}}>
										<button class="custom-button02 clickable">
											<i className="icon-edit" />
										</button>
									</Link>
								</td>

							{!this.props.userData.isLaboratory &&	<td>
                  <button
                  onClick={() => {
                    this.deleteGuide(exam.uid);
                  }}
                  class="custom-button02 clickable">
										<i className="icon-trash" />
									</button>
								</td>}
							</tr>
						))}
					</table>
				)}
			</MainLayout>
		);
	}

	render() {
		return this.state.loading ? this.renderLoading() : this.renderMainLayout();
	}
}

export default Guides;
