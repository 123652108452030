import firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
	apiKey: "AIzaSyCk6wOEus46-t2mxD7fr0VxGfH66J7bBr4",
	authDomain: "resenha-auth-db-storage.firebaseapp.com",
	databaseURL: "https://resenha-auth-db-storage.firebaseio.com",
	projectId: "resenha-auth-db-storage",
	storageBucket: "resenha-auth-db-storage.appspot.com",
	messagingSenderId: "30872160500",
	appId: "1:30872160500:web:55e6af72b0290bd3"
};

firebase.initializeApp(firebaseConfig);


const db = firebase.database();
const auth = firebase.auth();
const storage = firebase.storage();

export { db, auth, storage };
