import React from "react";
import LogoResenha from "../../Assets/Images/logo_resenha_app_HORIZONTAL@2x.png";
import PasswordField from "../../Components/PasswordField";
import { Link } from "react-router-dom";
import validateLogin from "./validation";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { auth } from "../../Config/firebase";
import { Helmet } from "react-helmet";
import "izitoast/dist/css/iziToast.min.css";
import FormField from "../../Components/FormField";
import iziToast from "izitoast/dist/js/iziToast.min.js";
import * as routes from "../../Common/Routes";
import _ from "lodash";

class Recover extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			errors: {},
		};
	}

	clearError(value) {
		this.setState({ errors: {} });
		this.setState({ email: value });
	}

	handleSubmit = (values, setSubmitting) => {
		this.setState({ errors: {} });
		const error = validateLogin(this.state.email);
		if (_.size(error) > 0) {

			this.setState({ errors: error });
		} else {

			this.setState({ loading: true }, () => {

				auth
					.sendPasswordResetEmail(this.state.email)
					.then(snapShot => {

						iziToast.success({
							title: "Enviado com sucesso!!",
							message: "Verifique seu email!",
							position: "center",
						});
						this.setState({ loading: false });
					})
					.catch(error => {


						if (error.code == "auth/user-not-found") {
							iziToast.error({
								title: "Oops...",
								message: " Usuário não encontrado!",
								position: "center",
							});
						} else {


							iziToast.error({
								title: "Oops...",
								message: " Ocorreu algum erro ao enviar o email, tente novamente!",
								position: "center",
							});
						}
						console.log(error);
						this.setState({ loading: false });
					});
			});
		}
	};
	render() {
		return (
			<div className="access-view-wrapper">
				<Helmet>
					<title>Recuperação de senha</title>
				</Helmet>
				<div className="access-view-wrapper__wrapper access-view-wrapper__wrapper--flex">
					<div className="content-full">
						<h1 className="logo-resenha">
							<img src={LogoResenha} width="216" height="74" title="Resenha" alt="logo Resenha" />
						</h1>
						<Formik
							onSubmit={(values, { setSubmitting }) => this.handleSubmit(values, setSubmitting)}>
							<Form className="default-form default-form--space10">
								<div className="default-form__row">
									<span className="form-label">Informe seu e-mail para recuperar sua senha.</span>
									<label className="form-label">Email:</label>
									<input
										onChange={e => {
											this.clearError(e.target.value);
										}}
										type="email"
										name="email"
										className="default-input default-input--full"
									/>
									{this.state.errors.email ? (
										<div className="error-message">{this.state.errors.email}</div>
									) : (
										<></>
									)}
								</div>
								<div className="wrap-buttons">
									<button
										type="submit"
										className="default-button default-button--mariner clickable">
										{this.state.loading ? "Enviando.." : "Enviar"}
									</button>
								</div>
								<Link className="text-back-to-login" to="/">
									<div className="back-to-login">
										<i class="fas fa-angle-left" />
										<span className="clickable text-back-to-login">Voltar ao login</span>
									</div>
								</Link>
							</Form>
						</Formik>
					</div>
				</div>
				<div className="access-view-wrapper__wrapper">
					<div className="contain-image" />
				</div>
			</div>
		);
	}
}

export default Recover;
