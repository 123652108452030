import React from "react";
import MainLayout from "../../Common/MainLayout";
import { Link } from "react-router-dom";
import postToFunctions from "../../Common/postToFunctions";
import _ from "lodash";
import LoadingScreen from "react-loading-screen";
import iziToast from "izitoast/dist/js/iziToast.min.js";
import moment from "moment";
import * as routes from "../../Common/Routes";
import * as utils from "../../Common/util";

class Animals extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			activities: [],
			loading: false,
			searchText: "",
			searchTextSpecie: "",
			activitiesBackUp: [],
			filterActiviesData: [],
			filterBackUp: [],
			filterName: false,
			searchTextName: "",
			searchTextSpecie: "",
			searchTextRace: "",
			filterSpecie: false,
		};
	}

	async componentDidMount() {
		var body = document.getElementsByTagName("body")[0];

		body.style.cursor = "wait";
		if (this.props.userData) {
			if (this.props.userData.isLaboratory) {
				body.style.cursor = "";
				this.props.history.push("/guides");
			}
		}

		this.setState({ loading: true });
		await this.getAnimals();

	}

	async getAnimals() {
    var body = document.getElementsByTagName("body")[0];
		let { data } = await postToFunctions("getAnimals");
		data = await _.orderBy(data.animals, ["createdAt"], ["desc"]);
		this.setState({ activities: data, activitiesBackUp: data }, () => {
      this.setState({ loading: false });
      body.style.cursor = "";
		});
	}

	async filterForAnimalName() {
		const { activities, searchText, searchTextName } = this.state;
		const animals = await utils.filterByName(activities, searchText);
		this.setState({ filterActiviesData: animals, filterBackUp: animals, filterName: true });
		if (searchTextName == "") this.setState({ filterName: false });
	}

	async filterForAnimaSpecie() {
		var {
			activities,
			searchTextSpecie,
			filterName,
			filterActiviesData,
			filterBackUp,
			filterRace,
		} = this.state;
		var array = [];
		if (searchTextSpecie == "") {
			this.setState({ filterSpecie: false });
			filterActiviesData = filterBackUp;
		}
		if (filterName || filterRace) array = filterActiviesData;
		else array = activities;
		const animals = await utils.filterDinamic(array, searchTextSpecie, "specie");
		this.setState({ filterActiviesData: animals, filterSpecie: true });
	}

	async filterForAnimaRace() {
		var {
			activities,
			searchTextSpecie,
			searchTextRace,
			filterName,
			filterActiviesData,
			filterBackUp,
			filterSpecie,
		} = this.state;
		var array = [];
		if (searchTextRace == "") {
			this.setState({ filterRace: false });
			filterActiviesData = filterBackUp;
		}
		if (filterName || filterSpecie) array = filterActiviesData;
		else array = activities;
		const animals = await utils.filterDinamic(array, searchTextRace, "race");
		this.setState({ filterActiviesData: animals });
	}

	deleteAnimal(uid) {
		iziToast.show({
			theme: "light",
			icon: "fa fa-ban",
			iconColor: "red",
			maxWidth: 700,
			title: "Deseja mesmo excluir esse animal?",
			timeout: false,
			position: "center", // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter
			buttons: [
				[
					'<button style="background-color: #1F6CBC; color:#fff;">Sim</button>',
					async () => {
						const { data } = await postToFunctions("deleteAnimals", { uid });
						iziToast.destroy();
						const activities = await _.reject(this.state.activities, { uid: uid });
						const activitiesBackUp = await _.reject(this.state.activitiesBackUp, { uid: uid });
						this.setState({ activities, activitiesBackUp });
						if (data.success) {
							iziToast.success({
								title: "OK",
								message: "Animal excluído com sucesso!",
								position: "topRight",
							});
						}
					},
					true,
				], // true to focus
				[
					'<button  style="background-color: #fff; border:solid 1px #1F6CBC; color: #1F6CBC">Não</button>',
					function(instance, toast) {
						instance.hide(
							{
								transitionOut: "fadeOutUp",
								onClosing: function(instance, toast, closedBy) {
									console.info("closedBy: " + closedBy); // The return will be: 'closedBy: buttonName'
								},
							},
							toast,
							"buttonName",
						);
					},
				],
			],
		});
	}

	render() {
		const {
			activities,
			searchText,
			filterActiviesData,
			searchTextSpecie,
			searchTextRace,
		} = this.state;
		const activitiesData =
			searchText == "" && searchTextSpecie == "" && searchTextRace == ""
				? activities
				: filterActiviesData;
		return this.state.loading ? (
			<div>
				<LoadingScreen
					loading={this.state.loading}
					bgColor="#fff"
					spinnerColor="#1F6CBC"
					textColor="#1F6CBC"
					logoSrc={""}
					text={<span style={{ fontFamily: "Proxima Nova Semibold" }}>Carregando...</span>}
				/>
			</div>
		) : (
			<MainLayout {...this.props}>
				<div className="main-layout__actions-layout">
					<div className="main-layout__top-content">
						<h2 className="main-layout__title">Animais</h2>
						<div className="search">
							<form>
								<h3 className="main-layout__title">Animais cadastrados</h3>
								<div className="search__field">
									<input
										onChange={e =>
											this.setState(
												{ searchText: e.target.value, searchTextName: e.target.value },
												() => {
													this.filterForAnimalName();
												},
											)
										}
										type="text"
										placeholder="Pesquise pelo nome do animal"
									/>
								</div>

								<div className="search__filter">
									<fieldset class="search__filter-fieldset">
										<i class="icon-filter" />
										Filtro
									</fieldset>
									<div className="search-filter__select">
										<label>Espécie</label>
										<select
											onChange={e =>
												this.setState({ searchTextSpecie: e.target.value }, () => {
													this.filterForAnimaSpecie();
												})
											}
											className="search-filter__select">
											<option value="">Selecione a espécie</option>
											<option value="Equino">Equino</option>
											<option value="Muar">Muar</option>
											<option value="Asinino">Asinino</option>
										</select>
									</div>

									{/* <div className="search-filter__select">
                                        <label>Animal</label>
                                        <select className="search-filter__select">
                                            <option value="">A-Z</option>
                                        </select>
                                    </div> */}
									<div className="search-filter__select">
										<label>Raça</label>
										<input
											onChange={e =>
												this.setState({ searchTextRace: e.target.value }, () => {
													this.filterForAnimaRace();
												})
											}
											className="search-filter__select input_filter_animals"
											type="text"
										/>
									</div>
								</div>
							</form>
						</div>
					</div>
					<div class="right-space">
						<Link to="/animals/new-animal">
							<button class="default-button default-button--mariner default-button--fluid default-button--thin clickable">
								+ Cadastrar novo animal
							</button>
						</Link>
					</div>
				</div>

				{_.size(this.state.activities) > 0 && (
					<table className="main-table">
						<tr>
							<th>Nome do Animal</th>
							<th>Veterinário</th>
							<th>Espécie</th>
							<th>Raça</th>
							<th>Pelagem</th>
							<th>Data de Registro</th>
							<th>Editar</th>
							<th>Excluir</th>
						</tr>
						{_.map(activitiesData, item => {
							var day = moment(item.createdAt).format("DD/MM/YYYY");
							return (
								<tr>
									<td>{item.name}</td>
									<td>{item.veterinaryName}</td>
									<td>{item.specie}</td>
									<td>{item.race}</td>
									<td>{item.coat}</td>
									<td>{day}</td>
									<td>
										<Link to={`/animals/${item.uid}`}>
											<button class="custom-button02 clickable">
												<i className="icon-edit" />
											</button>
										</Link>
									</td>
									<td>
										<button
											onClick={() => {
												this.deleteAnimal(item.uid);
											}}
											class="custom-button02 clickable">
											<i className="icon-trash" />
										</button>
									</td>
								</tr>
							);
						})}
					</table>
				)}
			</MainLayout>
		);
	}
}

export default Animals;
