import axios from "axios";
import _ from 'lodash';


//RETORNA OS DADOS DE ACORDO COM O CEP
export async function getCep(cep) {
    const result = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
    return result.data;
}

//RETORNA TODOS OS ESTADOS
export async function getStates() {
    const result = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
    const states = _.orderBy(result.data,['nome'],['asc']);
    return states;
}

//RETORNA AS CIDADE DE UM ESTADO(NECESSÁRIO ID DO ESTADO)
export async function getCities(UF) {
    const result = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${UF}/municipios`);
    return result.data;
}

//FILTRA POR ESTADO(ID DO ESTADO, ESTADOS, ARRAY A SER FILTRADO)
export async function filterByState(UF, states, array) {
    
    const state = await _.filter(states, state => {
        if (state.id == UF)
        return state
    });

    var result = await _.filter(array, item => {
        return String(item.state)
        .toLowerCase()
        .includes(String(state[0].nome).toLowerCase())
    })

    if(_.size(result)<=0){
         result = await _.filter(array, item => {
            return String(item.state)
            .toLowerCase()
            .includes(String(state[0].sigla).toLowerCase())
        })
    }
    return result
}

//FILTRO POR CIDADE
export async function filterByCity(idCity, cities, citiesBackUp) {
    const city = await _.filter(cities, result => {
        if (result.id == idCity)
            return result
    });
    const result = await _.filter(citiesBackUp, item => {
        return String(item.city)
            .toLowerCase()
            .includes(String(city[0].nome).toLowerCase())
    })

    return result;
}


//FILTRO POR NOME
export async function filterByName(array, searchText) {
    const result = await _.filter(array, item => {
        return String(item.name)
            .toLowerCase()
            .includes(String(searchText).toLowerCase())
    })
    return result;
}

//FILTRO DINÂMICO
export async function filterDinamic(array, searchText,key) {
    const result = await _.filter(array, item => {
        return String(item[key])
            .toLowerCase()
            .includes(String(searchText).toLowerCase())
    })
    return result;
}