import React from "react";
import { Link } from "react-router-dom";
import ToggleSwitch from "../../Components/ToggleSwitch";
import MainLayout from "../../Common/MainLayout";
import postToFunctions from "../../Common/postToFunctions";
import _ from "lodash";
import LoadingScreen from "react-loading-screen";
import * as utils from "../../Common/util";
import iziToast from "izitoast/dist/js/iziToast.min.js";

class Labs extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			activities: [],
			activitiesBackUp: [],
			loading: false,
			states: [],
			cities: [],
			searchText: "",
			filterActiviesData: [],
		};
	}

	async componentDidMount() {
		var body = document.getElementsByTagName("body")[0];

		body.style.cursor = "wait";
		if (this.props.userData) {
			if (this.props.userData.isLaboratory) {
				body.style.cursor = "";
				this.props.history.push("/guides");
			}
		}
		this.setState({ loading: true }, async () => {
			this.getStates();
			let { data } = await postToFunctions("getLabs");

			data = await _.orderBy(data.laboratories, ["createdAt"], ["desc"]);
			this.setState({ activities: data, activitiesBackUp: data }, () => {
				this.setState({ loading: false });
        body.style.cursor = "";
			});
		});
	}

	async getStates() {
		const result = await utils.getStates();
		this.setState({ states: result });
	}

	async getCities(UF) {
		if (UF) {
			const labs = await utils.filterByState(UF, this.state.states, this.state.activitiesBackUp);
			const result = await utils.getCities(UF);
			this.setState({ activities: labs, cities: result });
		} else {
			this.setState({ activities: this.state.activitiesBackUp, cities: [] });
		}
	}

	async filterForCities(idCity) {
		if (idCity) {
			const { cities, activitiesBackUp } = this.state;
			const labs = await utils.filterByCity(idCity, cities, activitiesBackUp);
			this.setState({ activities: labs });
		} else {
			this.setState({ activities: this.state.activitiesBackUp });
		}
	}

	async filterForLabName() {
		const { activities, searchText } = this.state;
		const labs = await utils.filterByName(activities, searchText);
		this.setState({ filterActiviesData: labs });
	}

	deleteLab(uid) {
		iziToast.show({
			theme: "light",
			icon: "fa fa-ban",
			iconColor: "red",
			maxWidth: 700,
			title: "Deseja mesmo excluir esse laboratório?",
			timeout: false,
			position: "center", // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter
			buttons: [
				[
					'<button style="background-color: #1F6CBC; color:#fff;">Sim</button>',
					async () => {
						const { data } = await postToFunctions("deleteLab", { uid });
						iziToast.destroy();
						const activities = await _.reject(this.state.activities, { uid: uid });
						const activitiesBackUp = await _.reject(this.state.activitiesBackUp, { uid: uid });
						this.setState({ activities, activitiesBackUp });
						if (data.success) {
							iziToast.success({
								title: "OK",
								message: "Laboratório excluído com sucesso!",
								position: "topRight",
							});
						}
					},
					true,
				], // true to focus
				[
					'<button  style="background-color: #fff; border:solid 1px #1F6CBC; color: #1F6CBC">Não</button>',
					function(instance, toast) {
						instance.hide(
							{
								transitionOut: "fadeOutUp",
								onClosing: function(instance, toast, closedBy) {
									console.info("closedBy: " + closedBy); // The return will be: 'closedBy: buttonName'
								},
							},
							toast,
							"buttonName",
						);
					},
				],
			],
		});
	}

	handleChangeCallback = async i => {
		const { data } = await postToFunctions("activeLab", { lab: i });
	};

	render() {
		const { activities, searchText, filterActiviesData } = this.state;
		const activitiesData = searchText == "" ? activities : filterActiviesData;
		return this.state.loading ? (
			<div>
				<LoadingScreen
					loading={this.state.loading}
					bgColor="#fff"
					spinnerColor="#1F6CBC"
					textColor="#1F6CBC"
					logoSrc={""}
					text={<span style={{ fontFamily: "Proxima Nova Semibold" }}>Carregando...</span>}
				/>
			</div>
		) : (
			<MainLayout {...this.props}>
				<div className="main-layout__actions-layout">
					<div className="main-layout__top-content">
						<h2 className="main-layout__title">Laboratórios</h2>
						<div className="search">
							<form>
								<h3 className="main-layout__title">Laboratórios cadastrados</h3>
								<div className="search__field">
									<input
										onChange={e =>
											this.setState({ searchText: e.target.value }, () => {
												this.filterForLabName();
											})
										}
										type="text"
										placeholder="Pesquise pelo nome do laboratório"
									/>
								</div>

								<div className="search__filter">
									<fieldset class="search__filter-fieldset">
										<i class="icon-filter"></i>
										Filtro
									</fieldset>
									<div className="search-filter__select">
										<label>Estado</label>
										<select
											onChange={e => {
												this.getCities(e.target.value);
											}}
											component="select"
											name="state"
											className="search-filter__select">
											<option value="">Nome do estado</option>
											{_.map(this.state.states, state => {
												return <option value={state.id}>{state.nome}</option>;
											})}
										</select>
									</div>
									<div className="search-filter__select">
										<label>Cidade</label>
										<select
											onChange={e => {
												this.filterForCities(e.target.value);
											}}
											className="search-filter__select">
											<option value="">Nome da cidade</option>
											{_.map(this.state.cities, city => {
												return <option value={city.id}>{city.nome}</option>;
											})}
										</select>
									</div>
								</div>
							</form>
						</div>
					</div>
					<div class="right-space">
						<Link to="/labs/new-lab">
							<button class="default-button default-button--mariner default-button--fluid default-button--thin clickable">
								+ Cadastrar novo laboratório
							</button>
						</Link>
					</div>
				</div>
				{_.size(activities) > 0 && (
					<table className="main-table">
						<tr>
							<th>Nº de Registro</th>
							<th>Nome do Laboratório</th>
							<th>Email</th>
							<th>Estado</th>
							<th>Cidade</th>
							<th>Ativação</th>
							<th>Editar</th>
							<th>Excluir</th>
						</tr>
						{_.map(activitiesData, item => {
							return (
								<tr>
									<td>{item.registrationNumber}</td>
									<td>{item.name}</td>
									<td>{item.email}</td>
									<td>{item.state}</td>
									<td>{item.city}</td>
									<td>
										<ToggleSwitch
											active={item.active}
											changeCallack={() => this.handleChangeCallback(item)}
										/>
									</td>
									<td>
										<Link to={`/labs/${item.uid}`}>
											<button class="custom-button02 clickable">
												<i className="icon-edit"></i>
											</button>
										</Link>
									</td>
									<td>
										<button
											onClick={() => {
												this.deleteLab(item.uid);
											}}
											class="custom-button02 clickable">
											<i className="icon-trash"></i>
										</button>
									</td>
								</tr>
							);
						})}
					</table>
				)}
			</MainLayout>
		);
	}
}

export default Labs;
