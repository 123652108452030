import React from 'react';
import PropTypes from 'prop-types';
import MainLayout from '../../Common/MainLayout';
import Modal from '../../Components/Modal';
import validateLab from './validation';
import LogoImageUpload from '../../Components/LogoImageUpload';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import PasswordField from '../../Components/PasswordField';
import postToFunctions from '../../Common/postToFunctions';
import FileUpload from '../../Components/FileUpload';
import * as utils from '../../Common/util';
import iconPic from '../../Assets/Images/icon-pic.svg'
import iziToast from 'izitoast/dist/js/iziToast.min.js';
import FormField from '../../Components/FormField';
import _ from 'lodash';
import SelectField from '../../Components/SelectField';
import { phone, cnpj, cellphone, cep } from '../../Common/TextMask';


class Profile extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            isModalDeleteOpen: false,
            isSaveDeleteOpen: false,
            imageUrl: null,
            loading: false,
            user: {},
            isCreating: false,
            states: {},
            cities: {},
            address:{},
            cep:{}
        }
    }

  async  componentDidMount() {
       await this.getStates()
        this.setState({ loading: true }, () => {
            if (this.props.userData) {
                this.setState({ user: this.props.userData, imageUrl: this.props.userData.imageUrl , avatarUrl:this.props.userData.avatarUrl }, () => {
                    if(this.props.userData.stateId){
                        this.setState({stateId:this.props.userData.stateId})
                        this.getCities(this.props.userData.stateId)
                    }
                    this.setState({ loading: false })
                })
            }
        })
    }

    async getStates() {
        const result = await utils.getStates()
        this.setState({ states: result })
    }

    toggleModalDelete = (value) => {
        this.setState({ isModalDeleteOpen: value });
    }

    toggleModalSave = (value) => {
        this.setState({ isSaveDeleteOpen: value });
    }


    handleUploadSuccess = url => {
        this.setState({ avatarUrl: url }, () => {
            iziToast.destroy();
        });
    }
    handleUploadSuccessPhoto = url => {
        this.setState({ imageUrl: url }, () => {
            iziToast.destroy();
        });
    }
    renderLogoFileUpload() {
        return (
            <div className="logo-image-upload">
                <div className="logo-image-upload__image profile-backgroud" 
                style={{ backgroundImage: `url(${this.state.avatarUrl ? this.state.avatarUrl : iconPic})` }}>

                </div>
                <label className="logo-image-upload__button clickable">
                    <FileUpload handleUploadSuccess={url => this.handleUploadSuccess(url)} />
                </label>
            </div>
        )
    }
    renderPhotoLaboratory() {
        return (
            <div className="single-picture-upload">
                <div>

                    <div className="single-picture-upload__image profile-backgroud" style={{ backgroundImage: `url(${this.state.imageUrl ? this.state.imageUrl : iconPic})`,overflow:'inherit' }}>
                        {/* {!this.state.imageUrl && <img width="75" height="49" src={iconPic} alt="" title="" />}
                    {this.state.imageUrl && <img src={this.state.imageUrl} alt="" title="" />} */}
                    <label className="logo-image-upload__button position-button-edit clickable">
                        <FileUpload handleUploadSuccess={url => this.handleUploadSuccessPhoto(url)} />
                    </label>
                    </div>
                </div>
            </div>
        )
    }


    setAddres(e) {
        const { name, value } = e.target;
        this.setState(prevState => ({
            user: {
                ...prevState.user,
                [name]: value
            }
        }));
    }



    async getCities(UF) {
        if (UF) {
            console.log("------------>", UF)
            const stateSelected = await _.filter(this.state.states, (o) => { return o.id == UF })
            const result = await utils.getCities(UF);
            this.setState({ cities: result, stateSelected: stateSelected[0].nome, stateId: UF, siglaState: stateSelected[0].sigla })
        } else {
            this.setState({ cities: [], stateSelected: null, stateId: null })
        }
    }

    cep(v){
        v=v.replace(/D/g,"")                //Remove tudo o que não é dígito
        v=v.replace(/^(\d{5})(\d)/,"$1-$2") //Esse é tão fácil que não merece explicações
        return v
    }

    async getAddress(cep) {
        cep = this.cep(cep)
        this.setState(prevState => ({
            user: {
                ...prevState.user,
                cep: cep
            }
        }));

        const result = await utils.getCep(cep);
        this.setState(prevState => ({
            user: {
                ...prevState.user,
                "cep": cep,
                "state": result.uf,
                "city": result.localidade,
                "neighborhood": result.bairro,
                "street": result.logradouro
            }
        }))

    }


    handleSubmit = async (admin, setSubmitting) => {
        this.setState({ isCreating: true }, async () => {
            admin.uid = this.state.user.uid
            admin.imageUrl = this.state.imageUrl
            admin.stateId = this.state.stateId
            const endPoint = this.state.user.isLaboratory ? "updateLab" : "updateAdmin"
            if(!this.state.user.isLaboratory){
                const { data } = await postToFunctions(endPoint, { admin })
                if (data.success) {
                  window.location.reload();
                    iziToast.success({
                        title: 'Ok',
                        message: "Dados atualizados com sucesso!",
                        position: 'topRight',
                    })
                    this.setState({ isCreating: false })
                }
                else {
                    iziToast.error({
                        title: 'Erro',
                        message: "Ooops.. Houve um erro ao tentar atuzaliar as informações, tente outra vez.",
                        position: 'center',
                    });
                    this.setState({ isCreating: false })
                }
            }else{ 
            admin.avatarUrl = this.state.avatarUrl
            admin.cep = this.state.user.cep
            admin.city = this.state.user.city
            admin.number = this.state.user.number
            admin.state = this.state.user.state
            admin.neighborhood = this.state.user.neighborhood
            admin.street = this.state.user.street
            admin.complement = this.state.user.complement

            const laboratory = admin
            const { data } = await postToFunctions(endPoint, { laboratory })
            if (data.success) {
              window.location.reload();
                iziToast.success({
                    title: 'Ok',
                    message: "Dados atualizados com sucesso!",
                    position: 'topRight',
                })
                this.setState({ isCreating: false })
            }
            else {
                iziToast.error({
                    title: 'Erro',
                    message: "Ooops.. Houve um erro al tentar atuzaliar as informações, tente outra vez.",
                    position: 'center',
                });
                this.setState({ isCreating: false })
            }}
           
        })

    }


   renderLaboratoryProfile(laboratory){

    const {  addressAutoComplete, address } = this.state;
       return( <Formik
        initialValues={{
            name: laboratory.name,
            cnpj: laboratory.cnpj,
            email: laboratory.email,
            phone: laboratory.phone,
            cellphone: laboratory.cellphone,
            incharge: laboratory.incharge,
            incharge_email: laboratory.incharge_email,
            incharge_phone: laboratory.incharge_phone,
            cep: laboratory.cep,
            number: laboratory.number,
            complement: laboratory.complement,
            cnpj: laboratory.cnpj,
            unique_guide:laboratory.unique_guide
        }}
        validate={values => validateLab(values)}
        onSubmit={(values) => { this.handleSubmit(values) }}
        render={props => (
            <Form>
                <div className="main-layout__actions-layout main-layout__actions-layout--align-center">
                    <div className="main-layout__top-content">
                        <div className="breadcrumb">
                            <h2 className="breadcrumb__title">Laboratório</h2>
                            <i className="breadcrumb__arrow breadcrumb__arrow-right"></i>
                            <h3 className="breadcrumb__title breadcrumb__title--subtitle">{this.props.title}</h3>
                        </div>
                    </div>
                    <div className="right-space">
                        {
                            this.props.match.params.lab
                                ?
                                <button disabled={this.state.isCreatelab && !this.state.deleting ? true : false} onClick={() => { this.deleteLab() }} type="button" 
                                className={`default-button ${this.state.isCreatelab ? "disabled-color" : "default-button--uncolored" }   default-button--thinner clickable`}>
                                   
                                    {
                                        this.state.textDeleting
                                        ?"Excluindo..."
                                        :"Excluir"}

                                    </button>
                                :
                                <></>
                        }
                        <button disabled={this.state.isCreatelab && !this.state.deleting ? true : false} 
                        onClick={() => { this.setState({ isPasswordConfirm: false }) }} 
                        type="submit" 
                        className={`default-button ${this.state.isCreatelab ? "disabled-color" : "default-button--shamrock" }  default-button--thinner clickable`}>
                            {this.state.isCreatelab && !this.state.deleting
                            ? "Salvando..." :"Salvar"}
                            </button>
                    </div>
                </div>

                <div className="half-layout">
                    <div className="half-layout__left">
                        <h4 className="main-layout__title">Dados do laboratório</h4>

                        <Field name="name" label={'Nome do laboratório*'} component={FormField} />
                        <Field name="cnpj" label={'CNPJ:'} mask={cnpj} component={FormField} />
                        <Field disabled name="email" style={{"background-color":"#d9d9d9"}} type="email" label={'Email*'} component={FormField} />
                        <Field name="phone" label={'Telefone:'} mask={phone} component={FormField} />
                        <Field name="cellphone" label={'Celular:'} mask={cellphone} component={FormField} />
                        <Field name="incharge" label={'Responsável:'} component={FormField} />
                        <Field name="incharge_email" type="email" label={'Email do responsável:'} component={FormField} />
                        <Field name="incharge_phone" label={'Contato do responsável:'} component={FormField} />
                        <div className="default-form__row">
                        <label className="form-label">Exame único?*</label>
                        <div className="default-form__row--tworows default-form__row--nofluid">
                            <div>
                                <label class="custom-radio">Sim
                                <Field defaultChecked={laboratory.unique_guide === "sim"} type="radio" id="radio1" value="sim" name="unique_guide" />
                                    <span class="custom-radio__checkmark"></span>
                                </label>
                            </div>
                            <div>
                                <label class="custom-radio">Não
                                    <Field defaultChecked={laboratory.unique_guide === "nao"} type="radio" id="radio2" value="nao" name="unique_guide" />
                                    <span class="custom-radio__checkmark"></span>
                                </label>
                            </div>
                        </div>
                        <ErrorMessage name="unique_guide" render={msg => <div className="error-message">{msg}</div>} />
                    </div>
                        <h4 className="main-layout__title">Endereço</h4>
                        <div className="default-form__row default-form__row--onerowcep">
                        <label className="form-label">CEP:</label>
                            <input onChange={(e) => { this.getAddress(e.target.value) }} name="cep" value={this.state.user.cep} type="text" className="default-input default-input--full" />
         
                            {/* <Field  name="cep" mask={cep} label={'CEP:'} component={FormField} /> */}
                        </div>
                        <div className="default-form__row">
                            <label className="form-label">Logradouro:</label>
                            <input onChange={(e) => { this.setAddres(e) }} name="street" type="text" value={address['street'] ? address['street'] : laboratory.street} className="default-input default-input--full" />
                        </div>
                        <div className="default-form__row default-form__row--tworows">
                            <Field name="number" label={'Número:'} component={FormField} />
                            <Field name="complement" label={'Complemento:'} component={FormField} />
                        </div>
                        <div className="default-form__row">
                            <label className="form-label">Bairro:</label>
                            <input onChange={(e) => { this.setAddres(e) }} name="neighborhood" type="text" value={address['neighborhood'] ? address['neighborhood'] : laboratory.neighborhood} className="default-input default-input--full" />
                        </div>
                        <div className="default-form__row">
                            <label className="form-label">Cidade:</label>
                            <input onChange={(e) => { this.setAddres(e) }} name="city" type="text" value={address['city'] ? address['city'] : laboratory.city} className="default-input default-input--full" />
                        </div>
                        <div className="default-form__row default-form__row--onerowcep">
                            <label className="form-label">Estado:</label>
                            <input onChange={(e) => { this.setAddres(e) }} name="state" type="text" value={address['state'] ? address['state'] : laboratory.state} className="default-input default-input--full" />
                        </div>
              
                    </div>
                    <div className="half-layout__right">
                        <h4 className="main-layout__title">Logotipo do laboratório</h4>
                        <div className="upload-field">
                            {this.renderLogoFileUpload()}
     
                        </div>
                        <h4 className="main-layout__title">foto do laboratório</h4>
                        {this.renderPhotoLaboratory()}
                    </div>
                </div>
            </Form>
        )}
    />)
   }


   renderAdminProfile(user){
       return( <Formik

        initialValues={{
            name: user.name,
            email: user.email,
            contact: user.contact,
            city: user.city
        }}
        //validate={values => validateProfile(values)}
        onSubmit={(values, { setSubmitting }) => this.handleSubmit(values, setSubmitting)}
    >
        <Form>
            <div className="main-layout__actions-layout main-layout__actions-layout--align-center">
                <div className="main-layout__top-content">
                    <div className="breadcrumb">
                        <h2 className="breadcrumb__title">Perfil</h2>
                    </div>
                </div>
                <div class="right-space">
                    <button
                        disabled={this.state.isCreating ? true : false}
                        type="submit" class={`default-button ${this.state.isCreating ? "disabled-color" : "default-button--shamrock"} default-button--thinner clickable`}>
                        {this.state.isCreating
                            ? "Salvando..." : "Salvar"}
                    </button>
                </div>
            </div>

            <div className="half-layout">
                <div className="half-layout__left">
                    <div className="upload-field">
                        <LogoImageUpload img={user.imageUrl} avatarUrl={url => { this.setState({ imageUrl: url }) }} />
                    </div>

                    <h4 class="main-layout__title">Dados Pessoais</h4>

                    <Field name="name" label={'Nome:'} component={FormField} />
                    <Field disabled name="email" type="email" style={{"background-color":"#d9d9d9"}}  label={'Email:'} component={FormField} />
                    <Field name="contact" label={'Contato:'} mask={cellphone} component={FormField} />

                    <div className="default-form__row">
                        <>
                            <label className="form-label">Estado</label>
                            <select onChange={e => { this.getCities(e.target.value) }} type="text" className="search-filter__select search-filter__select--large search-filter__select--full">
                                <option value="">Selecione o estado</option>
                                {
                                    _.map(this.state.states, state => {
                                        return (
                                            <option selected={user.stateId == state.id ? true : false} value={state.id}>{state.nome}</option>
                                        )
                                    })
                                }
                            </select>
                        </>
                    </div>

                    <Field name="city" label={'Cidade:'} component={SelectField}>
                        <option value="">Selecione a cidade</option>
                        {
                            _.map(this.state.cities, city => {
                                return (
                                    <option value={city.nome}>{city.nome}</option>
                                )
                            })
                        }
                    </Field>

                    {/* <h4 class="main-layout__title">Senha</h4>

                        <PasswordField label={'Criar senha:'} name="create_password" />
                        <PasswordField label={'Confirmar nova senha:'} name="confirm_password" />

                    <div className="wrap-buttons--center">
                        <button className="default-button default-button--fluid default-button--thinner default-button--mariner">Confirmar senha</button>
                    </div> */}
                </div>
            </div>
        </Form>
    </Formik>)
   }








    render() {
        const { loading, user } = this.state
        return loading ? (<></>) : (
            <>
                <MainLayout  {...this.props} data={this.props.userData}>
                   {
                       user.isLaboratory
                       ?
                       this.renderLaboratoryProfile(user)
                       :
                       this.renderAdminProfile(user)
                   }
                </MainLayout>
            </>
        )
    }
}

Profile.propTypes = {
    title: PropTypes.string.isRequired,
}

export default Profile;