import iziToast from 'izitoast/dist/js/iziToast.min.js';

function validateLab(values, isPassword,isUpdate = false) {
    const errors = {}
    if (isPassword && !isUpdate) {
        if (values.create_password !== values.confirm_password) {
            iziToast.warning({
                title: 'Atenção',
                message: 'As senhas são diferentes',
                position: 'center'
            });
            errors.senha = 'senhas não conferem';
        } else if (!values.create_password || !values.confirm_password) {
            iziToast.warning({
                title: 'Atenção',
                message: 'A senha não pode estar em branco.',
                position: 'center'
            });
        } else {
            iziToast.success({
                title: 'OK',
                message: 'Senhas iguais!',
                position: 'center'
            });
        }
    } 
    if (!values.name) {
        errors.name = 'O nome não está preenchido.';
    } 
    if (!values.email) {

        errors.email = 'O email não está preenchido.';
    }
    if(!values.unique_guide){
        errors.unique_guide = 'Exame obrigatório';
    }
    return errors;
}

export default validateLab;