import React from "react";
import PropTypes from "prop-types";
import MainLayout from "../../Common/MainLayout";
import Modal from "../../Components/Modal";
import { Formik, Form, Field } from "formik";
import { cpf, phone, cep } from "../../Common/TextMask";
import validateOwner from "./validation";
import PasswordField from "../../Components/PasswordField";
import SelectField from "../../Components/SelectField";
import * as utils from "../../Common/util";
import postToFunctions from "../../Common/postToFunctions";
import * as routes from "../../Common/Routes";
import _ from "lodash";
import iziToast from "izitoast/dist/js/iziToast.min.js";
import LoadingScreen from "react-loading-screen";
import { Link } from "react-router-dom";
import FormField from "../../Components/FormField";
import * as mask from "../../Common/mask";

class Owners extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isModalDeleteOpen: false,
			isSaveDeleteOpen: false,
			imageUrl: null,
			veterinaries: {},
			address: {},
			owner: {},
			loading: false,
			erros: {},
			isCreating: false,
			deleting: false,
			textDeleting: false,
		};
	}

	async componentDidMount() {

    var body = document.getElementsByTagName("body")[0];

    body.style.cursor = 'wait'
		this.setState(
			{
				loading: true,
			},
			async () => {
				await this.getVeterinaies();
				if (this.props.match.params.owner) {
					this.setState({ loading: true }, async () => {
						const uid = this.props.match.params.owner;
						const { data } = await postToFunctions("getOwnerById", { uid });
					//	console.log(data)
						const address = {};
						address.cep = data.owner.cep;
						address.street = data.owner.street;
						address.neighborhood = data.owner.neighborhood;
						address.city = data.owner.city;
						address.state = data.owner.state;
						address.state = data.owner.number;
						this.setState({
							owner: data.owner,
							loading: false,
							address,
						});
          });
          body.style.cursor = ''
				} else {
					this.setState({
						loading: false,
          });
          body.style.cursor = ''
				}
			},
		);
	}

	async getVeterinaies() {
		const { data } = await postToFunctions("getVeterinaries");
		this.setState({ veterinaries: data.veterinaries });
	}

	toggleModalDelete = value => {
		this.setState({ isModalDeleteOpen: value });
	};

	toggleModalSave = value => {
		this.setState({ isSaveDeleteOpen: value });
	};

	async verifyErrors(owner) {
		const error = {};
		if (!owner.cep || owner.cep == "") error.errorAdressCep = true;
		if (!owner.street || owner.street == "") error.errorAdressStreet = true;
		if (!owner.number || owner.number == "") error.errorAdressNumber = true;
		if (!owner.neighborhood || owner.neighborhood == "") error.errorAdressNeighborhood = true;
		if (!owner.state || owner.state == "") error.errorAdressState = true;
		if (!owner.city || owner.city == "") error.errorAdressCity = true;
		if (!owner.name || owner.name == "") error.name = true;
		if (!owner.last_name || owner.last_name == "") error.last_name = true;
		if (!owner.cpf || owner.cpf == "") error.cpf = true;
		if (!owner.contact || owner.contact == "") error.contact = true;
		if (!owner.veterinary || owner.veterinary == "") error.veterinary = true;

		this.setState({ erros: error });
		return error;
	}

	handleSubmit = async (ownerOld, setSubmitting) => {
		this.setState({ isCreating: true });
		const { owner } = this.state;
		console.log(owner);
		this.setState({ erros: {} });
		const error = await this.verifyErrors(owner);
		console.log(error);
		if (_.size(error) <= 0) {
			owner.imageUrl = this.state.imageUrl;

			if (!this.props.match.params.owner) {
			} else {
				owner.uid = this.props.match.params.owner;
			}
      const endPoint = this.props.match.params.owner ? "updateOwner" : "createOwner";
      console.log("End Point", endPoint,"OWNER ____>", owner)
      const { data } = await postToFunctions(endPoint, { owner });
      console.log(data)
			if (data.success) {
				iziToast.success({
					title: "OK",
					message: "Dados salvos com sucesso!",
					position: "topRight",
				});
				this.props.history.push(routes.OWNERS);
			} else {
				iziToast.error({
					title: "Erro",
					message: "Tivemos um problema em gravar os dados. Tente novamente",
					position: "center",
				});
			}
		}
		this.setState({ isCreating: false });
	};

	async getAddress(cep) {
		cep = await mask.maskCEP(cep);

		this.setState(prevState => ({
			owner: {
				...prevState.owner,
				cep: cep,
			},
		}));
		this.clearAlertMensage("errorAdressCep", cep);
		const result = await utils.getCep(cep);
		this.clearAlertMensage("errorAdressStreet", cep);
		this.clearAlertMensage("errorAdressNeighborhood", result.bairro);
		this.clearAlertMensage("errorAdressState", result.uf);
		this.clearAlertMensage("errorAdressCity", result.localidade);

		this.setState(prevState => ({
			owner: {
				...prevState.owner,
				cep: cep,
				state: result.uf,
				city: result.localidade,
				neighborhood: result.bairro,
				street: result.logradouro,
			},
		}));
	}

	clearAlertMensage(key, value) {
		if (value != "") {
			this.setState(prevState => ({
				erros: {
					...prevState.erros,
					[key]: false,
				},
			}));
		}
	}

	async setAddres(e, key) {
		const { name, value } = e.target;

		this.clearAlertMensage(key, value);
		this.setState(prevState => ({
			address: {
				...prevState.address,
				[name]: value,
			},
		}));
	}

	async setOwner(key, e) {
		var { name, value } = e.target;
		if (key == "cpf") {
			value = await mask.maskCPF(value);
		}
		if (key == "contact") {
			value = await mask.maskTEL(value);
		}
		this.clearAlertMensage(key, value);
		this.setState(prevState => ({
			owner: {
				...prevState.owner,
				[name]: value,
			},
		}));
	}

	deleteOwner() {
		this.setState({ isCreating: true, deleting: true });
		iziToast.show({
			close: true,
			theme: "light",
			icon: "fa fa-ban",
			iconColor: "red",
			maxWidth: 700,
			title: "Deseja mesmo excluir esse proprietário?",
			timeout: false,
			position: "center", // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter
			buttons: [
				[
					'<button style="background-color: #1F6CBC; color:#fff;">Sim</button>',
					async () => {
						this.setState({ textDeleting: true });
						const { data } = await postToFunctions("deleteOwner", {
							uid: this.props.match.params.owner,
						});
						iziToast.destroy();
						if (data.success) {
							iziToast.success({
								title: "OK",
								message: "Proprietário excluído com sucesso!",
								position: "topRight",
							});
							this.props.history.push(routes.OWNERS);
						} else {
							iziToast.error({
								title: "Erro",
								message: "Ooops... Algo deu errado.",
								position: "center",
							});
						}
					},
					true,
				], // true to focus
				[
					'<button  style="background-color: #fff; border:solid 1px #1F6CBC; color: #1F6CBC">Não</button>',
					async () => {
						this.setState({ isCreating: false, deleting: false });
						iziToast.destroy();
					},
					false,
				],
			],
		});
	}

	render() {
		const { veterinaries, address, owner } = this.state;

		return this.state.loading ? (
			<div>
				<LoadingScreen
					loading={this.state.loading}
					bgColor="#fff"
					spinnerColor="#1F6CBC"
					textColor="#1F6CBC"
					logoSrc={""}
					text={<span style={{ fontFamily: "Proxima Nova Semibold" }}>Carregando...</span>}
				/>
			</div>
		) : (
			<>
				<MainLayout {...this.props}>
					<Formik
						initialValues={{
							name: owner.name,
							last_name: owner.last_name,
							cpf: owner.cpf,
							contact: owner.contact,
							email: owner.email,
							veterinary: owner.veterinary,
							cep: owner.cep,
							incharge_phone: owner.incharge_phone,
							number: owner.number,
							complement: owner.complement,
						}}
						onSubmit={values => {
							this.handleSubmit(values);
						}}>
						<Form>
							<div className="main-layout__actions-layout main-layout__actions-layout--align-center">
								<div className="main-layout__top-content">
									<div className="breadcrumb">
										<Link to={routes.OWNERS}>
											<h2 className="breadcrumb__title">Proprietários</h2>
										</Link>
										<i className="breadcrumb__arrow breadcrumb__arrow-right" />
										<h3 className="breadcrumb__title breadcrumb__title--subtitle">
											{this.props.title}
										</h3>
									</div>
								</div>
								<div class="right-space">
									{this.props.match.params.owner ? (
										<button
											disabled={this.state.isCreating ? true : false}
											type="button"
											onClick={() => {
												this.deleteOwner();
											}}
											class={`default-button ${
												this.state.isCreating ? "disabled-color" : "default-button--uncolored"
											} default-button--thinner clickable`}>
											{this.state.textDeleting ? "Excluindo..." : "Excluir"}
										</button>
									) : (
										<></>
									)}
									<button
										disabled={this.state.isCreating ? true : false}
										onClick={() => {
											this.setState({ isPasswordConfirm: false });
										}}
										type="submit"
										class={`default-button ${
											this.state.isCreating ? "disabled-color" : "default-button--shamrock"
										} default-button--thinner clickable`}>
										{this.state.isCreating && !this.state.deleting ? "Salvando..." : "Salvar"}
									</button>
								</div>
							</div>

							<div className="half-layout">
								<div className="half-layout__left">
									<h4 class="main-layout__title">Dados</h4>
									<div className="default-form__row">
										<label className="form-label">Veterinário*</label>
										<select
											onChange={e => {
												this.setOwner("veterinary", e);
											}}
											type="text"
											name="veterinary"
											className="search-filter__select search-filter__select--large search-filter__select--full">
											<option value="">Selecione o veterinário</option>
											{_.map(veterinaries, item => {
												return (
													<option
														selected={item.uid == owner.veterinary ? true : false}
														value={item.uid}>
														{item.name+" "+item.last_name}
													</option>
												);
											})}
										</select>
										{this.state.erros.veterinary ? (
											<div className="error-message">Selecione o veterinario</div>
										) : (
											<></>
										)}
									</div>
									<div className="default-form__row">
										<label className="form-label">Nome*</label>
										<input
											value={this.state.owner.name}
											className="default-input default-input--full"
											name="name"
											label={"Nome*"}
											onChange={e => {
												this.setOwner("name", e);
											}}
										/>
										{this.state.erros.name ? (
											<div className="error-message">Preencha o nome</div>
										) : (
											<></>
										)}
									</div>
									<div className="default-form__row">
										<label className="form-label">Sobrenome*</label>
										<input
											value={this.state.owner.last_name}
											onChange={e => {
												this.setOwner("last_name", e);
											}}
											className="default-input default-input--full"
											name="last_name"
											label={"Sobrenome*"}
										/>
										{this.state.erros.last_name ? (
											<div className="error-message">Preencha o sobrenome</div>
										) : (
											<></>
										)}
									</div>
									<div className="default-form__row">
										<label className="form-label">CPF*</label>
										<input
											value={this.state.owner.cpf}
											onChange={e => {
												this.setOwner("cpf", e);
											}}
											className="default-input default-input--full"
											name="cpf"
											mask={cpf}
										/>
										{this.state.erros.cpf ? (
											<div className="error-message">Preencha o CPF</div>
										) : (
											<></>
										)}
									</div>

									<div className="default-form__row">
										<label className="form-label">Contato*</label>
										<input
											value={this.state.owner.contact}
											onChange={e => {
												this.setOwner("contact", e);
											}}
											className="default-input default-input--full"
											name="contact"
											mask={phone}
										/>
										{this.state.erros.contact ? (
											<div className="error-message">Preencha o Contato</div>
										) : (
											<></>
										)}
									</div>

									<div className="default-form__row">
										<label className="form-label">Email</label>
										<input
											value={this.state.owner.email}
											onChange={e => {
												this.setOwner("email", e);
											}}
											name="email"
											type="email"
											label={"Email*"}
											className="default-input default-input--full"
										/>
										{this.state.erros.email ? (
											<div className="error-message">Preencha o email</div>
										) : (
											<></>
										)}
									</div>

									<h4 class="main-layout__title">Endereço</h4>

									<div className="default-form__row default-form__row--onerowcep">
										<label className="form-label">CEP</label>
										<input
											onChange={e => {
												this.getAddress(e.target.value);
											}}
											value={this.state.owner.cep}
											name="cep"
											// mask={cep}
											// label={"CEP*"}
											class="default-input default-input--full"
										/>
										{this.state.erros.errorAdressCep ? (
											<div className="error-message">Preencha o CEP</div>
										) : (
											<></>
										)}
									</div>

									<div className="default-form__row">
										<label className="form-label">Logradouro*</label>
										<input
											onChange={e => {
												this.setAddres(e, "errorAdressStreet");
											}}
											name="street"
											type="text"
											value={owner.street}
											className="default-input default-input--full"
										/>

										{this.state.erros.errorAdressStreet ? (
											<div className="error-message">Preencha o logradouro</div>
										) : (
											<></>
										)}
									</div>
									<div className="default-form__row default-form__row--tworows">
										<div>
											<label className="form-label">Numero*</label>
											<input
												onChange={e => {
													this.setOwner("errorAdressNumber", e);
												}}
												name="number"
												type="text"
												value={owner.number}
												className="default-input default-input--full"
											/>

											{this.state.erros.errorAdressNumber ? (
												<div className="error-message">Preencha o numero</div>
											) : (
												<></>
											)}
										</div>
										<div>
											<label className="form-label">Complemento</label>
											<input
												onChange={e => {
													this.setOwner("errorAdressComplement", e);
												}}
												name="complement"
												type="text"
												value={owner.complement}
												className="default-input default-input--full"
											/>
										</div>
									</div>
									<div className="default-form__row">
										<label className="form-label">Bairro*</label>
										<input
											onChange={e => {
												this.setAddres(e, "errorAdressNeighborhood");
											}}
											name="neighborhood"
											type="text"
											value={owner.neighborhood}
											className="default-input default-input--full"
										/>
										{this.state.erros.errorAdressNeighborhood ? (
											<div className="error-message">Preencha o Bairro</div>
										) : (
											<></>
										)}
									</div>
									<div className="default-form__row">
										<label className="form-label">Cidade*</label>
										<input
											onChange={e => {
												this.setAddres(e, "errorAdressCity");
											}}
											name="city"
											type="text"
											value={owner.city}
											className="default-input default-input--full"
										/>
										{this.state.erros.errorAdressCity ? (
											<div className="error-message">Preencha a Cidade</div>
										) : (
											<></>
										)}
									</div>
									<div className="default-form__row default-form__row--onerowcep">
										<label className="form-label">Estado*</label>
										<input
											onChange={e => {
												this.setAddres(e, "errorAdressState");
											}}
											name="state"
											type="text"
											value={owner.state}
											className="default-input default-input--full"
										/>
										{this.state.erros.errorAdressState ? (
											<div className="error-message">Preencha o Estado</div>
										) : (
											<></>
										)}
									</div>
								</div>
							</div>
						</Form>
					</Formik>
				</MainLayout>
			</>
		);
	}
}

Owners.propTypes = {
	title: PropTypes.string.isRequired,
};

export default Owners;
