import React from 'react';
import firebase from 'firebase';
import PropTypes from 'prop-types';
import CustomUploadButton from 'react-firebase-file-uploader';
import iziToast from 'izitoast/dist/js/iziToast.min.js';


class FileUpload extends React.Component {

    handleUploadError = error => {
        console.log(error);
    }

    handleUploadSuccess = filename => {
        firebase
            .storage()
            .ref('images')
            .child(filename)
            .getDownloadURL()
            .then(url => {
                return this.props.handleUploadSuccess(url);
            });
    }

    render() {
        return (
                <CustomUploadButton
                    accept="image/*"
                    name="avatar"
                    randomizeFilename
                    storageRef={firebase.storage().ref('images')}
                    onUploadStart={() => {
                        // iziToast.info({
                        //     timeout: false,
                        //     title: 'Carregando Imagem!',
                        //     position: 'center'
                        // });
                    }}
                    onUploadError={this.handleUploadError.bind(this)}
                    onUploadSuccess={this.handleUploadSuccess.bind(this)}
                    onProgress={() => { }}
                    style={{width:'100%',height:'100%',opacity:0,cursor: 'pointer'}}
                />        
        )
    }
}

FileUpload.propTypes = {
    handleUploadSuccess: PropTypes.func.isRequired,
}

export default FileUpload;

