import React from "react";
import PropTypes from "prop-types";
import MainLayout from "../../Common/MainLayout";
import Modal from "../../Components/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { noexam } from "../../Common/TextMask";
import validateGuide from "./validation";
import FormField from "../../Components/FormField";
import SelectField from "../../Components/SelectField";
import { Link } from "react-router-dom";
import * as routes from "../../Common/Routes";
import iziToast from "izitoast/dist/js/iziToast.min.js";
import postToFunctions from "../../Common/postToFunctions";
import _ from "lodash";
import LoadingScreen from "react-loading-screen";

class Guide extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isModalDeleteOpen: false,
			isSaveDeleteOpen: false,
			imageUrl: null,
			loading: false,
			erros: {},
		};
	}

	toggleModalDelete = value => {
		this.setState({ isModalDeleteOpen: value });
	};

	toggleModalSave = value => {
		this.setState({ isSaveDeleteOpen: value });
	};

	async checkValues(exam) {
		let error = {};
		if (!exam.animal || exam.animal == "") error.animal = true;
		if (!exam.owner || exam.owner == "") error.owner = true;
		if (!exam.lab || exam.lab == "") error.lab = true;
		if (!exam.propertie || exam.propertie == "") error.propertie = true;

		console.log(error);
		this.setState({ erros: error });

		return error;
	}

	handleSubmit = async (values, setSubmitting) => {
		let { exam } = this.state;
		exam.exam_number = values.exam_number;
    var body = document.getElementsByTagName('body')[0]
    
    body.style.cursor = 'wait'

		let error = await this.checkValues(exam);

		if (_.size(error) > 0) {
		} else {
			this.setState({ updating: true });

			let animal = await _.filter(this.state.animals, ["key", exam.animal]);
			let propertie = await _.filter(this.state.properties, ["key", exam.propertie]);
			let owner = await _.filter(this.state.owners, ["key", exam.owner]);
			let lab = await _.filter(this.state.labs, ["key", exam.lab]);

			exam.animalName =  animal.length > 0  ? animal[0].label : "";
			exam.propertieName = propertie.length > 0 ? propertie[0].label : "";
			exam.ownerName = owner.length > 0  ? owner[0].label : "";
			exam.labName = lab.length > 0  ? lab[0].label : "";

			let { data } = await postToFunctions("updateExam", { exam });
      body.style.cursor = ''
			if (data.success) {
				iziToast.success({
					title: "OK",
					message: "Dados salvos com sucesso!",
					position: "topRight",
					timeout: 5000,
				});
				this.props.history.push(routes.GUIDES);
			} else {
				iziToast.error({
					title: "Erro",
					message: "Tivemos um problema em gravar os dados. Tente novamente",
					position: "center",
				});
			}
		}
	};

	async componentDidMount() {
    var body = document.getElementsByTagName('body')[0]
    
    body.style.cursor = 'wait'

		this.setState({ loading: true }, async () => {
			this.setState({
				isLab: this.props.location.state.lab,
				userData: this.props.location.state.user,
			});
      await this.getOptions(this.props.match.params.guide);
      body.style.cursor = ''
    });

	}
	async getOptions(uid) {
		try {
			let vets = await postToFunctions("getVeterinaries");
			let examData = await postToFunctions("getExamById", { uid });
			let { data } = await postToFunctions("getOptionsForCreateExam", {
				uid: examData.data.exam.veterinary,
			});
			this.setState(
				{
					properties: data.propertie,
					animals: data.animals,
					owners: data.owners,
					labs: data.labs,
					exam: examData.data.exam,
					examToPdf: examData.data.exam,
					veterinaries: vets.data.veterinaries,
				},
				() => {
					this.setState({ loading: false });
				},
			);
		} catch (e) {
      this.setState({ loading: false });
			console.log("ERRO ----> ", e);
    }
  
	}

	deleteGuide() {
		this.setState({ textDeleting: true });
		iziToast.show({
			close: false,
			theme: "light",
			icon: "fa fa-ban",
			iconColor: "red",
			maxWidth: 700,
			title: "Deseja mesmo excluir esse guia?",
			timeout: false,
			position: "center", // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter
			buttons: [
				[
					'<button style="background-color: #1F6CBC; color:#fff;">Sim</button>',
					async () => {
						const { data } = await postToFunctions("deleteExam", {
							uid: this.props.match.params.guide,
						});
						iziToast.destroy();
						if (data.success) {
							iziToast.success({
								title: "OK",
								message: "Guia excluído com sucesso!",
								position: "topRight",
								timeout: 5000,
							});
							this.props.history.push(routes.GUIDES);
						} else {
							iziToast.error({
								title: "Erro",
								message: "Ooops... Algo deu errado.",
								position: "center",
							});
						}
					},
					true,
				], // true to focus
				[
					'<button  style="background-color: #fff; border:solid 1px #1F6CBC; color: #1F6CBC">Não</button>',
					async () => {
						this.setState({ isCreatelab: false, textDeleting: false });
						iziToast.destroy();
					},
					false,
				],
			],
		});
	}

	async download() {
    var body = document.getElementsByTagName('body')[0]
    
    body.style.cursor = 'wait'
		this.setState({ pdfText: true }, async () => {
			let element = document.createElement("a");
			let { examToPdf } = this.state;
			let { data } = await postToFunctions("createPdfMormoBase64", { values: examToPdf.uid });
			element.href = data.url;
			element.target = "_blank";
			let teste = await setTimeout(
				function() {
					element.click();
          this.setState({ pdfText: false });
          body.style.cursor = ''
				}.bind(this),
				7000,
			);
		});
	}

	async downloadAie() {
    var body = document.getElementsByTagName('body')[0]
    
    body.style.cursor = 'wait'
		this.setState({ pdfText: true }, async () => {
			let element = document.createElement("a");
			let { examToPdf } = this.state;
			let { data } = await postToFunctions("createPdfAieBase64", { values: examToPdf.uid });
			element.href = data.url;
			element.target = "_blank";
			let teste = await setTimeout(
				function() {
					element.click();
          this.setState({ pdfText: false });
          body.style.cursor = ''
				}.bind(this),
				7000,
			);
		});
	}

	setExam(key, e) {
		let { name, value, text } = e.target;
		this.setState(prevState => ({
			exam: {
				...prevState.exam,
				[key]: value,
			},
			erros: {
				...prevState.erros,
				[key]: false,
			},
		}));
	}

	render() {
		return this.state.loading && !this.state.exam ? (
			<div>
				<LoadingScreen
					loading={this.state.loading}
					bgColor="#fff"
					spinnerColor="#1F6CBC"
					textColor="#1F6CBC"
					logoSrc={""}
					text={<span style={{ fontFamily: "Proxima Nova Semibold" }}>Carregando...</span>}
				/>
			</div>
		) : (
			<>
				<MainLayout {...this.props} data={this.state.userData}>
					<Formik
						initialValues={{
							exam_number: this.state.exam ? this.state.exam.exam_number : "",
						}}
						validate={values => validateGuide(values)}
						onSubmit={(values, { setSubmitting }) => this.handleSubmit(values, setSubmitting)}>
						<Form>
							<div className="main-layout__actions-layout main-layout__actions-layout--align-center">
								<div className="main-layout__top-content">
									<div className="breadcrumb">
										<Link to={routes.GUIDES}>
											<h2 className="breadcrumb__title">Guias</h2>
										</Link>
										<i className="breadcrumb__arrow breadcrumb__arrow-right" />
										<h3 className="breadcrumb__title breadcrumb__title--subtitle">
											{this.props.title}
										</h3>
									</div>
								</div>
								<div class="right-space">
									<button
										type="button"
										disabled={this.state.updating || this.state.isLab ? true : false}
										onClick={() => this.deleteGuide()}
										class={`default-button ${
											this.state.updating || this.state.textDeleting || this.state.isLab
												? "disabled-color"
												: "default-button--uncolored"
										} default-button--thinner ${this.state.isLab ? "" : "clickable"}`}>
										{this.state.textDeleting ? "Excluindo..." : "Excluir"}
									</button>
									<button
										disabled={this.state.updating || this.state.isLab ? true : false}
										type="submit"
										className={`default-button ${
											this.state.updating || this.state.textDeleting || this.state.isLab
												? "disabled-color"
												: "default-button--shamrock"
										} default-button--thinner ${this.state.isLab ? "" : "clickable"}`}>
										{this.state.updating ? "Salvando..." : "Salvar"}
									</button>
								</div>
							</div>

							<div className="half-layout">
								<div className="half-layout__left">
									<h4 class="main-layout__title">Dados da Guia</h4>

									<Field
										name="exam_number"
										//mask={noexam}
										label={"Número da guia*"}
										component={FormField}
										disabled={this.state.isLab ? true : false}
									/>
									<div className="default-form__row">
										<label className="form-label">Doença*</label>
										<select
											disabled={this.state.isLab ? true : false}
											onChange={e => {
												this.setExam("disease", e);
											}}
											type="text"
											name="disease"
											style={{ backgroundColor: this.state.isLab ? "#e6e6e6" : "" }}
											className="search-filter__select search-filter__select--large search-filter__select--full">
											<option
												selected={
													this.state.exam
														? this.state.exam.disease == "Mormo"
															? true
															: false
														: false
												}
												value="Mormo">
												Mormo
											</option>

											<option
												selected={
													this.state.exam
														? this.state.exam.disease == "AIE (Anemia infecciosa equina)"
															? true
															: false
														: false
												}
												value="AIE (Anemia infecciosa equina)">
												AIE (Anemia infecciosa equina)
											</option>
											<option
												selected={
													this.state.exam
														? this.state.exam.disease == "Ambos"
															? true
															: false
														: false
												}
												value="Ambos">
												Ambos
											</option>
										</select>
										{this.state.erros.disease && (
											<div className="error-message">Selecione a doença</div>
										)}
									</div>

									<div className="default-form__row">
										<label className="form-label">Finalidade*</label>
										<select
											style={{ backgroundColor: this.state.isLab ? "#e6e6e6" : "" }}
											disabled={this.state.isLab ? true : false}
											onChange={e => {
												this.setExam("goal", e);
											}}
											type="text"
											name="goal"
											className="search-filter__select search-filter__select--large search-filter__select--full">
											<option
												selected={
													this.state.exam
														? this.state.exam.goal == "Trânsito Nacional"
															? true
															: false
														: false
												}
												value="Trânsito Nacional">
												Trânsito Nacional
											</option>
											<option
												selected={
													this.state.exam
														? this.state.exam.goal == "Trânsito Internacional"
															? true
															: false
														: false
												}
												value="Trânsito Internacional">
												Trânsito Internacional
											</option>
										</select>
										{this.state.erros.goal && (
											<div className="error-message">Selecione a finalidade</div>
										)}
									</div>

									<div className="default-form__row">
										<label className="form-label">Laboratório*</label>
										<select
											style={{ backgroundColor: this.state.isLab ? "#e6e6e6" : "" }}
											disabled={this.state.isLab ? true : false}
											onChange={e => {
												this.setExam("lab", e);
											}}
											type="text"
											name="lab"
											className="search-filter__select search-filter__select--large search-filter__select--full">
											{_.map(this.state.labs, lab => {
												return (
													<option
														selected={lab.key == this.state.exam.lab ? true : false}
														value={lab.key}>
														{lab.label}
													</option>
												);
											})}
										</select>
									</div>

									<div className="default-form__row">
										<label className="form-label">Veterinário*</label>
										<select
											style={{ backgroundColor: this.state.isLab ? "#e6e6e6" : "" }}
											disabled={this.state.isLab ? true : false}
											onChange={e => {
												this.setExam("veterinary", e);
											}}
											type="text"
											name="veterinary"
											className="search-filter__select search-filter__select--large search-filter__select--full">
											{_.map(this.state.veterinaries, item => {
												return (
													<option
														selected={
															this.state.exam
																? this.state.exam.veterinary == item.uid
																	? true
																	: false
																: false
														}
														value={item.uid}>
														{item.name + " " + item.last_name}
													</option>
												);
											})}
										</select>
										{this.state.erros.veterinary && (
											<div className="error-message">Selecione o veterinario</div>
										)}
									</div>

									<div className="default-form__row">
										<label className="form-label">Proprietário*</label>
										<select
											style={{ backgroundColor: this.state.isLab ? "#e6e6e6" : "" }}
											disabled={this.state.isLab ? true : false}
											onChange={e => {
												this.setExam("owner", e);
											}}
											type="text"
											name="owner"
											className="search-filter__select search-filter__select--large search-filter__select--full">
											{_.map(this.state.owners, owner => {
												return (
													<option
														selected={owner.key == this.state.exam.owner ? true : false}
														value={owner.key}>
														{owner.label}
													</option>
												);
											})}
										</select>
										{this.state.erros.owner && (
											<div className="error-message">Selecione o proprietário</div>
										)}
									</div>

									<div className="default-form__row">
										<label className="form-label">Propriedade*</label>
										<select
											style={{ backgroundColor: this.state.isLab ? "#e6e6e6" : "" }}
											disabled={this.state.isLab ? true : false}
											onChange={e => {
												this.setExam("propertie", e);
											}}
											type="text"
											name="propertie"
											className="search-filter__select search-filter__select--large search-filter__select--full">
												<option>Selecione um proprietário</option>
											{_.map(this.state.properties, propertie => {
												return (
													<option
														selected={propertie.key == this.state.exam.propertie ? true : false}
														value={propertie.key}>
														{propertie.label}
													</option>
												);
											})}
										</select>
										{this.state.erros.propertie && (
											<div className="error-message">Selecione a propriedade</div>
										)}
									</div>

									<div className="default-form__row">
										<label className="form-label">Animal*</label>
										<select
											style={{ backgroundColor: this.state.isLab ? "#e6e6e6" : "" }}
											disabled={this.state.isLab ? true : false}
											onChange={e => {
												this.setExam("animal", e);
											}}
											type="text"
											name="animal"
											className="search-filter__select search-filter__select--large search-filter__select--full">
											{_.map(this.state.animals, animal => {
												return (
													<option
														selected={animal.key == this.state.exam.animal ? true : false}
														value={animal.key}>
														{animal.label}
													</option>
												);
											})}
										</select>
										{this.state.erros.animal && (
											<div className="error-message">Selecione o animal</div>
										)}
									</div>
									<div className="wrap-buttons--center wrap-buttons--flex-fluid">
										{/* <button
											type="button"
											className="default-button default-button--withicon default-button--fluid default-button--thinner default-button--mariner clickable">
											<i className="icon-share" />
											Compartilhar
                    </button> */}

										{this.state.exam ? (
											this.state.exam.disease == "Ambos" ? (
												<>
													<button
														onClick={() => {
															this.download();
														}}
														disabled={this.state.pdfText ? true : false}
														type="button"
														className={`default-button default-button--withicon default-button--fluid default-button--thinner  ${
															this.state.pdfText ? "disabled-color wait" : "default-button--uncolored clickable"
														} `}>
														<i className="icon-pdf" />
														{this.state.pdfText ? "Gerando PDF..." : "Baixar PDF Mormo"}
													</button>
													<button
														onClick={() => {
															this.downloadAie();
														}}
														disabled={this.state.pdfText ? true : false}
														type="button"
														className={`default-button default-button--withicon default-button--fluid default-button--thinner  ${
															this.state.pdfText ? "disabled-color wait" : "default-button--uncolored clickable"
														} `}>
														<i className="icon-pdf" />
														{this.state.pdfText ? "Gerando PDF..." : "Baixar PDF Aie"}
													</button>
												</>
                      ) : this.state.exam.disease == "Mormo" ?
                      <>
                      <button
                        onClick={() => {
                          this.download();
                        }}
                        disabled={this.state.pdfText ? true : false}
                        type="button"
                        className={`default-button default-button--withicon default-button--fluid default-button--thinner  ${
                          this.state.pdfText ? "disabled-color wait" : "default-button--uncolored clickable"
                        } `}>
                        <i className="icon-pdf" />
                        {this.state.pdfText ? "Gerando PDF..." : "Baixar PDF Mormo"}
                      </button>
                      </> :
                      <>
                      	<button
														onClick={() => {
															this.downloadAie();
														}}
														disabled={this.state.pdfText ? true : false}
														type="button"
														className={`default-button default-button--withicon default-button--fluid default-button--thinner  ${
															this.state.pdfText ? "disabled-color wait" : "default-button--uncolored clickable"
														} `}>
														<i className="icon-pdf" />
														{this.state.pdfText ? "Gerando PDF..." : "Baixar PDF Aie"}
													</button>
												</>
										) : (
											<></>
										)}
									</div>
								</div>
							</div>
						</Form>
					</Formik>
				</MainLayout>
			</>
		);
	}
}

Guide.propTypes = {
	title: PropTypes.string.isRequired,
};

export default Guide;
