import iziToast from 'izitoast/dist/js/iziToast.min.js';

const TEST_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


function validateProfessionals(values, isPassword = false, isUpdate = false) {
    const errors = {}
    if (isPassword && !isUpdate) {
        if (values.create_password !== values.confirm_password) {
            iziToast.warning({
                title: 'Atenção',
                message: 'As senhas são diferentes',
                position: 'center'
            });
            errors.senha = 'senhas não conferem';
        } else if (!values.create_password || !values.confirm_password) {
            iziToast.warning({
                title: 'Atenção',
                message: 'A senha não pode estar em branco.',
                position: 'center'
            });
        } else {
            iziToast.success({
                title: 'OK',
                message: 'Senhas iguais!',
                position: 'center'
            });
        }
    } 
    if (!values.name) {
        errors.name = 'O nome não está preenchido.';
    } 
    if (!values.cpf) {
        errors.cpf = 'O CPF não está preenchido.';
    } 
    if (!values.email) {
        errors.email = 'O email não está preenchido.';
    } 

    if(!TEST_EMAIL.test(values.email)){
      errors.email = 'Formato do email incorreto.';
    }
    if (!values.crmv) {
        errors.crmv = 'O CRMV não está preenchido.';
    } 
   
    return errors;
}

export default validateProfessionals;