import React, { Component } from "react";

class Terms extends Component {
	render() {
		return (
			<div style={{ padding: '0px 20px' }}>
                <p>
                    <strong>TERMOS DE USO E PERMISSÕES RESENHA APP</strong>
                </p>
                <p>
                    A Equilibrium Soluções Tecnológicas, inscrita no CNPJ 21.865.414/0001- 53 ,
                    situada na Rua Alabastro, n. 244 , Bairro Sagrada Família, em Belo
                    Horizonte, Minas Gerais, CEP 31030 - 080 , é a empresa idealizadora,
                    desenvolvedora e administradora do Resenha App, plataforma de fornece
                    solução relacionada aos exames para ‘mormo’ e ‘anemia infecciosa equina’,
                    através de resenha, por meio do seu App, denominado Resenha App.
                </p>
                <p>
                    A Resenha App não é uma clínica veterinária, tampouco laboratório. Portanto,
                    a Resenha App não possui qualquer responsabilidade, direta ou indireta,
                    pelos exames, diagnósticos etc.
                </p>
                <p>
                    Para os fins deste, “usuários” são aqueles que utilizam, em caráter
                    provisório ou permanente, quaisquer dos serviços ofertados pela Resenha App
                    em sua plataforma. E, para os fins deste, “Acordo” ou “Acordos” significa
                    todos os termos e condições de uso, permissões, privacidades, instrumentos,
                    regulamentos, normas e demais aplicáveis às Partes, Resenha App e usuário.
                </p>
                <p>
                    O aplicativo Resenha App e o seu Conteúdo são de propriedade da Equilibrium.
                    Nós concedemos a você uma permissão limitada, não exclusiva e revogável para
                    fazer uso do aplicativo e uma permissão limitada, não exclusiva e revogável
                    para fazer uso pessoal e não comercial do Conteúdo (coletivamente,
                    "Acesso"). Este Acesso permanecerá em vigor, a menos que seja encerrado por
                    você ou pelo Resenha App.
                </p>
                <p>
                    Você promete e concorda que está usando o aplicativo Resenha App para seu
                    próprio uso pessoal, e que você não irá redistribuir nem transferir o
                    aplicativo Resenha App ou o seu Conteúdo. O Conteúdo e os aplicativos de
                    software do Resenha App não são vendidos nem transferidos para você, e o
                    Resenha App retem a titularidade de todas as cópias dos aplicativos de
                    software Resenha App e do Conteúdo, mesmo após a instalação em seus
                    computadores pessoais, telefones celulares, tablets, e demais dispositivos.
                </p>
                <p>
                    Todas as marcas, nomes comerciais, logotipos, nomes de domínio do aplicativo
                    Resenha App e quaisquer outras características da marca Resenha App são de
                    propriedade exclusiva da Equilibrium. Não há concessão de quaisquer direitos
                    de usar quaisquer características da marca Resenha App seja para uso
                    comercial ou não comercial.
                </p>
                <p>
                    Você concorda em respeitar nossos Acordos, Políticas, Termos de Uso e
                    Privacidade e não usar o aplicativo Resenha App, e seu Conteúdo ou qualquer
                    parte dos mesmos de qualquer forma não expressamente permitida pelos
                    Acordos.
                </p>
                <p>
                    Eventuais softwares de terceiros incluídos no Resenha App são
                    disponibilizados para você consoante os termos da licença aplicável no
                    respectivo software de terceiros.
                </p>
                <p>
                    Ao usar ou acessar o aplicativo Resenha App você concorda com esta
                    declaração, que poderá ser eventualmente atualizada pela Resenha App,
                    independente de prévia comunicação.
                </p>
                <p>
                    <strong>DADOS PESSOAIS</strong>
                </p>
                <p>
                    De acordo com a Lei, a Resenha App, responsável pelo aplicativo, informa a
                    todos os usuários que forneçam ou que vão fornecer seus dados pessoais, que
                    estes serão incorporados a um banco de dados automático.
                </p>
                <p>
                    Ao se cadastrar no Resenha App, haverá a coleta dos dados pessoais dos
                    usuários, que ficarão armazenados nos servidores da Resenha App como: nome
                    completo, endereço, telefone celular, CPF, data de nascimento e e-mail.
                    Estes dados são os mínimos necessários para a regular utilização do App.
                </p>
                <p>
                    Dados do Facebook – Ao utilizar sua conta no Facebook para se integrar aos
                    nossos serviços, poderemos coletar todos os dados que forem disponibilizados
                    pelo Facebook ao nosso sistema, como informações sobre seus anúncios,
                    quantos clicks eles receberam, informações sobre os visitantes aos seus
                    websites que estejam disponíveis nessa plataforma, entre outras.
                </p>
                <p>
                    Dados financeiros - Os dados de pagamento e efetivação da compra, exigidas
                    para o processamento da transação, bem como, os dados do cartão de crédito
                    dos usuários, não são armazenados nos servidores da Resenha App.
                </p>
                <p>
                    Além das informações recebidas durante os procedimentos de cadastro e
                    pagamento, descritos acima, também há o recebimento de informações quando o
                    usuário entra em contato com a Resenha App. Estas informações podem ser
                    incluídas no cadastro de cada usuário.
                </p>
                <p>Coletamos e usamos seus dados pessoais pelos seguintes motivos:</p>
                <span>
                    <p>a) para oferecer, personalizar e melhorar sua experiência com o Resenha App e
                    outros serviços e produtos fornecidos;</p>
                    <p>b) para entender como você acessa e usa o Resenha App, a fim de garantir a
                    funcionalidade técnica do aplicativo, desenvolver novos produtos e serviços e
                    analisar seu uso do Resenha App;</p>
                    <p>c) para nos comunicarmos com você para fins relacionados ao Resenha App;</p>
                    <p>d) para processar seu pagamento e evitar ou detectar fraude, incluindo pagamentos
                    fraudulentos e uso fraudulento do Resenha App;</p>
                    <p>e) para nos comunicarmos com você, diretamente ou por meio de um dos nossos
                    parceiros, para: marketing, pesquisa, participação em concursos, pesquisas e
                    sorteios, promoções, por e-mails, notificações ou outras mensagens, de acordo
                    com as permissões que você possa nos ter fornecido;</p>
                    <p>f) para oferecer recursos, informações, publicidade ou outro conteúdo com base em
                    sua localização específica; e</p>
                    <p>g) para outros fins comerciais legítimos.</p>
                </span>
                <p>
                    Os usuários assumem a responsabilidade sobre as informações pessoais que
                    estão fornecendo à Resenha App para utilização do aplicativo Resenha App,
                    bem como, as consequências de suas decisões.
                </p>
                <p>
                    <strong>COMO USAMOS SUAS INFORMAÇÕES PESSOAIS</strong>
                </p>
                <p>
                    As informações pessoais que obtemos (em alguns casos, em conjunto com
                    informações não-pessoais) nos permitem fornecer nossos serviços a você.
                </p>
                <p>
                    As suas informações são sempre trafegadas utilizando criptografia e
                    guardadas em servidores, que observam padrões rígidos de segurança,
                    inviolabilidade e confiabilidade, de modo a garantir a intimidade, vida
                    privada, honra e imagem dos usuários.
                </p>
                <p>
                    Mediante requerimento do usuário, a Resenha App procederá a exclusão
                    definitiva dos dados pessoais, salvo nos casos de guarda obrigatória.
                </p>
                <p>
                    <strong>CONFIRMAÇÃO DE CONTA</strong>
                </p>
                <p>
                    Para garantir a entrega segura dos nossos serviços, é necessário, se
                    cadastrar com um endereço de e-mail válido. Ao proceder o cadastro, o
                    usuário expressa o consentimento sobre coleta, uso, armazenamento e
                    tratamento de dados pessoais pela Resenha App.
                </p>
                <p>
                    Na efetivação da compra, o cadastro poderá ser submetido ao departamento de
                    segurança de informações do Resenha App, que poderá entrar em contato com o
                    usuário e solicitar detalhamento das informações.
                </p>
                <p>
                    <strong>COM QUEM COMPARTILHAMOS ESTAS INFORMAÇÕES?</strong>
                </p>
                <p>
                    Os dados pessoais serão disponibilizados apenas a empregados autorizados e
                    repassados aos terceiros envolvidos na atividade/operação que se comprometem
                    a cumprir a presente política de privacidade, não sendo compartilhados
                    publicamente ou utilizados para finalidades que não as propostas
                    inicialmente.
                </p>
                <p>
                    Salvo situações legais, a Resenha App não fornecerá a terceiros, a
                    informação de dados pessoais, registros de conexão e de acesso a aplicações
                    de internet.
                </p>
                <p>
                    <strong>MEDIDAS DE SEGURANÇA</strong>
                </p>
                <p>
                    A Resenha App toma precauções, entre elas medidas administrativas, técnicas
                    e físicas, para proteger as suas informações pessoais contra perda, roubo,
                    uso indevido, bem como contra acesso não autorizado, divulgação, alteração e
                    destruição.
                </p>
                <p>
                    A Resenha App garante que adotou as medidas oportunas de segurança em suas
                    instalações, sistemas e servidores, assim como o tratamento confidencial dos
                    Dados Pessoais de acordo com a legislação vigente aplicável.
                </p>
                <p>
                    Garante também a confidencialidade dos Dados Pessoais e o seu tratamento
                    seguro nos movimentos internacionais. No entanto, a Resenha App. revelará às
                    autoridades públicas competentes os Dados Pessoais e qualquer outra
                    informação que esteja em seu poder ou seja acessível através de seus
                    sistemas e seja pedida de acordo com a legislação aplicável.
                </p>
                <p>
                    <strong>CONSENTIMENTO E MUDANÇAS NA POLÍTICA DE PRIVACIDADE</strong>
                </p>
                <p>
                    Ao utilizar nossos serviços, você concorda com a política. Estamos em
                    constante aprimoramento, com novas funcionalidades e novos serviços
                    prestados. Por isso, às vezes é necessário fazer alterações nesta política.
                    A cada vez que isto ocorrer atualizaremos a data da última modificação,
                    disponível em “Sobre”. O uso continuado dos serviços após quaisquer
                    alterações desta política indica sua concordância com os termos revistos.
                </p>
                <p>
                    <strong>DADOS CADASTRADOS</strong>
                </p>
                <p>
                    Ao criar uma conta no Resenha App, o usuário concorda em fornecer
                    informações verdadeiras, completas e atualizadas sobre si mesmo. Caso a
                    Resenha App identifique inconsistências ou eventuais riscos em relação ao
                    seu usuário ou às suas atividades no Resenha App e considere necessário
                    validar as informações cadastradas, o usuário se compromete a fornecer as
                    comprovações que lhe forem requisitadas.
                </p>
                <p>
                    Se tivermos motivos que indiquem que quaisquer informações cadastradas não
                    são verdadeiras, teremos o direito de suspender ou encerrar a conta do
                    usuário, bem como nos recusar a prover quaisquer de nossos serviços.
                </p>
                <p>
                    Qualquer erro, atraso, prejuízo ou dano causado devido ao cadastramento de
                    informações incorretas, incompletas ou desatualizadas é de total
                    responsabilidade do usuário.
                </p>
                <p>
                    <strong>USUÁRIO E SENHA</strong>
                </p>
                <p>
                    O usuário é responsável por manter a confidencialidade de seus ‘dados de
                    usuário’ e sua ‘senha’, criados no ato de registro no Resenha App. O usuário
                    é o único e exclusivamente responsável por todas as atividades que ocorram
                    sob sua conta e se compromete a notificar-nos imediatamente acerca de
                    quaisquer usos não autorizados de sua conta ou quaisquer outras violações de
                    segurança.
                </p>
                <p>
                    A Resenha App não será responsável por quaisquer perdas e danos resultantes
                    de acessos não autorizados ou uso de sua conta.
                </p>
                <p>
                    A titularidade do cadastro é pessoal. Apenas o titular da conta no Resenha
                    App poderá ter acesso aos dados relativos à mesma. Ainda, alterações em
                    dados cadastrados somente poderão ser feitas pelo próprio usuário em sua
                    área logada.
                </p>
                <p>
                    A Resenha App não tem prerrogativa para fazer esse tipo de alteração,
                    independentemente da razão alegada ou circunstância.
                </p>
                <p>
                    <strong>PERMISSÕES</strong>
                </p>
                <p>
                    O usuário permite, para pleno acesso às funcionalidades do aplicativo,
                    acesso às fotos do seu aparelho celular (arquivos de imagens), acesso à
                    câmera do aparelho celular, armazenamento de dados, e demais aplicativos
                    necessários (" <em>Overriding overapps</em> "), bem como publicidades e
                    outras informações, e permite que nossos parceiros de negócios façam o
                    mesmo. Em qualquer parte do Resenha App pode ser influenciado por
                    considerações de natureza comercial.
                </p>
                <p>
                    Demais permissões úteis e/ou necessárias poderão ser requeridas ao usuário,
                    independentemente de notificação prévia, para plena funcionalidade do
                    aplicativo.
                </p>
                <p>
                    <strong>CONTEÚDO GERADO PELO USUÁRIO E INDENIZAÇÕES</strong>
                </p>
                <p>
                    O usuário promete e declara que, no que concerne a qualquer Conteúdo de
                    Usuário que criar e compartilhar, por qualquer meio, a) possui ou tem o
                    direito de postar tal Conteúdo de Usuário; e b) tal Conteúdo de Usuário, ou
                    a sua utilização pelo Resenha App, não viola os Acordos nem quaisquer outros
                    direitos, tampouco lei aplicável, ou nos direitos de propriedade
                    intelectual, de publicidade, de personalidade ou outros direitos de
                    terceiros.
                </p>
                <p>
                    O Resenha App pode, mas não tem a obrigação de, monitorar, revisar ou editar
                    o Conteúdo de Usuário. Em todos os casos, o Resenha App se reserva o direito
                    de remover ou desabilitar o acesso a qualquer Conteúdo de Usuário, por
                    qualquer motivo, incluindo Conteúdo de Usuário que, a critério exclusivo do
                    Resenha App, viole os Acordos ou à lei nacional.
                </p>
                <p>
                    O Resenha App pode tomar essas ações sem notificação prévia para você ou
                    qualquer terceiro. Remover ou desabilitar o acesso ao Conteúdo de Usuário
                    será de nosso exclusivo critério, e não prometemos remover ou desabilitar o
                    acesso a qualquer Conteúdo de Usuário específico.
                </p>
                <p>
                    O usuário é exclusivamente responsável por todos os Conteúdos de Usuário que
                    compartilhar, por qualquer meio. O Resenha App não é responsável pelo
                    Conteúdo de Usuário, técnico ou não, tampouco endossa qualquer opinião
                    contida em qualquer Conteúdo de Usuário.
                </p>
                <p>
                    O usuário concorda que, caso terceiros ingressem com qualquer medida
                    judicial em face do Resenha App relacionada a conteúdo do usuário,
                    compartilhado ou não, este assumirá todos os ônus e responsabilidades
                    judiciais, inclusive indenização, mantendo o Resenha App indene por
                    quaisquer danos, perdas e despesas de qualquer natureza decorrentes de tal
                    medida judicial.
                </p>
                <p>
                    O usuário concorda em indenizar e manter o Resenha App indene contra todos
                    os danos, perdas e despesas de qualquer natureza (incluindo custos e
                    honorários advocatícios) decorrentes de ou relacionados com: a) violação, de
                    sua parte, dos Acordos ou qualquer um desses; b) qualquer Conteúdo de
                    Usuário que compartilhar ou com o qual contribuir; c) qualquer atividade na
                    qual o usuário se envolva no ou através do Resenha App; e d) violação, de
                    sua parte, de qualquer lei ou dos direitos de terceiros.
                </p>
                <p>
                    <strong>PAGAMENTO</strong>
                </p>
                <p>
                    A Resenha App apresentará aos usuários duas versões do aplicativo, sendo uma
                    versão gratuita e outra versão paga, denominada ‘ <em>premium</em> ’.
                </p>
                <p>
                    Os valores, forma de cobrança e pagamento serão expostos pelo próprio
                    aplicativo, podendo ser alterados independentemente de notificação prévia.
                </p>
                <p>
                    A Resenha App poderá, de tempos em tempos, alterar o preço da versão ‘{" "}
                    <em>premium</em> ’, incluindo taxas de assinatura recorrentes, sendo certo
                    que comunicará qualquer alteração de preço para você antecipadamente e, se
                    for o caso, como aceitar as alterações em questão. Alterações de preço
                    surtirão efeitos no início do próximo período de assinatura, após a data da
                    alteração do preço. Sujeito à lei aplicável, você aceita o novo preço ao
                    continuar a usar o Resenha App, após a mudança de preço surtir efeitos. Se
                    você não concordar com uma alteração de preço, você tem o direito de
                    rejeitar a alteração por meio do cancelamento da versão ‘ <em>premium</em> ’
                    antes que a alteração de preço entre em efeito.
                </p>
                <p>
                    A assinatura/contratação da versão ‘ <em>premium</em> ’ será renovada
                    automaticamente no final do período de assinatura aplicável.
                </p>
                <p>
                    O usuário fica ciente, desde já, em ocorrendo a contratação da versão ‘{" "}
                    <em>premium’</em> , caso haja ausência de pagamento regular, os serviços
                    serão imediatamente suspensos ou cancelados, independentemente de prévia
                    notificação, podendo, ainda, a Resenha App adotar as medidas extrajudiciais
                    e judiciais cabíveis para cobrança dos valores, tal como, mas não se
                    limitando, inscrição do usuário nos cadastros de proteção ao crédito.
                </p>
                <p>
                    <strong>
                    ALTERAÇÕES DOS ACORDOS, TERMOS DE USO, PERMISSÕES E POLÍTICAS DE
                    PRIVACIDADE
                    </strong>
                </p>
                <p>
                    Ocasionalmente, nós podemos fazer alterações aos Acordos, Termos de Uso,
                    Permissões e Políticas de Privacidade, por razões válidas, como melhorar as
                    funções ou recursos existentes ou acrescentar novas funções ou recursos ao
                    Resenha App, implementar avanços científicos e tecnológicos, e ajustes
                    técnicos razoáveis, assegurando a operacionalidade ou a segurança do
                    aplicativo, bem como por razões legais ou regulamentares.
                </p>
                <p>
                    Quando realizarmos mudanças, lhe forneceremos um aviso de acordo com as
                    circunstâncias, por exemplo, exibindo uma notificação proeminente ou
                    buscando sua confirmação dentro do aplicativo ou enviando um e-mail para
                    você.
                </p>
                <p>
                    Em alguns casos, nós te notificaremos com antecedência, e seu uso continuado
                    do aplicativo, após terem sido feitas as alterações, constituirá sua
                    aceitação das alterações.
                </p>
                <p>
                    Portanto, por favor certifique-se de ler cuidadosamente qualquer
                    notificação. Se você não quiser continuar a utilizar o Serviço sob a nova
                    versão dos Acordos, você poderá encerrar sua conta entrando em contato
                    conosco.
                </p>
                <p>
                    <strong>LIMITAÇÕES</strong>
                </p>
                <p>
                    O usuário concorda que o seu único e exclusivo recurso para quaisquer
                    problemas ou qualquer insatisfação com os serviços do Resenha App é
                    desinstalar o aplicativo e cessar o uso do serviço.
                </p>
                <p>
                    Em nenhum caso, portanto, o Resenha App, a empresa Equilibrium, seus
                    gestores, sócios, acionistas, parceiros, funcionários, fornecedores ou
                    licenciantes serão responsáveis por: a) qualquer perda ou dano, direto ou
                    indireto, material ou imaterial (moral); b) qualquer perda de uso, dados,
                    informações, arquivos, negócios, oportunidades, lucros e afins; c) danos aos
                    dispositivos; d) o não desempenho ou desempenho inadequado do aplicativo; e)
                    fraudes, declarações fraudulentas, óbito ou danos pessoais causados por
                    negligência e, se requerido pela lei aplicável, negligência grave.
                </p>
                <p>
                    <strong>RENÚNCIA, DIVISIBILIDADE E INTERPRETAÇÃO</strong>
                </p>
                <p>
                    Salvo disposição em contrário, caso qualquer disposição seja considerada
                    nula ou inexequível por qualquer motivo ou em qualquer medida, tal nulidade
                    ou exequibilidade não afetará de qualquer forma nem tornará inválidas ou
                    inexequíveis as disposições restantes dos Acordos.
                </p>
                <p>
                    A inaplicabilidade pela Resenha App dos Acordos ou qualquer uma de suas
                    disposições não significa renúncia de direito ou do respectivo terceiro
                    beneficiário de fazê-lo, tampouco novação ou aditamento de qualquer Acordo.
                </p>
                <p>
                    <strong>CESSÃO DE DIREITOS</strong>
                </p>
                <p>
                    A Resenha App pode ceder quaisquer direitos e Acordos, no todo ou em parte,
                    a terceiros, independentemente de qualquer comunicação ou notificação aos
                    usuários. A Resenha App pode delegar quaisquer obrigações suas ao abrigo dos
                    Acordos.
                </p>
                <p>
                    Por sua vez, o usuário não pode ceder os Acordos, no todo ou em parte, nem
                    transferir ou sublicenciar seus direitos ao abrigo dos Acordos, a qualquer
                    terceiro.
                </p>
                <p>
                    <strong>DÚVIDAS E SUGESTÕES</strong>
                </p>
                <p>
                    Para mais informações sobre os dados pessoais, termos de uso e política de
                    privacidade, consulte direitos de dados e configurações de privacidade
                    diretamente no Resenha App.
                </p>
                <p>
                    Se você tiver dúvidas sobre sua privacidade, nossa Política de Privacidade,
                    informações que temos sobre você, seus direitos e como exercê-los, entre em
                    contato conosco.
                </p>
                <p>
                    E, por fim, se você tiver outras dúvidas e/ou sugestões, consulte a seção
                    Perguntas Frequentes Resenha App ou entre em contato conosco.
                </p>
                <p>
                    Se você fornecer feedback, ideias ou sugestões para o Resenha App, você
                    reconhece que o Feedback não é confidencial e você autoriza o Resenha App a
                    usar esse Feedback, ideias ou sugestões sem restrições e sem pagamentos a
                    você, ou seja, não será devido nenhum tipo de indenização.
                </p>
            </div>
		);
	}
}
export default Terms;
