import React from 'react';

function withUserData(BaseComponent) {
    return class UserData extends React.Component {
        constructor(props) {
            super(props);
    
            this.state = {
                userData: null,
            }
        }
    
        componentWillMount() {
    
            this.setState({
                userData: {
                    userName: this.props.userData ? this.props.userData.name : "Olá",
                    avatar: this.props.userData ? this.props.userData.imageUrl : null
                }
            })
        }
    
        render() {
            return (
                <BaseComponent {...this.props} {...this.state.userData} />
            )
        }
    }
}

export default withUserData;