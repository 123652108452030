import React, { Component } from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import * as routes from "./Common/Routes";
import NotFound from "./Views/NotFound";
import Login from "./Views/Login";
import Counters from "./Views/Counters";
import Labs from "./Views/Labs";
import Guides from "./Views/Guides";
import Professionals from "./Views/Professionals";
import Owners from "./Views/Owners";
import Animals from "./Views/Animals";
import Properties from "./Views/Properties";
import Notifications from "./Views/Notifications";
import Profile from "./Views/Profile";
import Lab from "./Views/Labs/lab";
import Guide from "./Views/Guides/guide";
import Animal from "./Views/Animals/animal";
import Owner from "./Views/Owners/owner";
import Veterinary from "./Views/Professionals/veterinary";
import Propertie from "./Views/Properties/propertie";
import withAuthentication, { AppContext } from "./Common/withAuthentication";
import Recover from "./Views/RecoverPassword";
import Terms from "./Views/Terms";


class App extends Component {
	render() {

		return (
			<AppContext.Consumer>
				{(context) => (
					<Router>
						<Switch>
							<Route exact path={routes.START} component={Login} />
							<Route exact path={routes.COUNTERS} component={props =>
								<Counters title="Home" {...props} {...context} />
							} />
							<Route exact path={routes.LABS} component={props =>
								<Labs title="Laboratórios" {...props} {...context}/>
							} />
							<Route exact path={routes.GUIDES} component={props =>
								<Guides title="Guias" {...props} {...context}/>
							}/>
							<Route exact path={routes.PROFESSIONALS} component={props =>
								<Professionals title="Veterinários" {...props} {...context}/>} 
							/>
							<Route exact path={routes.OWNERS} component={props =>
								<Owners title="Proprietários" {...props} {...context}/>} />
							<Route exact path={routes.ANIMALS} component={props =>
							 <Animals title="Animais" {...props} {...context} />
							}/>
							<Route exact path={routes.PROPERTIES} component={props =>
								<Properties title="Propriedades" {...props} {...context}/>} />
								
							<Route exact path={routes.NOTIFICATIONS} component={props => 
							    <Notifications  title="Notificações" {...props} {...context} />
							} />
							<Route exact path={routes.PROFILE} component={props =>
								<Profile title="Perfil" {...props} {...context} />} />
							<Route exact path={routes.RECOVER_PASSWORD} component={Recover} />

							<Route exact path={routes.NEW_LAB} render={props =>
								<Lab title="Novo laboratório" {...props} />
							} />
							<Route exact path={routes.EDIT_LAB} render={props =>
								<Lab title="Editar laboratório" {...props} />
							} />

							<Route exact path={routes.EDIT_GUIDE} render={props =>
								<Guide title="Editar Guia" {...props} />
							} />

							<Route exact path={routes.NEW_VETERINARY} render={props =>
								<Veterinary title="Novo Veterinário" {...props} />
							} />

							<Route exact path={routes.EDIT_VETERINARY} render={props =>
								<Veterinary title="Editar Veterinário" {...props} />
							} />

							<Route exact path={routes.NEW_OWNER} render={props =>
								<Owner title="Novo Proprietário" {...props} />
							} />

							<Route exact path={routes.EDIT_OWNER} render={props =>
								<Owner title="Editar Proprietário" {...props} />
							} />

							<Route exact path={routes.NEW_PROPERTIE} render={props =>
								<Propertie title="Nova Propriedade" {...props} />
							} />

							<Route exact path={routes.EDIT_PROPERTIE} render={props =>
								<Propertie title="Editar Propriedade" {...props} />
							} />

							<Route exact path={routes.NEW_ANIMAL} render={props =>
								<Animal title="Novo Animal" {...props} />
							} />

							<Route exact path={routes.EDIT_ANIMAL} render={props =>
								<Animal title="Editar Animal" {...props} />
							} />

							<Route exact path={routes.TERMS} render={_ =>
								<Terms title="Termos" />
							} />

							<Route component={NotFound} />
						</Switch>
					</Router>
				)}
			</AppContext.Consumer>

		);
	}
}

export default withAuthentication(App);
