import React from "react";
import Notifications from "../Components/Notifications";
import withAuthentication, { AppContext } from "../Common/withAuthentication";

class NotificationsContainer extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			notifications_list: [],
		};
	}

	componentWillMount() {
		this.setState({
			notifications_list: [
				{
					title: "Notificação xyz",
					locale: "Exame",
				},
				{
					title: "Notificação xyz",
					locale: "Exame",
				},
				{
					title: "Notificação xyz",
					locale: "Exame",
				},
				{
					title: "Notificação xyz",
					locale: "Exame",
				},
				{
					title: "Notificação xyz",
					locale: "Exame",
				},
				{
					title: "Notificação xyz",
					locale: "Exame",
				},
				{
					title: "Notificação xyz",
					locale: "Exame",
				},
				{
					title: "Notificação xyz",
					locale: "Exame",
				},
				{
					title: "Notificação xyz",
					locale: "Exame",
				},
				{
					title: "Notificação xyz",
					locale: "Exame",
				},
			],
		});
	}

	render() {
		return (
			<AppContext.Consumer>
				{context => (
					<Notifications
            {...this.props}
            {...context}
						list={this.state.notifications_list}
						length={this.state.notifications_list.length}
					/>
				)}
			</AppContext.Consumer>
		);
	}
}

export default NotificationsContainer;
