import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';
import * as routes from '../Common/Routes';
import Header from '../Components/Header';
import Menu from '../Components/Menu';
import withAuthorization from './withAuthorization';


class MainLayout extends React.PureComponent {
    

    render() {
        return (
            <div>
                <Helmet>
                    <title>{this.props.title}</title>
                </Helmet>
                <Header {...this.props}/>
                <Menu {...this.props}/>
                <div className="main-layout">
                    {this.props.children}
                </div>
            </div>
        )
    }
}

MainLayout.contextType = {
    authUser: PropTypes.object,
    userData: PropTypes.object,
};

export default withAuthorization(MainLayout);