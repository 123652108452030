import React from "react";
import LogoResenha from '../../Assets/Images/logo_resenha_app_HORIZONTAL@2x.png';
import PasswordField from '../../Components/PasswordField';
import { Link, withRouter } from 'react-router-dom';
import validateLogin from './validation';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { auth } from "../../Config/firebase";
import iziToast from 'izitoast/dist/js/iziToast.min.js';
import * as routes from '../../Common/Routes';
import {Helmet} from 'react-helmet';
import postToFunctions from '../../Common/postToFunctions';



class Login extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			loading: false
		}
  
  }

 async componentWillMount(){
   await auth.onAuthStateChanged(async authUser =>{
      if(!authUser){
            console.log("Entrou no barra")
            this.props.history.push("/") 
        }else{
          console.log("Não Entrou no barra", authUser)
          const {data} = await postToFunctions("getLabById", { uid: authUser.uid })
          if(data.laboratory)
             this.props.history.push(routes.GUIDES) 
          else
             this.props.history.push(routes.COUNTERS)                
        }
    })
  }
  

	handleSubmit = (values, setSubmitting) => {
		this.setState({ loading: true }, () => {
			auth.signInWithEmailAndPassword(values.email, values.password)
				.then(async snapShot => {
					if (snapShot) {
						const result = await postToFunctions("getLabById", { uid: snapShot.user.uid })
						var userData = result.data.laboratory
						var success = result.data.success
						if (!userData) {
							let { data } = await postToFunctions("getAdminData", { uid: snapShot.user.uid })
							userData = data.userData;
							success = data.success
						}
						if (userData.isLaboratory && !userData.active) {
							auth.signOut();
							iziToast.error({
								title: 'Erro',
								message: 'Laboratório inativo. Não foi possível realizar o seu login. Por favor, entre em contato com o administrador.',
								position: 'center'
							});
							this.props.history.push({ pathname: "/" });
							this.setState({ loading: false });
							return
						}
						if (success) {
							const image = userData.imageUrl ? userData.imageUrl : userData.avatarUrl;
							iziToast.success({
								title: 'Bem Vindo ',
								message: userData.name,
								position: 'topRight',
								image: image,
								imageWidth: 100,
							});
							this.setState({ loading: false });
							this.props.history.push({ pathname: `${routes.COUNTERS}` });
						} else {
							auth.signOut();
							iziToast.error({
								title: 'Erro',
								message: 'Usuário não existe!',
								position: 'center'
							});
							this.props.history.push({ pathname: "/" });
							this.setState({ loading: false });
						}
					}
				})
				.catch(error => {
					console.log(error)
					if (error.code == "auth/user-not-found") {
						iziToast.error({
							title: 'Erro',
							message: 'Usuário não existe!',
							position: 'center'
						});
					} else if (error.code == "auth/wrong-password") {
						iziToast.error({
							title: 'Erro',
							message: 'Senha incorreta!',
							position: 'center'
						});
					} else {
						iziToast.error({
							title: 'Erro',
							message: 'Oops.. Algo deu errado!',
							position: 'center'
						});
					}
					this.setState({ loading: false });
				})
		});

	}
	render() {
		return (
			<div className="access-view-wrapper">
				<Helmet>
                    <title>Login</title>
                </Helmet>
				<div className="access-view-wrapper__wrapper access-view-wrapper__wrapper--flex">
					<div className="content-full">
						<h1 className="logo-resenha">
							<img src={LogoResenha} width="216" height="74" title="Resenha" alt="logo Resenha" />
						</h1>
						<Formik
							initialValues={{
								email: '',
								password: ''
							}}
							validate={values => validateLogin(values)}
							onSubmit={(values, { setSubmitting }) => this.handleSubmit(values, setSubmitting)}
						>
							<Form className="default-form default-form--space10">
								<div className="default-form__row">
									<label className="form-label">Email:</label>
									<Field type="email" name="email" className="default-input default-input--full" />
									<ErrorMessage className="error-message" name="email" component="div" />
								</div>
								<div className="default-form__row">
									<label className="form-label">Senha:</label>
									<PasswordField />
								</div>
								<div className="wrap-buttons wrap-buttons--spacebottom30">
									<Link to={routes.RECOVER_PASSWORD} className="link">
										<span>Esqueceu a senha?</span>
									</Link>
								</div>
								<div className="wrap-buttons">
									<button type="submit" className="default-button default-button--mariner clickable">{this.state.loading ? "Entrando..." : "Entrar"}</button>
								</div>
							</Form>
						</Formik>
					</div>
				</div>
				<div className="access-view-wrapper__wrapper">
					<div className="contain-image"></div>
				</div>
			</div>
		)
	}
}

export default withRouter(Login);
