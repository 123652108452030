import React from "react";
import MainLayout from "../../Common/MainLayout";
import withAuthorization from "../../Common/withAuthorization";
import PropTypes from "prop-types";
import iziToast from "izitoast/dist/js/iziToast.min.js";
import postToFunctions from "../../Common/postToFunctions";
import LoadingScreen from "react-loading-screen";
import _ from "lodash";
import moment from "moment";
import * as routes from "../../Common/Routes";

class Counters extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			counters: [],
			loading: false,
			activities: [],
		};
	}
	async componentDidMount() {
		var body = document.getElementsByTagName("body")[0];
		body.style.cursor = "wait";
		if (this.props.userData) {
			if (this.props.userData.isLaboratory) {
				body.style.cursor = "";
				this.props.history.push("/guides");
			} else {
				this.setState({ loading: true }, async () => {
					await this.getCounters();
          this.setState({ loading: false });
          body.style.cursor = "";
				});
			}
		}
	}

	async getCounters() {
		const { data } = await postToFunctions("getCounters");
		this.setState({ counters: data.counters, loading: false, activities: data.concat });
	}

	gotoPage(item) {
		let route = false;
		if (item.type == "USUÁRIO") route = routes.PROFESSIONALS;
		if (item.type == "PROPRIEDADE") route = routes.PROPERTIES;
		if (item.type == "PROPRIETÁRIO") route = routes.OWNERS;
		if (item.type == "ANIMAL") route = routes.ANIMALS;
		if (item.type == "LABORATÓRIO") route = routes.LABS;

		this.props.history.push(route);
	}

	render() {
		return this.state.loading ? (
			<div>
				<LoadingScreen
					loading={this.state.loading}
					bgColor="#fff"
					spinnerColor="#1F6CBC"
					textColor="#1F6CBC"
					logoSrc={""}
					text={<span style={{ fontFamily: "Proxima Nova Semibold" }}>Carregando...</span>}
				/>
			</div>
		) : (
			<MainLayout {...this.props}>
				<div className="main-layout__top-content">
					<h2 className="main-layout__title">Contadores</h2>

					<div>
						{this.state.counters.length > 0 &&
							this.state.counters.map(item => (
								<div className="box-counter">
									<span className="box-counter__number">{item.number}</span> <br />
									<span className="box-counter__text">{item.label}</span>
								</div>
							))}
					</div>

					<h3 className="main-layout__title">Últimas atividades</h3>
				</div>
				{this.state.activities.length > 0 && (
					<table className="main-table">
						<tr>
							<th>Data e Hora</th>
							<th>Tipo de Cadastro</th>
							<th>Identificação</th>
							{/* <th>Código</th> */}
							<th>Visualização</th>
						</tr>
						{_.map(this.state.activities, item => {
							return (
								<tr>
									<td>
										{" "}
										{moment(item.createdAt).format("DD/MM/YYYY") +
											" " +
											moment(item.createdAt).format("HH:mm:ss")}
									</td>
									<td>{item.type}</td>
									<td>{item.name}</td>
									{/* <td>{item.code}</td> */}
									<td>
										<button
											class="custom-button01 custom-button01--white custom-button01--icon clickable"
											onClick={() => this.gotoPage(item)}>
											<i className="icon-eye" />
											Visualizar
										</button>
									</td>
								</tr>
							);
						})}
					</table>
				)}
			</MainLayout>
		);
	}
}

export default withAuthorization(Counters);
