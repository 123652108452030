import React from "react";
import * as routes from "../../Common/Routes";
import PropTypes from "prop-types";
import postToFunctions from "../../Common/postToFunctions";
import _ from "lodash";

class Notifications extends React.PureComponent {
	state = {
		showNotifications: false,
		notifications: {},
		count: {},
	};

	container = React.createRef();

	async componentDidMount() {
		if (this.props.userData) {
			let { data } = await postToFunctions("getNotifications", {
				uid: this.props.userData.uid,
				isLab: this.props.userData.isLaboratory,
			});
			let count = await _.filter(data.notify, { read: false });
			this.setState({ notifications: data.notify, count });
		}
		document.addEventListener("mousedown", this.handlerClickOutside);
	}

	handlerClickOutside = event => {
		if (this.container.current && !this.container.current.contains(event.target)) {
			this.setState({ showNotifications: false });
		}
	};

	toggleNotifications() {
		this.setState({ showNotifications: !this.state.showNotifications });
	}

	async gotoNotifications() {
		console.log(this.state.notifications);
		await postToFunctions("setNotificationRead", { notifications: this.state.notifications });
		if (this.props.match.path == "/notifications") window.location.reload();
		else this.props.history.push(routes.NOTIFICATIONS);
	}

	render() {
		return (
			<div className="notifications" ref={this.container}>
				<button
					className="notifications__button clickable"
					onClick={this.toggleNotifications.bind(this)}>
					{_.size(this.state.count) > 0 && (
						<span className="notifications__length">{_.size(this.state.count)}</span>
					)}
					<i className="bell-icon" />
				</button>

				{this.state.showNotifications && (
					<div className="notifications__list-wrapper">
						<div className="notifications__list-wrapper-title">Notificações</div>
						<ul className="notifications__list-wrapper-list">
							{_.map(this.state.notifications, item => {
								return (
									<li
										onClick={() => {
											this.gotoNotifications();
										}}
										className="notifications__list-wrapper-list-item clickable"
										style={{ backgroundColor: `${item.read ? "#ffff" : "#cce6ff"}` }}>
										<span className="title">{item.title}</span>
										<br />
										<span className="locale">{item.locale}</span>
									</li>
								);
							})}
						</ul>
					</div>
				)}
			</div>
		);
	}
}

Notifications.propTypes = {
	length: PropTypes.number.isRequired,
	list: PropTypes.array.isRequired,
};

export default Notifications;
