import React from "react";
import MainLayout from "../../Common/MainLayout";
import postToFunctions from "../../Common/postToFunctions";
import { Link } from "react-router-dom";
import _ from "lodash";
import LoadingScreen from "react-loading-screen";
import * as utils from "../../Common/util";
import moment from "moment";
import iziToast from "izitoast/dist/js/iziToast.min.js";

class Owners extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			activities: [],
			activitiesBackUp: [],
			states: [],
			loadin: false,
			searchText: "",
			cities: [],
			filterActiviesData: [],
			filterBackUp: [],
		};
  }
  
  componentWillMount(){
    var body = document.getElementsByTagName("body")[0];

		body.style.cursor = "wait";
  }

	async componentDidMount() {
		var body = document.getElementsByTagName("body")[0];

		body.style.cursor = "wait";
		if (this.props.userData) {
			if (this.props.userData.isLaboratory) {
				body.style.cursor = "wait";
				this.props.history.push("/guides");
			}
		}
		this.setState({ loading: true }, async () => {
			this.getStates();
			await this.getOwners();
		},()=>{
      body.style.cursor = "";
    });
	}

	async getStates() {
		const result = await utils.getStates();
		this.setState({ states: result });
	}

	async getOwners() {
    var body = document.getElementsByTagName("body")[0];


		let { data } = await postToFunctions("getOwners");

		data = await _.orderBy(data.owners, ["createdAt"], ["desc"]);

		this.setState({ activities: data, activitiesBackUp: data }, () => {
      this.setState({ loading: false });
      body.style.cursor = "";
		});
	}

	async getCities(uf) {
		if (uf) {
			const activities = await utils.filterByState(
				uf,
				this.state.states,
				this.state.activitiesBackUp,
			);
			const cities = await utils.getCities(uf);
			const owner = await utils.filterByName(activities, this.state.searchText);
			this.setState({ activities, cities, filterActiviesData: owner });
		} else {
			this.setState({
				activities: this.state.activitiesBackUp,
				cities: [],
				filterActiviesData: this.state.filterBackUp,
			});
		}
	}

	async filterForOwnerName() {
		const { activities, searchText } = this.state;
		const owner = await utils.filterByName(activities, searchText);
		this.setState({ filterActiviesData: owner, filterBackUp: owner });
	}

	async filterForCities(idCity) {
		if (idCity) {
			const { cities, activitiesBackUp } = this.state;
			const activities = await utils.filterByCity(idCity, cities, activitiesBackUp);
			const veterinary = await utils.filterByName(activities, this.state.searchText);

			this.setState({ activities, filterActiviesData: veterinary });
		} else {
			this.setState({
				activities: this.state.activitiesBackUp,
				filterActiviesData: this.state.filterBackUp,
			});
		}
	}

	deleteOwner(uid) {
		iziToast.show({
			theme: "light",
			icon: "fa fa-ban",
			iconColor: "red",
			maxWidth: 700,
			title: "Deseja mesmo excluir esse proprietário?",
			timeout: false,
			position: "center", // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter
			buttons: [
				[
					'<button style="background-color: #1F6CBC; color:#fff;">Sim</button>',
					async () => {
						var body = document.getElementsByTagName("body")[0];

						body.style.cursor = "wait";
						const { data } = await postToFunctions("deleteOwner", { uid });
						iziToast.destroy();
						const activities = await _.reject(this.state.activities, { uid: uid });
						const activitiesBackUp = await _.reject(this.state.activitiesBackUp, { uid: uid });
						this.setState({ activities, activitiesBackUp });
						body.style.cursor = "";
						if (data.success) {
							iziToast.success({
								title: "OK",
								message: "Proprietário excluído com sucesso!",
								position: "topRight",
							});
						}
					},
					true,
				], // true to focus
				[
					'<button  style="background-color: #fff; border:solid 1px #1F6CBC; color: #1F6CBC">Não</button>',
					function(instance, toast) {
						instance.hide(
							{
								transitionOut: "fadeOutUp",
								onClosing: function(instance, toast, closedBy) {
									console.info("closedBy: " + closedBy); // The return will be: 'closedBy: buttonName'
								},
							},
							toast,
							"buttonName",
						);
					},
				],
			],
		});
	}

	render() {
		const { activities, searchText, filterActiviesData, activitiesBackUp } = this.state;
		const activitiesData = searchText == "" ? activities : filterActiviesData;
		return this.state.loading ? (
			<div>
				<LoadingScreen
					loading={this.state.loading}
					bgColor="#fff"
					spinnerColor="#1F6CBC"
					textColor="#1F6CBC"
					logoSrc={""}
					text={<span style={{ fontFamily: "Proxima Nova Semibold" }}>Carregando...</span>}
				/>
			</div>
		) : (
			<MainLayout {...this.props}>
				<div className="main-layout__actions-layout">
					<div className="main-layout__top-content">
						<h2 className="main-layout__title">Proprietários</h2>
						<div className="search">
							<form>
								<h3 className="main-layout__title">Proprietários cadastrados</h3>
								<div className="search__field">
									<input
										onChange={e =>
											this.setState({ searchText: e.target.value }, () => {
												this.filterForOwnerName();
											})
										}
										type="text"
										placeholder="Pesquise por nome do proprietário"
									/>
								</div>

								<div className="search__filter">
									<fieldset class="search__filter-fieldset">
										<i class="icon-filter"></i>
										Filtro
									</fieldset>
									<div className="search-filter__select">
										<label>Estado</label>
										<select
											onChange={e => {
												this.getCities(e.target.value);
											}}
											className="search-filter__select">
											<option value="">Selecione o estado</option>
											{_.map(this.state.states, state => {
												return <option value={state.id}>{state.nome}</option>;
											})}
										</select>
									</div>
									<div className="search-filter__select">
										<label>Cidade</label>
										<select
											onChange={e => {
												this.filterForCities(e.target.value);
											}}
											className="search-filter__select">
											<option value="">Selecione a cidade</option>
											{_.map(this.state.cities, city => {
												return <option value={city.id}>{city.nome}</option>;
											})}
										</select>
									</div>
								</div>
							</form>
						</div>
					</div>
					<div class="right-space">
						<Link to="/owners/new-owner">
							<button class="default-button default-button--mariner default-button--fluid default-button--thin clickable">
								+ Cadastrar novo proprietário
							</button>
						</Link>
					</div>
				</div>
				{_.size(this.state.activities) > 0 ? (
					<table className="main-table">
						<tr>
							<th>Nome do Proprietário</th>
							<th>Contato</th>
							<th>Cidade</th>
							<th>Estado</th>
							<th>Data de Cadastro</th>
							<th>Editar</th>
							<th>Excluir</th>
						</tr>
						{_.map(activitiesData, item => {
							var day = moment(item.createdAt).format("DD/MM/YYYY");

							return (
								<tr>
									<td>{item.name}</td>
									<td>{item.contact}</td>
									<td>{item.city}</td>
									<td>{item.state}</td>
									<td>{day}</td>
									<td>
										<Link to={`/owners/${item.uid}`}>
											<button class="custom-button02 clickable">
												<i className="icon-edit"></i>
											</button>
										</Link>
									</td>
									<td>
										<button
											onClick={() => {
												this.deleteOwner(item.uid);
											}}
											class="custom-button02 clickable">
											<i className="icon-trash"></i>
										</button>
									</td>
								</tr>
							);
						})}
					</table>
				) : (
					<></>
				)}
			</MainLayout>
		);
	}
}

export default Owners;
