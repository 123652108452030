import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import LogoResenha from '../../Assets/Images/logo_resenha_app_HORIZONTAL@2x.png';
import NotificationsContainer from '../../Containers/NotificationsContainer';
import UserGreetingContainer from '../../Containers/UserGreetingContainer';
import { auth } from "../../Config/firebase";
import * as routes from '../../Common/Routes'
import PropTypes from "prop-types";
import withAuthentication, { AppContext } from "../../Common/withAuthentication";


class Header extends Component {
    render() {
        return (
            <AppContext.Consumer>
                {(context)=>(
                <div className="header">
                    <h1 className="header__logo logo-resenha">
                      <a href="/counters">
                        <img src={LogoResenha} width="134" height="47" title="Resenha" alt="logo Resenha" />
                      </a>
                    </h1>
                      
                    <div className="header__right-content">

                        <UserGreetingContainer  {...context} />
                        <NotificationsContainer {...this.props}/>

                        <div className="logout">

                            <button onClick={() => {
                                auth.signOut();
                            }} className="logout__button clickable">
                                <i className="icon-logout"></i><span>Sair</span>
                            </button>

                        </div>
                    </div>
                </div>)}
                
            </AppContext.Consumer>
        )
    }
}

export default Header;