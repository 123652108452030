import React from "react";
import MainLayout from "../../Common/MainLayout";
import postToFunctions from "../../Common/postToFunctions";
import moment from "moment";
import LoadingScreen from "react-loading-screen";
import _ from "lodash";
import iziToast from "izitoast/dist/js/iziToast.min.js";
import * as utils from "../../Common/util";
import { Link } from "react-router-dom";

class Properties extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			activities: [],
			loading: false,
			searchText: "",
			filterActiviesData: [],
			filterBackUp: [],
			modifiedFilter: false,
		};
	}

	componentWillMount() {
		var body = document.getElementsByTagName("body")[0];

		body.style.cursor = "wait";
	}

	async componentDidMount() {
		var body = document.getElementsByTagName("body")[0];
		body.style.cursor = "wait";
		if (this.props.userData) {
			if (this.props.userData.isLaboratory) {
				body.style.cursor = "";
				this.props.history.push("/guides");
			}
		}
		this.setState({ loading: true }, async () => {
			this.getStates();
			let { data } = await postToFunctions("getProperty");

			data = await _.orderBy(data.properties, ["createdAt"], ["desc"]);

			this.setState({ activities: data, activitiesBackUp: data }, () => {
				this.setState({ loading: false });
				body.style.cursor = "";
			});
		});
	}

	async getCities(uf) {
		if (uf) {
			const activities = await utils.filterByState(
				uf,
				this.state.states,
				this.state.activitiesBackUp,
			);
			const cities = await utils.getCities(uf);
			const owner = await utils.filterByName(activities, this.state.searchText);
			this.setState({ activities, cities, filterActiviesData: owner, modifiedFilter: true });
		} else {
			this.setState({
				activities: this.state.activitiesBackUp,
				cities: [],
				filterActiviesData: this.state.filterBackUp,
				modifiedFilter: false,
			});
		}
	}

	async filterByTypeProperty(index) {
		if (index) {
			const array =
				this.state.searchText !== "" || this.state.modifiedFilter
					? this.state.filterActiviesData
					: this.state.activitiesBackUp;
			const activities = await utils.filterDinamic(array, index, "propertie");
			this.setState({ activities });
		} else {
			this.setState({ activities: this.state.activitiesBackUp });
		}
	}

	async filterForCities(idCity) {
		if (idCity) {
			const { cities, activitiesBackUp } = this.state;
			const activities = await utils.filterByCity(idCity, cities, activitiesBackUp);
			const veterinary = await utils.filterByName(activities, this.state.searchText);

			this.setState({ activities, filterActiviesData: veterinary });
		} else {
			this.setState({
				activities: this.state.activitiesBackUp,
				filterActiviesData: this.state.filterBackUp,
			});
		}
	}

	async filterForPropertyName() {
		const { activities, searchText } = this.state;
		const property = await utils.filterByName(activities, searchText);
		this.setState({ filterActiviesData: property, filterBackUp: property });
	}

	async getStates() {
		const result = await utils.getStates();
		this.setState({ states: result });
	}

	deletePropriety(uid) {
		iziToast.show({
			theme: "light",
			icon: "fa fa-ban",
			iconColor: "red",
			maxWidth: 700,
			title: "Deseja mesmo excluir essa propriedade?",
			timeout: false,
			position: "center", // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter
			buttons: [
				[
					'<button style="background-color: #1F6CBC; color:#fff;">Sim</button>',
					async () => {
						var body = document.getElementsByTagName("body")[0];

						body.style.cursor = "wait";
						const { data } = await postToFunctions("deleteProperty", { uid });
						iziToast.destroy();
						const activities = await _.reject(this.state.activities, { uid: uid });
						const activitiesBackUp = await _.reject(this.state.activitiesBackUp, { uid: uid });
						this.setState({ activities, activitiesBackUp });
						body.style.cursor = "";
						if (data.success) {
							iziToast.success({
								title: "OK",
								message: "Propriedade excluída com sucesso!",
								position: "topRight",
							});
						}
					},
					true,
				], // true to focus
				[
					'<button  style="background-color: #fff; border:solid 1px #1F6CBC; color: #1F6CBC">Não</button>',
					function (instance, toast) {
						instance.hide(
							{
								transitionOut: "fadeOutUp",
								onClosing: function (instance, toast, closedBy) {
									console.info("closedBy: " + closedBy); // The return will be: 'closedBy: buttonName'
								},
							},
							toast,
							"buttonName",
						);
					},
				],
			],
		});
	}

	render() {
		const { activities, searchText, filterActiviesData, activitiesBackUp } = this.state;
		const activitiesData = searchText == "" ? activities : filterActiviesData;
		return this.state.loading ? (
			<div>
				<LoadingScreen
					loading={this.state.loading}
					bgColor="#fff"
					spinnerColor="#1F6CBC"
					textColor="#1F6CBC"
					logoSrc={""}
					text={<span style={{ fontFamily: "Proxima Nova Semibold" }}>Carregando...</span>}
				/>
			</div>
		) : (
				<MainLayout {...this.props}>
					<div className="main-layout__actions-layout">
						<div className="main-layout__top-content">
							<h2 className="main-layout__title">Propriedades</h2>
							<div className="search">
								<form>
									<h3 className="main-layout__title">Propriedades cadastradas</h3>
									<div className="search__field">
										<input
											onChange={e =>
												this.setState({ searchText: e.target.value }, () => {
													this.filterForPropertyName();
												})
											}
											type="text"
											placeholder="Pesquise pelo nome da propriedade"
										/>
									</div>

									<div className="search__filter" style={{ display: "inline-block" }}>
										<fieldset class="search__filter-fieldset">
											<i class="icon-filter"></i>
											Filtro
									</fieldset>
										<div className="search-filter__select">
											<label>Estado</label>
											<select
												onChange={e => {
													this.getCities(e.target.value);
												}}
												className="search-filter__select">
												<option value="">Selecione o estado</option>
												{_.map(this.state.states, state => {
													return <option value={state.id}>{state.nome}</option>;
												})}
											</select>
										</div>
										<div className="search-filter__select">
											<label>Cidade</label>
											<select
												onChange={e => {
													this.filterForCities(e.target.value);
												}}
												className="search-filter__select">
												<option value="">Selecione a cidade</option>
												{_.map(this.state.cities, city => {
													return <option value={city.id}>{city.nome}</option>;
												})}
											</select>
										</div>

										<div className="search-filter__select">
											<label>Tipo de Propriedade</label>
											<select
												onChange={e => {
													this.filterByTypeProperty(e.target.value);
												}}
												className="search-filter__select">
												<option value="">Selecione o tipo</option>
												<option value="Haras">Haras</option>
												<option value="Fazenda">Fazenda</option>
												<option value="Sítio">Sítio</option>
											</select>
										</div>
									</div>
								</form>
							</div>
						</div>
						<div class="right-space-property">
							<Link to="/properties/new-propertie">
								<button
									class="default-button default-button--mariner default-button--thin clickable"
									style={{ width: 278 }}>
									<span>+ Cadastrar nova propriedade</span>
								</button>
							</Link>
						</div>
					</div>

					{_.size(this.state.activities) > 0 && (
						<table className="main-table">
							<tr>
								<th>Nome da Propriedade</th>
								<th>Contato</th>
								<th>Tipo de Propriedade</th>
								<th>Município/UF</th>
								<th>Data de Cadastro</th>
								<th>Editar</th>
								<th>Excluir</th>
							</tr>
							{_.map(activitiesData, item => {
								var day = moment(item.createdAt).format("DD/MM/YYYY");
								return (
									<tr>
										<td>{item.name}</td>
										<td>{item.phone}</td>
										<td>{item.propertie}</td>
										<td>{item.city + "/" + item.state}</td>
										<td>{day}</td>
										<td>
											<Link to={`/properties/${item.uid}`}>
												<button class="custom-button02 clickable">
													<i className="icon-edit"></i>
												</button>
											</Link>
										</td>
										<td>
											<button
												onClick={() => {
													this.deletePropriety(item.uid);
												}}
												class="custom-button02 clickable">
												<i className="icon-trash"></i>
											</button>
										</td>
									</tr>
								);
							})}
						</table>
					)}
				</MainLayout>
			);
	}
}

export default Properties;
