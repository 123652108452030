import React from "react";
import { auth } from '../Config/firebase'
import * as routes from '../Common/Routes';
import { withRouter } from 'react-router-dom';
import postToFunctions from './postToFunctions';
import PropTypes from "prop-types";

const withAuthorization = Component => {
	class WithAuthorization extends React.Component {
		componentWillMount(){ 
			
            auth.onAuthStateChanged(async authUser =>{
				if(!authUser){
             
              this.props.history.push("/") 
			  	}
			})
		}
		render() {
			return <Component {...this.props}/>;
		}
	}
	WithAuthorization.contextType = {
		authUser: PropTypes.object,
		userData: PropTypes.object,
	};
	return withRouter(WithAuthorization);
};
export default withAuthorization;
