function validateAnimal(values) {
    const errors = {} 
    if(!values.name)
       errors.name                       = "Preencha o nome";
    if(!values.specie)  
       errors.specie                     = "Preencha a espécie";
    if(!values.race)
       errors.race                       = "Preencha a raça";
    if(!values.coat)
       errors.coat                       = "Preencha a pelagem";
    if(!values.sex)
       errors.sex                        = "Preencha o sexo";
    if(!values.birth_year)
       errors.birth_year                 = "Preencha o ano de nascimento";
    if(!values.check && values.sex == "femea")
       errors.check                      = "Selecione a gestação";
   if(!values.veterinary)
       errors.veterinary                 = "Selecione o veterinário";    
   
       
    return errors;
}

export default validateAnimal;