function validateLogin(values) {
    let errors = {};
    if (!values) {
      errors.email = 'Email obrigatório.';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values)
      ) {
        errors.email = 'Endereço de email inválido';
      }
    return errors;
}


export default validateLogin;