import React from "react";
import PropTypes from "prop-types";
import MainLayout from "../../Common/MainLayout";
import Modal from "../../Components/Modal";
import GalleryUpload from "../../Components/GalleryUpload";
import { Formik, Form, Field, ErrorMessage } from "formik";
import validateAnimal from "./validation";
import postToFunctions from "../../Common/postToFunctions";
import _ from "lodash";
import SelectField from "../../Components/SelectField";
import iziToast from "izitoast/dist/js/iziToast.min.js";
import * as routes from "../../Common/Routes";
import { date, shortDate } from "../../Common/TextMask";
import { Link } from "react-router-dom";
import LoadingScreen from "react-loading-screen";
import FormField from "../../Components/FormField";

class Animal extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isModalDeleteOpen: false,
			isSaveDeleteOpen: false,
			imageUrl: {},
			veterinaries: {},
			animal: {},
			loading: false,
			isCreating: false,
			deleting: false,
			textDeleting: false,
		};
	}

	async componentDidMount() {
		var body = document.getElementsByTagName("body")[0];

		body.style.cursor = "wait";
		this.setState({ loading: true });
		await this.getVeterinaies();
		if (this.props.match.params.animal) {
			this.setState({ loading: true });
			const { data } = await postToFunctions("getAnimalsById", {
				uid: this.props.match.params.animal,
			});
			console.log(data)
			this.setState({ loading: false, animal: data.animal, imageUrl: data.animal.imageUrl }, () => {
				body.style.cursor = "";
			});
		} else {
			body.style.cursor = "";
		}
	}

	async getVeterinaies() {
		const { data } = await postToFunctions("getVeterinaries");
		this.setState({ veterinaries: data.veterinaries, loading: false });
	}

	toggleModalDelete = value => {
		this.setState({ isModalDeleteOpen: value });
	};

	toggleModalSave = value => {
		this.setState({ isSaveDeleteOpen: value });
	};

	handleSubmit = async (animal, setSubmitting) => {
		this.setState({ isCreating: true });
		animal.imageUrl = this.state.imageUrl;
		if (animal.sex == "macho")
			animal.check = "nao"

		if (!this.props.match.params.animal) {
		} else {
			animal.uid = this.props.match.params.animal;
		}
		const endPoint = this.props.match.params.animal ? "updateAnimals" : "createAnimal";
		const { data } = await postToFunctions(endPoint, { animal });
		if (data.success) {
			iziToast.success({
				title: "OK",
				message: "Dados salvos com sucesso!",
				position: "topRight",
			});
			this.props.history.push(routes.ANIMALS);
		} else {
			iziToast.error({
				title: "Erro",
				message: "Tivemos um problema em gravar os dados. Tente novamente",
				position: "center",
			});
		}

		this.setState({ isCreating: false });

	};

	deleteAnimal() {
		this.setState({ isCreating: true, deleting: true });
		iziToast.show({
			close: false,
			theme: "light",
			icon: "fa fa-ban",
			iconColor: "red",
			maxWidth: 700,
			title: "Deseja mesmo excluir esse animal?",
			timeout: false,
			position: "center", // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter
			buttons: [
				[
					'<button style="background-color: #1F6CBC; color:#fff;">Sim</button>',
					async () => {
						this.setState({ textDeleting: true });
						const { data } = await postToFunctions("deleteAnimals", {
							uid: this.props.match.params.animal,
						});
						iziToast.destroy();
						if (data.success) {
							iziToast.success({
								title: "OK",
								message: "Animal excluído com sucesso!",
								position: "topRight",
							});
							this.props.history.push(routes.ANIMALS);
						} else {
							iziToast.error({
								title: "Erro",
								message: "Ooops... Algo deu errado.",
								position: "center",
							});
						}
					},
					true,
				], // true to focus
				[
					'<button  style="background-color: #fff; border:solid 1px #1F6CBC; color: #1F6CBC">Não</button>',
					async () => {
						this.setState({ isCreating: false, deleting: false });
						iziToast.destroy();
					},
					false,
				],
			],
		});
	}

	hendlerImageArray(index, url) {
		if (url == "")
			url = null


		this.setState(prevState => ({
			imageUrl: {
				...prevState.imageUrl,
				[index]: url,
			},
		}));
	}

	render() {
		const { veterinaries, animal } = this.state;
		return this.state.loading ? (
			<div>
				<LoadingScreen
					loading={this.state.loading}
					bgColor="#fff"
					spinnerColor="#1F6CBC"
					textColor="#1F6CBC"
					logoSrc={""}
					text={<span style={{ fontFamily: "Proxima Nova Semibold" }}>Carregando...</span>}
				/>
			</div>
		) : (
				<>
					<MainLayout {...this.props}>
						<Formik
							//enableReinitialize={true}
							initialValues={{
								name: animal.name,
								specie: animal.specie,
								race: animal.race,
								coat: animal.coat,
								sex: animal.sex,
								birth_year: animal.birth_year,
								check: animal.check,
								registration_number: animal.registration_number,
								veterinary: animal.veterinary,
								influenza_vaccine: animal.influenza_vaccine,
								date_vaccine: animal.date_vaccine,
								allotment_vaccine: animal.allotment_vaccine,
								validate_vaccine: animal.validate_vaccine,
							}}
							validate={values => validateAnimal(values)}
							onSubmit={(values, { setSubmitting }) => this.handleSubmit(values, setSubmitting)}>
							<Form>
								<div className="main-layout__actions-layout main-layout__actions-layout--align-center">
									<div className="main-layout__top-content">
										<div className="breadcrumb">
											<Link to={routes.ANIMALS}>
												<h2 className="breadcrumb__title">Animais</h2>
											</Link>
											<i className="breadcrumb__arrow breadcrumb__arrow-right" />
											<h3 className="breadcrumb__title breadcrumb__title--subtitle">
												{this.props.title}
											</h3>
										</div>
									</div>
									<div class="right-space">
										{this.props.match.params.animal ? (
											<button
												disabled={this.state.isCreating ? true : false}
												type="button"
												onClick={() => {
													this.deleteAnimal();
												}}
												class={`default-button ${
													this.state.isCreating ? "disabled-color" : "default-button--uncolored"
													} default-button--thinner clickable`}>
												{this.state.textDeleting ? "Excluindo..." : "Excluir"}
											</button>
										) : (
												<></>
											)}
										<button
											disabled={this.state.isCreating ? true : false}
											type="submit"
											class={`default-button ${
												this.state.isCreating ? "disabled-color" : "default-button--shamrock"
												} default-button--thinner clickable`}>
											{this.state.isCreating && !this.state.deleting ? "Salvando..." : "Salvar"}
										</button>
									</div>
								</div>

								<div className="half-layout">
									<div className="half-layout__left">
										<Field name="veterinary" label={"Veterinário*"} component={SelectField}>
											<option value="">Selecione o veterinário</option>
											{_.map(veterinaries, item => {
												return <option value={item.uid}>{item.name + " " + item.last_name}</option>;
											})}
										</Field>
										<Field name="name" label={"Nome do Animal*"} component={FormField} />
										{/* <Field name="specie" label={'Espécie*'} component={FormField} /> */}
										<Field name="specie" label={"Espécie*"} component={SelectField}>
											<option value="">Selecione a espécie</option>
											<option value="Equino">Equino</option>
											<option value="Muar">Muar</option>
											<option value="Asinino">Asinino</option>
										</Field>
										<Field name="race" label={"Raça*"} component={FormField} />
										<Field name="coat" label={"Pelagem*"} component={FormField} />
										<Field name="sex" label={"Sexo*"} component={SelectField}>
											<option value="">Selecione o sexo</option>
											<option value="macho">Macho</option>
											<option value="femea">Fêmea</option>
										</Field>


										{/* <div className="default-form__row">
											<label className="form-label">Sexo*</label>
											<select
												onChange={e => {
													var sexOfAnimal = e.target.value
											       this.setState({erroSex:false})
													this.setState(prevState => ({
														animal: {
															...prevState,
															sex: sexOfAnimal
														}
													}))
													if(sexOfAnimal == "macho"){
														this.setState(prevState => ({
															animal: {
																...prevState,
																check: "nao"
															}
														}))
													}
												}}
												name="state"
												className="search-filter__select search-filter__select--large search-filter__select--full"
											>
												<option value="">Selecione o sexo</option>
												<option selected={animal.sex == "macho"} value="macho">Macho</option>
												<option selected={animal.sex == "femea"} value="femea">Fêmea</option>

											</select>
											{!this.state.animal.sex && this.setState.erroSex ? (
												<div className="error-message">Selecione o sexo</div>
											) : (
													<></>
												)}
										</div> */}

											<div className="default-form__row">
												<label className="form-label">O animal está em gestação?*</label>
												<div className="default-form__row--tworows default-form__row--nofluid">
													<div>
														<label class="custom-radio">
															Sim
													<Field
																defaultChecked={animal.check === "sim"}
																type="radio"
																id="radio1"
																value="sim"
																name="check"
															/>
															<span class="custom-radio__checkmark" />
														</label>
													</div>
													<div>
														<label class="custom-radio">
															Não
													<Field
																defaultChecked={animal.check === "nao"}
																type="radio"
																id="radio2"
																value="nao"
																name="check"
															/>
															<span class="custom-radio__checkmark" />
														</label>
													</div>
												</div>
												<ErrorMessage
													name="check"
													render={msg => <div className="error-message">{msg}</div>}
												/>
											</div>
										

										<Field
											name="birth_year"
											maxlength={4}
											label={"Ano de Nascimento*"}
											component={FormField}
										/>
										<Field
											name="registration_number"
											label={"Número do Registro/Microchip"}
											component={FormField}
										/>
										{/* <Field name="influenza_vaccine" label={'Vacina contra influenza*'} component={FormField} /> */}
										<div className="default-form__row">
											<label className="form-label">Vacina contra influenza</label>
											<div className="default-form__row--tworows default-form__row--nofluid">
												<div>
													<label class="custom-radio" onClick={() => this.setState({ influenza: true })}>
														Sim
													<Field
															defaultChecked={animal.influenza_vaccine === "sim"}
															type="radio"
															id="radio1"
															value="sim"
															name="influenza_vaccine"
														/>
														<span class="custom-radio__checkmark" />
													</label>
												</div>
												<div>
													<label class="custom-radio" onClick={() => this.setState({ influenza: false })}>
														Não
													<Field
															defaultChecked={animal.influenza_vaccine === "nao"}
															type="radio"
															id="radio2"
															value="nao"
															name="influenza_vaccine"
														/>
														<span class="custom-radio__checkmark" />
													</label>
												</div>
											</div>
											<ErrorMessage
												name="influenza_vaccine"
												render={msg => <div className="error-message">{msg}</div>}
											/>
										</div>

										{this.state.influenza ? (
											<>
												<Field
													name="date_vaccine"
													label={"Data de vacinação"}
													component={FormField}
													mask={date}
												/>
												<Field name="allotment_vaccine" label={"Lote"} component={FormField} />
												<Field
													name="validate_vaccine"
													label={"Validade"}
													component={FormField}
													mask={shortDate}
												/>
											</>
										) : (
												<></>
											)}
									</div>

									<div className="half-layout__right">
										<h4 class="main-layout__title">Foto</h4>

										<div className="upload-field">
											<GalleryUpload
												image={animal.imageUrl}
												animalUrl={url => {
													this.hendlerImageArray("animalImage1", url);
												}}
												animalUrl2={url => {
													this.hendlerImageArray("animalImage2", url);
												}}
												animalUrl3={url => {
													this.hendlerImageArray("animalImage3", url);
												}}
											/>
										</div>
									</div>
								</div>
							</Form>
						</Formik>
					</MainLayout>
				</>
			);
	}
}

Animal.propTypes = {
	title: PropTypes.string.isRequired,
};

export default Animal;
