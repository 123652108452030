import React from 'react';
import iconPic from '../../Assets/Images/icon-pic.svg'
import FileUpload from '../FileUpload';


class LogoImageUpload extends React.PureComponent {
    state = {
        avatarUrl: iconPic,
    }
    componentDidMount(){
        this.setState({avatarUrl:this.props.img})
    }
    handleUploadSuccess = url => {
        this.setState({ avatarUrl: url });
        this.props.avatarUrl(url);
    }
    render() {
        return (
            <div className="logo-image-upload">
                <div className="logo-image-upload__image" style={{backgroundImage: `url(${this.state.avatarUrl ? this.state.avatarUrl :iconPic})`}}>
                    {/* <img src={this.state.avatarUrl} alt="" title="" /> */}
                    </div>
                <label className="logo-image-upload__button clickable">
                    <FileUpload handleUploadSuccess={url => this.handleUploadSuccess(url)} />
                </label>
            </div>
        )
    }
}


LogoImageUpload.defaultProp = {
    images: null,
};

export default LogoImageUpload;