import React from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field, ErrorMessage } from "formik";
import MainLayout from "../../Common/MainLayout";
import PasswordField from "../../Components/PasswordField";
import validateLab from "./validation";
import { phone, cnpj, cellphone, cep } from "../../Common/TextMask";
import FormField from "../../Components/FormField";
import postToFunctions from "../../Common/postToFunctions";
import iziToast from "izitoast/dist/js/iziToast.min.js";
import _ from "lodash";
import LoadingScreen from "react-loading-screen";
import iconPic from "../../Assets/Images/icon-pic.svg";
import FileUpload from "../../Components/FileUpload";
import * as routes from "../../Common/Routes";
import { Link } from "react-router-dom";
import * as utils from "../../Common/util";

class Lab extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isModalDeleteOpen: false,
			isSaveDeleteOpen: false,
			isCreatelab: false,
			imageUrl: null,
			avatarUrl: null,
			isPasswordConfirm: false,
			laboratory: [],
			loading: false,
			addressAutoComplete: [],
			address: [],
			deleting: false,
			textDeleting: false,
		};
	}
	async componentDidMount() {
    var body = document.getElementsByTagName("body")[0];

		body.style.cursor = "wait";
		if (this.props.match.params.lab) {
			this.setState({ loading: true }, async () => {
				const uid = this.props.match.params.lab;
				const { data } = await postToFunctions("getLabById", { uid });
				this.setState({
					laboratory: data.laboratory,
					loading: false,
					avatarUrl: data.laboratory.avatarUrl,
					imageUrl: data.laboratory.imageUrl,
				},()=>{
          body.style.cursor = "";
        });
			});
		}else{
      body.style.cursor = "";
    }
	}
	toggleModalDelete = value => {
		this.setState({ isModalDeleteOpen: value });
	};

	toggleModalSave = value => {
		this.setState({ isSaveDeleteOpen: value });
	};

	handleSubmit = async (laboratory, setSubmitting) => {
		this.setState({ isCreatelab: true });
		if (this.state.isPasswordConfirm && !this.props.match.params.lab) {
			validateLab(laboratory, this.state.isPasswordConfirm);
		} else {
			const error = await validateLab(laboratory, this.state.isPasswordConfirm);
			if (_.size(error) == 1 && !this.props.match.params.lab) {
				console.log("Validation Error", error);
			} else {
				if (
					laboratory.create_password !== laboratory.confirm_password &&
					!this.props.match.params.lab
				) {
					iziToast.error({
						title: "Erro",
						message: "As senhas não conferem",
						position: "center",
					});
				} else if (
					(!laboratory.create_password || !laboratory.create_password) &&
					!this.props.match.params.lab
				) {
					iziToast.error({
						title: "Erro",
						message: "Preencha a senha.",
						position: "center",
					});
				} else {
					laboratory.avatarUrl = this.state.avatarUrl;
					laboratory.imageUrl = this.state.imageUrl;
					laboratory.state = this.state.address.state;
					laboratory.cep = this.state.address.cep;
					laboratory.city = this.state.address.city;
					laboratory.neighborhood = this.state.address.neighborhood;

					if (!this.props.match.params.lab) {
					} else {
						laboratory.uid = this.props.match.params.lab;
					}
					const endPoint = this.props.match.params.lab ? "updateLab" : "createLaboratory";
					const { data } = await postToFunctions(endPoint, { laboratory });
					if (data.success) {
						iziToast.success({
							title: "OK",
							message: "Dados salvos com sucesso!",
							position: "topRight",
						});
						this.props.history.push(routes.LABS);
					} else {
						iziToast.error({
							title: "Erro",
							message: "Tivemos um problema em gravar os dados. Tente novamente",
							position: "center",
						});
					}
				}
			}
		}
		this.setState({ isCreatelab: false });
	};

	cep(v) {
		v = v.replace(/D/g, ""); //Remove tudo o que não é dígito
		v = v.replace(/^(\d{5})(\d)/, "$1-$2"); //Esse é tão fácil que não merece explicações
		return v;
	}

	async getAddress(cep) {
		cep = this.cep(cep);
		this.setState(prevState => ({
			address: {
				...prevState.address,
				cep: cep,
			},
		}));
		const result = await utils.getCep(cep);
		this.setState(prevState => ({
			address: {
				...prevState.address,
				cep: cep,
				state: result.uf,
				city: result.localidade,
				neighborhood: result.bairro,
				street: result.logradouro,
			},
		}));
	}

	setAddres(e) {
		const { name, value } = e.target;
		this.setState(prevState => ({
			address: {
				...prevState.address,
				[name]: value,
			},
		}));
	}

	deleteLab() {
		this.setState({ isCreatelab: true, deleting: true });
		iziToast.show({
			close: false,
			theme: "light",
			icon: "fa fa-ban",
			iconColor: "red",
			maxWidth: 700,
			title: "Deseja mesmo excluir esse laboratório?",
			timeout: false,
			position: "center", // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter
			buttons: [
				[
					'<button style="background-color: #1F6CBC; color:#fff;">Sim</button>',
					async () => {
						this.setState({ textDeleting: true });
						const { data } = await postToFunctions("deleteLab", {
							uid: this.props.match.params.lab,
						});
						iziToast.destroy();
						if (data.success) {
							iziToast.success({
								title: "OK",
								message: "Laboratório excluído com sucesso!",
								position: "topRight",
							});
							this.props.history.push(routes.LABS);
						} else {
							iziToast.error({
								title: "Erro",
								message: "Ooops... Algo deu errado.",
								position: "center",
							});
						}
					},
					true,
				], // true to focus
				[
					'<button  style="background-color: #fff; border:solid 1px #1F6CBC; color: #1F6CBC">Não</button>',
					async () => {
						this.setState({ isCreatelab: false, deleting: false });
						iziToast.destroy();
					},
					false,
				],
			],
		});
	}

	handleUploadSuccess = url => {
		this.setState({ avatarUrl: url }, () => {
			iziToast.destroy();
		});
	};
	handleUploadSuccessPhoto = url => {
		this.setState({ imageUrl: url }, () => {
			iziToast.destroy();
		});
	};
	renderLogoFileUpload() {
		return (
			<div className="logo-image-upload">
				<div
					className={`logo-image-upload__image  ${this.state.avatarUrl ? "profile-backgroud" : "profile-backgroud-lab"}`}
					style={{
						backgroundImage: `url(${this.state.avatarUrl ? this.state.avatarUrl : iconPic})`,
					}}
				/>
				<label className="logo-image-upload__button clickable">
					<FileUpload handleUploadSuccess={url => this.handleUploadSuccess(url)} />
				</label>
			</div>
		);
	}
	renderPhotoLaboratory() {
		return (
			<div className="single-picture-upload">
				<div className="to-hover">
					<div
						onClick={() => {
							this.handleUploadSuccessPhoto("");
						}}
						className="exclude-button clickable"
						style={{ display: this.state.imageUrl ? "" : "none" }}>
						<div class="custom-button02 clickable button-exlude">
							<i className="icon-trash-animal" />
							<span className="exclude-text">Excluir</span>
						</div>
					</div>
					<div
						className={`gallery-picture-upload__image ${this.state.imageUrl ? "profile-backgroud-laboratory" : "profile-backgroud-animal"}`}
						style={{
							backgroundImage: `url(${this.state.imageUrl ? this.state.imageUrl : iconPic})`,
						}}>
						<FileUpload handleUploadSuccess={url => this.handleUploadSuccessPhoto(url)} />
					</div>

					{/* <div
						className="single-picture-upload__image profile-backgroud"
						style={{
							backgroundImage: `url(${this.state.imageUrl ? this.state.imageUrl : iconPic})`,
							overflow: "inherit",
						}}>
						<label className="logo-image-upload__button position-button-edit clickable">
							<FileUpload handleUploadSuccess={url => this.handleUploadSuccessPhoto(url)} />
						</label>
					</div> */}
				</div>
			</div>
		);
	}

	render() {
		const { laboratory, addressAutoComplete, address } = this.state;
		return this.state.loading ? (
			<div>
				<LoadingScreen
					loading={this.state.loading}
					bgColor="#fff"
					spinnerColor="#1F6CBC"
					textColor="#1F6CBC"
					logoSrc={""}
					text={<span style={{ fontFamily: "Proxima Nova Semibold" }}>Carregando...</span>}
				/>
			</div>
		) : (
			<>
				<MainLayout {...this.props}>
					<Formik
						initialValues={{
							name: laboratory.name,
							cnpj: laboratory.cnpj,
							email: laboratory.email,
							phone: laboratory.phone,
							cellphone: laboratory.cellphone,
							incharge: laboratory.incharge,
							incharge_email: laboratory.incharge_email,
							incharge_phone: laboratory.incharge_phone,
							cep: laboratory.cep,
							number: laboratory.number,
							complement: laboratory.complement,
							neighborhood: laboratory.neighborhood
								? laboratory.neighborhood
								: addressAutoComplete.neighborhood,
							city: laboratory.city ? laboratory.city : addressAutoComplete.city,
							state: laboratory.state ? laboratory.state : addressAutoComplete.state,
							cnpj: laboratory.cnpj,
							unique_guide: laboratory.unique_guide,
						}}
						validate={values => validateLab(values)}
						onSubmit={values => {
							this.handleSubmit(values);
						}}
						render={props => (
							<Form>
								<div className="main-layout__actions-layout main-layout__actions-layout--align-center">
									<div className="main-layout__top-content">
										<div className="breadcrumb">
											<Link to={routes.LABS}>
												<h2 className="breadcrumb__title">Laboratório</h2>
											</Link>
											<i className="breadcrumb__arrow breadcrumb__arrow-right" />
											<h3 className="breadcrumb__title breadcrumb__title--subtitle">
												{this.props.title}
											</h3>
										</div>
									</div>
									<div className="right-space">
										{this.props.match.params.lab ? (
											<button
												disabled={this.state.isCreatelab && !this.state.deleting ? true : false}
												onClick={() => {
													this.deleteLab();
												}}
												type="button"
												className={`default-button ${
													this.state.isCreatelab ? "disabled-color" : "default-button--uncolored"
												}   default-button--thinner clickable`}>
												{this.state.textDeleting ? "Excluindo..." : "Excluir"}
											</button>
										) : (
											<></>
										)}
										<button
											disabled={this.state.isCreatelab && !this.state.deleting ? true : false}
											onClick={() => {
												this.setState({ isPasswordConfirm: false });
											}}
											type="submit"
											className={`default-button ${
												this.state.isCreatelab ? "disabled-color" : "default-button--shamrock"
											}  default-button--thinner clickable`}>
											{this.state.isCreatelab && !this.state.deleting ? "Salvando..." : "Salvar"}
										</button>
									</div>
								</div>

								<div className="half-layout">
									<div className="half-layout__left">
										<h4 className="main-layout__title">Dados do laboratório</h4>

										<Field name="name" label={"Nome do laboratório*"} component={FormField} />
										<Field name="cnpj" label={"CNPJ:"} mask={cnpj} component={FormField} />
										<Field
											name="email"
											disabled={this.props.match.params.lab ? true : false}
											type="email"
											label={"Email*"}
											component={FormField}
										/>
										<Field name="phone" label={"Telefone:"} mask={phone} component={FormField} />
										<Field
											name="cellphone"
											label={"Celular:"}
											mask={cellphone}
											component={FormField}
										/>
										<Field name="incharge" label={"Responsável:"} component={FormField} />
										<Field
											name="incharge_email"
											type="email"
											label={"Email do responsável:"}
											component={FormField}
										/>
										<Field
											name="incharge_phone"
											label={"Contato do responsável:"}
											component={FormField}
										/>
										<div className="default-form__row">
											<label className="form-label">Exame único?*</label>
											<div className="default-form__row--tworows default-form__row--nofluid">
												<div>
													<label class="custom-radio">
														Sim
														<Field
															defaultChecked={laboratory.unique_guide === "sim"}
															type="radio"
															id="radio1"
															value="sim"
															name="unique_guide"
														/>
														<span class="custom-radio__checkmark" />
													</label>
												</div>
												<div>
													<label class="custom-radio">
														Não
														<Field
															defaultChecked={laboratory.unique_guide === "nao"}
															type="radio"
															id="radio2"
															value="nao"
															name="unique_guide"
														/>
														<span class="custom-radio__checkmark" />
													</label>
												</div>
											</div>
											<ErrorMessage
												name="unique_guide"
												render={msg => <div className="error-message">{msg}</div>}
											/>
										</div>
										<h4 className="main-layout__title">Endereço</h4>
										<div className="default-form__row default-form__row--onerowcep">
											<label className="form-label">CEP:</label>
											<input
												onChange={e => {
													this.getAddress(e.target.value);
												}}
												name="cep"
												value={this.state.address.cep ? this.state.address.cep : laboratory.cep}
												type="text"
												className="default-input default-input--full"
											/>

											{/* <Field onChange={(e) => { this.getAddress(e.target.value) }} name="cep" mask={cep} label={'CEP:'} component={FormField} /> */}
										</div>
										<div className="default-form__row">
											<label className="form-label">Logradouro:</label>
											<input
												onChange={e => {
													this.setAddres(e);
												}}
												name="street"
												type="text"
												value={address["street"] ? address["street"] : laboratory.street}
												className="default-input default-input--full"
											/>
										</div>
										<div className="default-form__row default-form__row--tworows">
											<Field name="number" label={"Número:"} component={FormField} />
											<Field name="complement" label={"Complemento:"} component={FormField} />
										</div>
										<div className="default-form__row">
											<label className="form-label">Bairro:</label>
											<input
												onChange={e => {
													this.setAddres(e);
												}}
												name="neighborhood"
												type="text"
												value={
													address["neighborhood"]
														? address["neighborhood"]
														: laboratory.neighborhood
												}
												className="default-input default-input--full"
											/>
										</div>
										<div className="default-form__row">
											<label className="form-label">Cidade:</label>
											<input
												onChange={e => {
													this.setAddres(e);
												}}
												name="city"
												type="text"
												value={address["city"] ? address["city"] : laboratory.city}
												className="default-input default-input--full"
											/>
										</div>
										<div className="default-form__row default-form__row--onerowcep">
											<label className="form-label">Estado:</label>
											<input
												onChange={e => {
													this.setAddres(e);
												}}
												name="state"
												type="text"
												value={address["state"] ? address["state"] : laboratory.state}
												className="default-input default-input--full"
											/>
										</div>
										{_.size(laboratory) <= 0 ? (
											<>
												<h4 className="main-layout__title">Senha</h4>

												<PasswordField label={"Criar senha*"} name="create_password" />

												<PasswordField label={"Confirmar nova senha*"} name="confirm_password" />

												<div className="wrap-buttons--center">
													<button
														type="submit"
														onClick={() => {
															this.setState({ isPasswordConfirm: true });
														}}
														className="default-button default-button--fluid default-button--thinner default-button--mariner clickable">
														Confirmar senha
													</button>
												</div>
											</>
										) : (
											<div />
										)}
									</div>
									<div className="half-layout__right">
										<h4 className="main-layout__title">Logotipo do laboratório</h4>
										<div className="upload-field">
											{this.renderLogoFileUpload()}
											{/* <LogoImageUpload
                                                avatarUrl={url => {
                                                    this.setState({ avatarUrl: url })
                                                }}
                                                avartar={laboratory.avatarUrl}
                                            /> */}
										</div>
										<h4 className="main-layout__title">foto do laboratório</h4>
										{this.renderPhotoLaboratory()
										/* {<SinglePictureUpload photoUrl={url => { this.setState({ imageUrl: url }) }} />} */
										}
									</div>
								</div>
							</Form>
						)}
					/>
				</MainLayout>
			</>
		);
	}
}

Lab.propTypes = {
	title: PropTypes.string.isRequired,
};

export default Lab;
