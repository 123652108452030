import React from 'react';
import ReactDOM from 'react-dom';
import RegisterServiceWorker from './RegisterServiceWorker';
import '../src/Styles/Css/global.css'
import '../src/Styles/Css/normalize.css'
import Router from './Router';
import 'izitoast/dist/css/iziToast.min.css';
import 'izitoast/dist/js/iziToast.min.js';

ReactDOM.render(<Router />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
RegisterServiceWorker();
