import React from "react";
import PropTypes from "prop-types";
import MainLayout from "../../Common/MainLayout";
import { Formik, Form } from "formik";
import validateProperties from "./validation";
import LoadingScreen from "react-loading-screen";
import postToFunctions from "../../Common/postToFunctions";
import _ from "lodash";
import * as utils from "../../Common/util";
import { Link } from "react-router-dom";
import * as routes from "../../Common/Routes";
import iziToast from "izitoast/dist/js/iziToast.min.js";
import * as mask from "../../Common/mask";

class Properties extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isModalDeleteOpen: false,
			isSaveDeleteOpen: false,
			imageUrl: null,
			loading: false,
			veterinaries: {},
			states: {},
			stateSelected: {},
			property: {},
			stateId: null,
			sigla: null,
			errors: {},
			feedbackActions: false,
			deleting: false,
			textDeleting: false,
		};
	}

	async componentDidMount() {
		var body = document.getElementsByTagName("body")[0];
		body.style.cursor = "wait";
		this.setState({ loading: true }, async () => {
			await this.getVeterinaies();
			await this.getStates();
			if (this.props.match.params.propertie) {
				const { data } = await postToFunctions("getPropertyById", {
					uid: this.props.match.params.propertie,
				});
				const stateOfProperty = await _.filter(this.state.states, o => {
					return o.sigla == data.property.state;
				});
				this.setState({ property: data.property });
				if (stateOfProperty.length > 0) {
					await this.getCities(stateOfProperty[0].id);
				}
				body.style.cursor = "";
			} else {
				body.style.cursor = "";
			}
			this.setState({ loading: false });
		});
	}

	async getVeterinaies() {
		const { data } = await postToFunctions("getVeterinaries");
		this.setState({ veterinaries: data.veterinaries });
	}

	async getStates() {
		const result = await utils.getStates();
		this.setState({ states: result });
	}

	async getCities(UF) {
		this.clearAlertMensage("state", UF);
		if (UF) {
			const stateSelected = await _.filter(this.state.states, o => {
				return o.id == UF;
			});
			const result = await utils.getCities(UF);
			this.setState({
				cities: result,
				stateSelected: stateSelected[0].nome,
				stateId: UF,
				siglaState: stateSelected[0].sigla,
			});
		} else {
			this.setState({ cities: [], stateSelected: null, stateId: null });
		}
	}

	toggleModalDelete = value => {
		this.setState({ isModalDeleteOpen: value });
	};

	toggleModalSave = value => {
		this.setState({ isSaveDeleteOpen: value });
	};

	deletePropriety() {
		this.setState({ feedbackActions: true, deleting: true });
		iziToast.show({
			close: false,
			theme: "light",
			icon: "fa fa-ban",
			iconColor: "red",
			maxWidth: 700,
			title: "Deseja mesmo excluir essa propriedade?",
			timeout: false,
			position: "center", // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter
			buttons: [
				[
					'<button style="background-color: #1F6CBC; color:#fff;">Sim</button>',
					async () => {
						this.setState({ textDeleting: true });
						const { data } = await postToFunctions("deleteProperty", {
							uid: this.props.match.params.propertie,
						});
						iziToast.destroy();
						if (data.success) {
							iziToast.success({
								title: "OK",
								message: "Propriedade excluída com sucesso!",
								position: "topRight",
							});
							this.props.history.push(routes.PROPERTIES);
						} else {
							iziToast.error({
								title: "Erro",
								message: "Ooops... Algo deu errado.",
								position: "center",
							});
						}
					},
					true,
				], // true to focus
				[
					'<button  style="background-color: #fff; border:solid 1px #1F6CBC; color: #1F6CBC">Não</button>',
					async () => {
						this.setState({ feedbackActions: false, deleting: false });
						iziToast.destroy();
					},
					false,
				],
			],
		});
		this.setState({ feedbackActions: true });
	}

	async verifyErrors(propertie) {
		const error = {};
		if (!propertie.name || propertie.name == "") error.name = true;
		if (!propertie.veterinary || propertie.veterinary == "") error.veterinary = true;
		if (!propertie.state_registration_number || propertie.state_registration_number == "")
			error.state_registration_number = true;
		if (!propertie.phone || propertie.phone == "") error.phone = true;
		if (!propertie.city || propertie.city == "") error.city = true;
		if (!propertie.propertie || propertie.propertie == "") error.propertie = true;
		if (!propertie.number_of_animals || propertie.number_of_animals == "")
			error.number_of_animals = true;
		if (!this.state.stateId || this.state.stateId == "") error.state = true;
		this.setState({ errors: error });
		return error;
	}

	clearAlertMensage(key, value) {
		if (value != "") {
			this.setState(prevState => ({
				errors: {
					...prevState.errors,
					[key]: false,
				},
			}));
		}
	}

	async	handlerPropertie(key, e) {
		var { name, value } = e.target;
		if (key == "phone") {
			value = await mask.maskTEL(value)
		}
		if (key == "state_registration_number") {
			value = await mask.registrationNumber(value)
		}
		this.clearAlertMensage(key, value);

		this.setState(prevState => ({
			property: {
				...prevState.property,
				[name]: value,
			},
		}));

	}

	handleSubmit = async (propertieOld, setSubmitting) => {
		const propertie = this.state.property;
		this.setState({ erros: {}, feedbackActions: true });
		const error = await this.verifyErrors(propertie);

		if (_.size(error) <= 0) {
			const vet = await _.filter(this.state.veterinaries, ["uid", propertie.veterinary]);
			propertie.veterinaryName = vet[0].name;
			if (!this.props.match.params.propertie) {
			} else {
				propertie.uid = this.props.match.params.propertie;
			}
			propertie.state = this.state.siglaState;
			propertie.stateId = this.state.stateId;
			propertie.sigla = this.state.siglaState;
			const endPoint = this.props.match.params.propertie ? "updateProperty" : "createProperty";
			const { data } = await postToFunctions(endPoint, { propertie });
			if (data.success) {
				this.setState({ feedbackActions: false }, () => {
					iziToast.success({
						title: "OK",
						message: "Dados salvos com sucesso!",
						position: "topRight",
					});
				});
				this.props.history.push(routes.PROPERTIES);
			}
		} else {
			this.setState({ feedbackActions: false });
		}
	};

	render() {
		const { veterinaries, property } = this.state;
		return this.state.loading ? (
			<div>
				<LoadingScreen
					loading={this.state.loading}
					bgColor="#fff"
					spinnerColor="#1F6CBC"
					textColor="#1F6CBC"
					logoSrc={""}
					text={<span style={{ fontFamily: "Proxima Nova Semibold" }}>Carregando...</span>}
				/>
			</div>
		) : (
				<>
					<MainLayout {...this.props}>
						<Formik
							initialValues={{
								veterinary: property.veterinary,
								name: property.name,
								state_registration_number: property.state_registration_number,
								phone: property.phone,
								city: property.city,
								propertie: property.propertie,
								number_of_animals: property.number_of_animals,
								state: property.stateId,
							}}
							validate={values => validateProperties(values)}
							onSubmit={(values, { setSubmitting }) => this.handleSubmit(values, setSubmitting)}>
							<Form>
								<div className="main-layout__actions-layout main-layout__actions-layout--align-center">
									<div className="main-layout__top-content">
										<div className="breadcrumb">
											<Link to={routes.PROPERTIES}>
												<h2 className="breadcrumb__title">Propriedades</h2>
											</Link>
											<i className="breadcrumb__arrow breadcrumb__arrow-right" />
											<h3 className="breadcrumb__title breadcrumb__title--subtitle">
												{this.props.title}
											</h3>
										</div>
									</div>
									<div class="right-space">
										{this.props.match.params.propertie ? (
											<button
												disabled={this.state.feedbackActions ? true : false}
												type="button"
												onClick={() => {
													this.deletePropriety();
												}}
												class={`default-button ${
													this.state.feedbackActions ? "disabled-color" : "default-button--uncolored"
													} default-button--thinner clickable`}>
												{this.state.textDeleting ? "Excluindo..." : "Excluir"}
											</button>
										) : (
												<></>
											)}
										<button
											disabled={this.state.feedbackActions ? true : false}
											type="submit"
											class={`default-button ${
												this.state.feedbackActions ? "disabled-color" : "default-button--shamrock"
												} default-button--thinner clickable`}>
											{this.state.feedbackActions && !this.state.deleting ? "Salvando..." : "Salvar"}
										</button>
									</div>
								</div>

								<div className="half-layout">
									<div className="half-layout__left">
										<h4 class="main-layout__title">Dados</h4>

										<div className="default-form__row">
											<label className="form-label">Veterinário*</label>
											<select
												onChange={e => {
													this.handlerPropertie("veterinary", e);
												}}
												className="search-filter__select search-filter__select--large search-filter__select--full"
												name="veterinary"
											>
												<option value="">Selecione o veterinário</option>
												{_.map(veterinaries, item => {
													return <option selected={item.uid == property.veterinary ? true : false} value={item.uid}>{item.name + " " + item.last_name}</option>;
												})}
											</select>
											{this.state.errors.veterinary ? (
												<div className="error-message">Selecione o veterinário</div>
											) : (
													<></>
												)}
										</div>

										<div className="default-form__row">
											<label className="form-label">Nome da Propriedade*</label>
											<input
												onChange={e => {
													this.handlerPropertie("name", e);
												}}
												className="default-input default-input--full"
												name="name"
												value={property.name}
											/>
											{this.state.errors.name ? (
												<div className="error-message">Preencha o nome</div>
											) : (
													<></>
												)}
										</div>

										<div className="default-form__row">
											<label className="form-label">Nº Cadastro Estadual*</label>
											<input
												onChange={e => {
													this.handlerPropertie("state_registration_number", e);
												}}
												className="default-input default-input--full"
												name="state_registration_number"
												value={property.state_registration_number}
												maxLength={11}
											/>
											{this.state.errors.state_registration_number ? (
												<div className="error-message">Preencha o cadastro estadual</div>
											) : (
													<></>
												)}
										</div>

										<div className="default-form__row">
											<label className="form-label">Contato*</label>
											<input
												onChange={e => {
													this.handlerPropertie("phone", e);
												}}
												name="phone"
												className="default-input default-input--full"
												value={property.phone}
												maxLength={15}
											/>
											{this.state.errors.phone ? (
												<div className="error-message">Preencha o contato</div>
											) : (
													<></>
												)}
										</div>

										<div className="default-form__row">
											<label className="form-label">Estado*</label>
											<select
												onChange={e => {
													this.getCities(e.target.value);
												}}
												name="state"
												className="search-filter__select search-filter__select--large search-filter__select--full"
											>
												<option value="">Selecione o estado</option>
												{_.map(this.state.states, state => {
													return <option selected={property.state == state.sigla ? true : false} value={state.id}>{state.nome}</option>;
												})}
											</select>
											{this.state.errors.state ? (
												<div className="error-message">Selecione o estado</div>
											) : (
													<></>
												)}
										</div>

										<div className="default-form__row">
											<label className="form-label">Cidade*</label>
											<select
												onChange={e => {
													this.handlerPropertie("city", e);
												}}
												name="city"
												className="search-filter__select search-filter__select--large search-filter__select--full"
											>
												<option value="">Selecione a cidade</option>
												{_.map(this.state.cities, city => {
													return <option selected={property.city == city.nome ? true : false} value={city.nome}>{city.nome}</option>;
												})}
											</select>
											{this.state.errors.city ? (
												<div className="error-message">Preencha a cidade</div>
											) : (
													<></>
												)}
										</div>

										<div className="default-form__row">
											<label className="form-label">Tipo de Propriedade*</label>

											<select
												onChange={e => {
													this.handlerPropertie("propertie", e);
												}}
												className="search-filter__select search-filter__select--large search-filter__select--full"
												name="propertie"
											>
												<option value="">Selecione a propriedade</option>
												<option selected={property.propertie == "Haras" ? true : false} value="Haras">Haras</option>
												<option selected={property.propertie == "Fazenda" ? true : false} value="Fazenda">Fazenda</option>
												<option selected={property.propertie == "Jóquei Clube" ? true : false} value="Jóquei Clube">Jóquei Clube</option>
												<option selected={property.propertie == "Sociedade Hípica" ? true : false} value="Sociedade Hípica">Sociedade Hípica</option>
												<option selected={property.propertie == "Unidade Militar" ? true : false} value="Unidade Militar">Unidade Militar</option>
											</select>
											{this.state.errors.propertie ? (
												<div className="error-message">Selecione o tipo de propriedade</div>
											) : (
													<></>
												)}
										</div>

										<div className="default-form__row">
											<label className="form-label">Nº de Equídeos existentes*</label>
											<input
												value={property.number_of_animals}
												onChange={e => {
													this.handlerPropertie("number_of_animals", e);
												}}
												name="number_of_animals"
												className="default-input default-input--third"
											/>
											{this.state.errors.number_of_animals ? (
												<div className="error-message">Preencha o numero de animais</div>
											) : (
													<></>
												)}
										</div>
									</div>
								</div>
							</Form>
						</Formik>
					</MainLayout>
				</>
			);
	}
}

Properties.propTypes = {
	title: PropTypes.string.isRequired,
};

export default Properties;
