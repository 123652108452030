import React from 'react';
import iconPic from '../../Assets/Images/icon-pic.svg'
import FileUpload from '../FileUpload';
import _ from 'lodash';
import iconPlus from '../../Assets/Images/icon-plus.svg'
import ImageGallery from '../ImageGalleryUpload';



class GalleryUpload extends React.PureComponent {
    state = {
        avatarUrl: null,
        imageUrl: null,
        imageUrl2: null,
    }
    componentDidMount() {
        if (this.props.image) {
            const images = _.map(this.props.image, item => {
                return item;
            })
            this.setState({ avatarUrl: images[0], imageUrl: images[1], imageUrl2: images[2] })
        }

    }
    handleUploadSuccess = url => {
        this.setState({ avatarUrl: url });
        this.props.animalUrl(url)
    }
    imageUploadSuccess = async url => {
        console.log("url-->",url)
        this.setState({ imageUrl: url });
        this.props.animalUrl2(url)
    }
    imageUploadSuccess2 =  async  url => {
        this.setState({ imageUrl2: url });
        this.props.animalUrl3(url)
    }

    renderImage1() {
        const color = this.state.imageUrl ? 'black' : 'white'
        const border = this.state.imageUrl ? 'none' : 'dashed'

        return (
            <div className="image-gallery-upload">
                <div className="to-hover-image" style={{backgroundColor: color,borderStyle: border}}>
                    <div className="new-image-animal clickable" style={{ backgroundImage: `url(${this.state.imageUrl ? this.state.imageUrl : iconPlus})`, overflow: 'inherit',position:'absolute'  }}>
                        <FileUpload handleUploadSuccess={async url => await this.imageUploadSuccess(url)} />
                    </div>
                    <div onClick={() => {
                        this.imageUploadSuccess('')
                    }} className={`image-gallery-upload__image-animal clickable profile-backgroud_teste`}
                        style={{ display: this.state.imageUrl ? '' : 'none' }} >
                        <div class="custom-button02 clickable button-exlude">
                            <i className="icon-trash-animal"></i>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderImage2() {
        const color = this.state.imageUrl2 ? 'black' : 'white'
        const border = this.state.imageUrl2 ? 'none' : 'dashed'

        return (
            <div className="image-gallery-upload">
                <div className="to-hover-image" style={{backgroundColor:color,borderStyle: border}}>
                    <div className="new-image-animal clickable" style={{ backgroundImage: `url(${this.state.imageUrl2 ? this.state.imageUrl2 : iconPlus})`, overflow: 'inherit',position:'absolute' }}>
                        <FileUpload handleUploadSuccess={async url => await this.imageUploadSuccess2(url)} />
                    </div>
                    <div onClick={() => {
                        this.imageUploadSuccess2('')
                    }} className={`image-gallery-upload__image-animal clickable profile-backgroud_teste`}
                        style={{ display: this.state.imageUrl2 ? '' : 'none' }} >
                        <div class="custom-button02 clickable button-exlude">
                            <i className="icon-trash-animal"></i>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className="gallery-upload">
                <div className="to-hover">
                    <div onClick={() => {
                        this.handleUploadSuccess('')
                    }} className="exclude-button clickable" style={{ display: this.state.avatarUrl ? '' : 'none' }}>
                        <div class="custom-button02 clickable button-exlude">
                            <i className="icon-trash-animal"></i><span className="exclude-text">Excluir</span>
                        </div>
                    </div>
                    <div className="gallery-picture-upload__image profile-backgroud-animal" style={{ backgroundImage: `url(${this.state.avatarUrl ? this.state.avatarUrl : iconPic})` }}>
                        <FileUpload handleUploadSuccess={url => this.handleUploadSuccess(url)} />
                    </div>
                </div>
                {this.renderImage1()}
                {this.renderImage2()}
            </div>
        )
    }
}


GalleryUpload.defaultProp = {
    images: null,
};

export default GalleryUpload;