import React from "react";
import PropTypes from "prop-types";
import MainLayout from "../../Common/MainLayout";
import PasswordField from "../../Components/PasswordField";
import validateProfessionals from "./validation";
import { date, phone, cpf, shortDate, cep } from "../../Common/TextMask";
import ProfilePictureUpload from "../../Components/ProfilePictureUpload";
import { Formik, Form, Field, ErrorMessage } from "formik";
import FormField from "../../Components/FormField";
import postToFunctions from "../../Common/postToFunctions";
import * as routes from "../../Common/Routes";
import * as utils from "../../Common/util";
import _ from "lodash";
import ToggleSwitch from "../../Components/ToggleSwitch";
import iziToast from "izitoast/dist/js/iziToast.min.js";
import FileUpload from "../../Components/FileUpload";
import iconPic from "../../Assets/Images/iconPic.PNG";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import * as mask from "../../Common/mask";
import { Link } from "react-router-dom";

class Veterinary extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isModalDeleteOpen: false,
			isSaveDeleteOpen: false,
			imageUrl: iconPic,
			loading: false,
			addressAutoComplete: [],
			address: [],
			isPasswordConfirm: false,
			controller: 0,
			veterinary: {},
			isCreating: false,
			deleting: false,
			textDeleting: false,
		};
	}

	async componentDidMount() {
		var body = document.getElementsByTagName("body")[0];

		body.style.cursor = "wait";
		await this.getStates();
		if (this.props.match.params.veterinary) {
			this.setState({ loading: true }, async () => {
				const uid = this.props.match.params.veterinary;
				const { data } = await postToFunctions("getVeterinaryById", { uid });
				this.setState({
					veterinary: data.veterinary,
					loading: false,
					imageUrl: data.veterinary.imageUrl,
				});
				body.style.cursor = "";
			});
		}else{
      body.style.cursor = "";
    }
	}

	toggleModalDelete = value => {
		this.setState({ isModalDeleteOpen: value });
	};

	toggleModalSave = value => {
		this.setState({ isSaveDeleteOpen: value });
	};

	async getStates() {
		await axios
			.get("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
			.then(async data => {
				const states = await _.orderBy(data.data, ["sigla"], ["asc", "desc"]);
				this.setState({ states });
			});
	}

	handleSubmit = async veterinary => {
		this.setState({ isCreating: true });
		if (this.state.isPasswordConfirm && !this.props.match.params.veterinary) {
			await validateProfessionals(veterinary, this.state.isPasswordConfirm);
		} else {
			const error = await validateProfessionals(
				veterinary,
				this.state.isPasswordConfirm,
				this.props.match.params.veterinary ? true : false,
			);
			if (_.size(error) == 1 && !this.props.match.params.veterinary) {
				console.log("Validation Error", error);
			} else {
				if (
					veterinary.create_password !== veterinary.confirm_password &&
					!this.props.match.params.veterinary
				) {
					iziToast.error({
						title: "Erro",
						message: "As senhas não conferem",
						position: "center",
					});
				} else if (
					(!veterinary.create_password || !veterinary.confirm_password) &&
					!this.props.match.params.veterinary
				) {
					iziToast.error({
						title: "Erro",
						message: "Preencha a senha.",
						position: "center",
					});
				} else {
					veterinary.imageUrl = this.state.imageUrl;
					veterinary.cep = this.state.veterinary.cep;
					veterinary.street = this.state.veterinary.street;
					veterinary.city = this.state.veterinary.city;
					veterinary.neighborhood = this.state.veterinary.neighborhood;
					veterinary.state = this.state.veterinary.state;

					if (!this.props.match.params.veterinary) {
					} else {
						veterinary.uid = this.props.match.params.veterinary;
					}
					const endPoint = this.props.match.params.veterinary
						? "updateVeterinary"
						: "createVeterinary";
					const { data } = await postToFunctions(endPoint, { veterinary });
					if (data.success) {
						iziToast.success({
							title: "OK",
							message: "Dados salvos com sucesso!",
							position: "topRight",
						});
						this.props.history.push(routes.PROFESSIONALS);
					} else {
						iziToast.error({
							title: "Erro",
							message: "Tivemos um problema em gravar os dados. Tente novamente",
							position: "center",
						});
					}
				}
			}
		}
		this.setState({ isCreating: false });
	};

	async getAddress(cep) {
		cep = await mask.maskCEP(cep);
		this.setState(prevState => ({
			veterinary: {
				...prevState.veterinary,
				cep: cep,
			},
		}));
		const result = await utils.getCep(cep);
		this.setState(prevState => ({
			veterinary: {
				...prevState.veterinary,
				state: result.uf,
				city: result.localidade,
				neighborhood: result.bairro,
				street: result.logradouro,
			},
		}));
	}

	setAddres(e) {
		const { name, value } = e.target;
		this.setState(prevState => ({
			veterinary: {
				...prevState.veterinary,
				[name]: value,
			},
		}));
	}

	deleteVeterinary() {
		this.setState({ isCreating: true, deleting: true });
		iziToast.show({
			close: false,
			theme: "light",
			icon: "fa fa-ban",
			iconColor: "red",
			maxWidth: 700,
			title: "Deseja mesmo excluir esse veterinário?",
			timeout: false,
			position: "center", // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter
			buttons: [
				[
					'<button style="background-color: #1F6CBC; color:#fff;">Sim</button>',
					async () => {
						this.setState({ textDeleting: true });
						const { data } = await postToFunctions("deleteVeterinary", {
							uid: this.props.match.params.veterinary,
						});
						iziToast.destroy();
						if (data.success) {
							iziToast.success({
								title: "OK",
								message: "Veterinário excluído com sucesso!",
								position: "topRight",
							});
							this.props.history.push(routes.PROFESSIONALS);
						} else {
							iziToast.error({
								title: "Erro",
								message: "Ooops... Algo deu errado.",
								position: "center",
							});
						}
					},
					true,
				], // true to focus
				[
					'<button  style="background-color: #fff; border:solid 1px #1F6CBC; color: #1F6CBC">Não</button>',
					async () => {
						this.setState({ isCreating: false, deleting: false });
						iziToast.destroy();
					},
					false,
				],
			],
		});
	}

	handleChangeCallback = async i => {
		const { data } = await postToFunctions("activeVeterinary", { veterinary: i });
  };
  
  handleChangeCallbackLogout = async i => {
    i.logged = !i.logged
		const { data } = await postToFunctions("updateVeterinary", { veterinary: i });
	};

	setPassword(e) {
		const { name, value } = e.target;
		this.setState(prevState => ({
			password: {
				...prevState.password,
				[name]: value,
			},
		}));
	}

	handleUploadSuccess = url => {
		this.setState({ imageUrl: url });
	};

	renderLogoFileUpload() {
		return (
			<div className="profile-picture-upload">
				<div
					className="profile-picture-upload__image profile-backgroud"
					style={{ backgroundImage: `url(${this.state.imageUrl})` }}>
					<label className="logo-image-upload__button profile-edit-button clickable">
						<FileUpload handleUploadSuccess={url => this.handleUploadSuccess(url)} />
					</label>
				</div>
			</div>
		);
	}

	render() {
		const { address, veterinary } = this.state;
		return this.state.loading ? (
			<div>
				<LoadingScreen
					loading={this.state.loading}
					bgColor="#fff"
					spinnerColor="#1F6CBC"
					textColor="#1F6CBC"
					logoSrc={""}
					text={<span style={{ fontFamily: "Proxima Nova Semibold" }}>Carregando...</span>}
				/>
			</div>
		) : (
			<>
				<MainLayout {...this.props} data={this.state.userData}>
					<Formik
						initialValues={{
							name: veterinary.name,
							birth_date: veterinary.birth_date,
							cpf: veterinary.cpf,
							city: veterinary.city,
							complement: veterinary.complement,
							contact: veterinary.contact,
							last_name: veterinary.last_name,
							licence_date: veterinary.licence_date,
							neighborhood: veterinary.neighborhood,
							number: veterinary.number,
							state: veterinary.state,
							email: veterinary.email,
							state_veterinary: veterinary.state_veterinary,
							crmv: veterinary.crmv,
							licence_mormo: veterinary.licence_mormo,
						}}
						validate={values => validateProfessionals(values)}
						onSubmit={values => this.handleSubmit(values)}
						render={props => (
							<Form>
								<div className="main-layout__actions-layout main-layout__actions-layout--align-center">
									<div className="main-layout__top-content">
										<div className="breadcrumb">
											<Link to={routes.PROFESSIONALS}>
												<h2 className="breadcrumb__title">Veterinários</h2>
											</Link>
											<i className="breadcrumb__arrow breadcrumb__arrow-right" />
											<h3 className="breadcrumb__title breadcrumb__title--subtitle">
												{this.props.title}
											</h3>
										</div>
									</div>
									<div class="right-space">
										{this.props.match.params.veterinary ? (
											<button
												disabled={this.state.isCreating ? true : false}
												type="button"
												onClick={() => {
													this.deleteVeterinary();
												}}
												class={`default-button ${
													this.state.isCreating ? "disabled-color" : "default-button--uncolored"
												} default-button--thinner clickable`}>
												{this.state.textDeleting ? "Excluindo..." : "Excluir"}
											</button>
										) : (
											<></>
										)}
										<button
											disabled={this.state.isCreating ? true : false}
											type="submit"
											onClick={() => {
												this.setState({ isPasswordConfirm: false });
											}}
											class={`default-button ${
												this.state.isCreating ? "disabled-color" : "default-button--shamrock"
											} default-button--thinner clickable`}>
											{this.state.isCreating && !this.state.deleting ? "Salvando..." : "Salvar"}
										</button>
									</div>
								</div>

								<div className="half-layout">
									<div className="half-layout__left">
										<h4 class="main-layout__title">Dados</h4>
										<Field name="name" label={"Nome*"} component={FormField} />
										<Field name="last_name" label={"Sobrenome: "} component={FormField} />
										<Field
											name="birth_date"
											label={"Data de Nascimento:"}
											component={FormField}
											mask={date}
										/>
										<Field name="cpf" label={"CPF*"} component={FormField} mask={cpf} />
										<Field name="contact" label={"Celular:"} component={FormField} mask={phone}/>
										<Field
											name="email"
											disabled={this.props.match.params.veterinary ? true : false}
											type="email"
											label={"Email*"}
											component={FormField}
										/>
										<h4 class="main-layout__title">Endereço</h4>

										<div className="default-form__row default-form__row--onerowcep">
											<label className="form-label">CEP:</label>
											<input
												onChange={e => {
													this.getAddress(e.target.value);
												}}
												className="default-input default-input--full"
												name="cep"
												value={veterinary.cep}

												// component={FormField}
											/>
										</div>
										<div className="default-form__row">
											<label className="form-label">Logradouro:</label>
											<input
												onChange={e => {
													this.setAddres(e);
												}}
												name="street"
												type="text"
												value={veterinary.street}
												className="default-input default-input--full"
											/>
										</div>
										<div className="default-form__row default-form__row--tworows">
											<Field name="number" label={"Número:"} component={FormField} />
											<Field name="complement" label={"Complemento:"} component={FormField} />
										</div>
										<div className="default-form__row">
											<label className="form-label">Bairro:</label>
											<input
												onChange={e => {
													this.setAddres(e);
												}}
												name="neighborhood"
												type="text"
												value={veterinary.neighborhood}
												className="default-input default-input--full"
											/>
										</div>
										<div className="default-form__row">
											<label className="form-label">Cidade:</label>
											<input
												onChange={e => {
													this.setAddres(e);
												}}
												name="city"
												type="text"
												value={veterinary.city}
												className="default-input default-input--full"
											/>
										</div>
										<div className="default-form__row  default-form__row--onerowcep">
											<label className="form-label">Estado:</label>
											<input
												onChange={e => {
													this.setAddres(e);
												}}
												name="state"
												type="text"
												value={veterinary.state}
												className="default-input default-input--full"
											/>
										</div>
										<h4 class="main-layout__title">Dados do veterinário</h4>
										<div className="default-form__row  default-form__row--onerowcep">
											<label className="form-label">Estado:</label>
											<Field
												name="state_veterinary"
												label={"Estado"}
												component={"select"}
												className="default-input default-input--full">
												<option value="">Selecione..</option>
												{_.map(this.state.states, state => {
													return <option value={state.sigla}>{state.sigla}</option>;
												})}
											</Field>
										</div>
										<Field name="crmv" label={"CRMV*"} component={FormField} />
										<div className="default-form__row default-form__row--tworows">
											<Field
												name="licence_mormo"
												type="number"
												label={"Habilitação para Mormo"}
												component={FormField}
											/>
											<Field
												name="licence_date"
												type="text"
												label={"Portaria Habilitação:"}
												component={FormField}
												mask={shortDate}
											/>
										</div>
										{veterinary.isVeterinary ? (
											<>
												<label className="form-label">Ativar veterinário:</label>
												<ToggleSwitch
													active={veterinary.active}
													changeCallack={() => this.handleChangeCallback(veterinary)}
												/>
											</>
										) : (
											<></>
                    )}
                    
                    {veterinary.isVeterinary ? (
											<>
												<label className="form-label">Login/Logout veterinário:</label>
												<ToggleSwitch
													active={veterinary.logged}
													changeCallack={() => this.handleChangeCallbackLogout(veterinary)}
												/>
											</>
										) : (
											<></>
										)}
										{!veterinary.isVeterinary ? (
											<>
												<h4 class="main-layout__title">Senha</h4>
												<PasswordField label={"Criar senha*"} name="create_password" />
												<PasswordField label={"Confirmar nova senha*"} name="confirm_password" />
												<div className="wrap-buttons--center">
													<button
														type="submit"
														onClick={() => {
															this.setState({ isPasswordConfirm: true });
														}}
														className="default-button default-button--fluid default-button--thinner default-button--mariner  clickable">
														Confirmar senha
													</button>
												</div>
											</>
										) : (
											<div />
										)}
									</div>
									<div className="half-layout__right">
										<div className="upload-field">{this.renderLogoFileUpload()}</div>
									</div>
								</div>
							</Form>
						)}
					/>
				</MainLayout>
			</>
		);
	}
}

Veterinary.propTypes = {
	title: PropTypes.string.isRequired,
};

export default Veterinary;
